import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { isSameOrBefore } from '../../utils/helper';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { isDate } from '../../utils/validations';
import { errors } from '../../constants';
import { DateBox as DateBoxDevEx } from 'devextreme-react';

const DateBox = ({...props}) => {
  const [isFocus, setFocus] = useState(false);
  const isDateSameOrBefore = props.allowFuture ? true : isSameOrBefore(props.value);
  const isValid = props.isValid && isDateSameOrBefore;
  const isLabel = props.label || !isValid;
  const showRequired = props.isRequired && (!props.value || !isDate(props.value)) ? true : false;
  const errorMessage = !isDateSameOrBefore ? errors.FUTURE_DATE : props.errorText || errors.INCOMPLETE_DATA_FIELD;

  const handleFocusIn = e => {
    setFocus(true);
  };

  const handleFocusOut = e => {
    setFocus(false);
  };

  const onValueChanged = e => {
    if (!e.event) return;
    props.onValueChanged(e);
  };

  return <>
    {isLabel && <div>
      {props.label && <FieldLabel text={props.label} icon={props.labelIcon} moreInfo={props.moreInfo} />}
      {(!props.hideErrorIcon && !props.readOnly && (!isValid || showRequired)) && <FieldError isFocus={isFocus} error={errorMessage} />}
    </div>}
    <DateBoxDevEx
      {...props}
      labelMode='hidden'
      useMaskBehavior
      dateOutOfRangeMessage={null}
      onFocusIn={props.onFocusIn || handleFocusIn}
      onFocusOut={props.onFocusOut || handleFocusOut}
      isValid={(showRequired) ? props.value && isDate(props.value) : isValid}
      showClearButton={props.showClearButton && isFocus}
      displayFormat='MM/dd/yyyy'
      onValueChanged={onValueChanged} />
  </>;
};

DateBox.propTypes = {
  label: PropTypes.string,
  labelIcon: PropTypes.array,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  showClearButton: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  errorText: PropTypes.string,
  moreInfo: PropTypes.string,
  onFocusIn: PropTypes.func,
  onFocusOut: PropTypes.func,
  hideErrorIcon: PropTypes.bool,
  allowFuture: PropTypes.bool,
  onValueChanged: PropTypes.func
};

DateBox.defaultProps = {
  isValid: true,
  allowFuture: false
};

export default DateBox;
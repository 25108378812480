import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'devextreme-react/tooltip';
import cn from 'classnames';
import { createID } from '../../utils/helper';
import styles from './FieldError.module.scss';

const FieldError = ({ error, className, isFocus }) => {
  const id = `ID-${createID()}`;

  return (
    <div className={cn(className, styles.container)}>
      <FontAwesomeIcon
        id={id}
        icon={['fal', 'exclamation-triangle']} />
      <Tooltip
        target={`#${id}`}
        visible={isFocus}
        position='top'
        maxWidth='375px'
        hideOnOutsideClick={false}
      >
        <div>
          {error}
        </div>
      </Tooltip>
    </div>
  );
};

FieldError.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
  isFocus: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

export default FieldError;

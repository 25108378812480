import axios from 'axios';
import queryString from 'query-string';
import { storage } from '../constants';
import getConfig from '../config';
const { REACT_APP_API_BASE_URL } = getConfig();

let loggedInUser = JSON.parse(window.sessionStorage.getItem(storage.AP_USER));

axios.interceptors.request.use(function (config) {
  // Serialize the parameteters
  config.paramsSerializer = params => queryString.stringify(params, { arrayFormat: 'brackets' });
  return config;
});

export default {
  request: async (options) => {
    options.baseURL = REACT_APP_API_BASE_URL;

    if (options.isCUF) {
      if (!options.params) options.params = {};
      options.params.email = loggedInUser.email;
    }
    if (options.isCompany) {
      let loggedInUserToken = window.sessionStorage.getItem(storage.TOKEN);
      if (!options.params) options.params = {};
      options.params.token = loggedInUserToken;
    }

    const response = await axios(options);
    return response;
  },
  link: (options) => {
    const url = `${REACT_APP_API_BASE_URL}${options.url}`
    return url
  }
};

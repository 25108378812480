import React, { useEffect, useContext, useState } from 'react';
import { incorporationHistory } from '../../constants/forms/companyInformation';
import { countries, errors, reportingStandards } from '../../constants';
import { createID, sortList } from '../../utils/helper';
import { ProfileContext } from '../../context/Profile.context';
import Controls from '../Controls';
import Entity from '../Entity';
import Label from '../Label';
import SlidePanel from '../SlidePanel';
import Table from '../Table';
import FormSection from '../FormSection';

const columns = [
    {
        header: 'State',
        field: 'stateOfIncorporation'
    },
    {
        header: 'Country',
        field: 'countryOfIncorporation'
    },
    {
        header: 'Year',
        field: 'yearOfIncorporation',
        moreInfo: 'Year company became registered in Jurisdiction.',
    }
];

const COUNTRY = 'countryOfIncorporation';
const STATE = 'stateOfIncorporation';
const YEAR = 'yearOfIncorporation';

const incorporationObj = {
    [COUNTRY]: countries.US,
    [STATE]: null,
    [YEAR]: null
};

const IncorporationHistory = () => {
    const [profileState, dispatchProfile] = useContext(ProfileContext);
    const [incorporationView, setIncorporationView] = useState(incorporationObj);
    const [slidePanel, setSlidePanel] = useState(false);
    const [validationError, setValidationError] = useState(null);
    const profileData = profileState.profile;
    const incorporationList = [ ...profileData.companyInfo.incorporationInfo ];
    const sortedList = sortList(incorporationList, 'yearOfIncorporation', 'asc');
    const edgarFilingStatus = profileData.edgarFilingStatus;
    const isReadOnly = profileState.readOnly;

    useEffect(() => {
        if (!slidePanel) {
            setIncorporationView(incorporationObj);
            setValidationError(false);
        }
    }, [slidePanel]);

    const onValueChanged = value => {
        if (incorporationView[COUNTRY] !== value[COUNTRY]) value[STATE] = null;
        if (!value[COUNTRY]) value[COUNTRY] = countries.US;
        setIncorporationView(value);
    };

    const openAddNew = _ => {
        const newIncorpObj = { ...incorporationObj };
        const preExistingList = sortedList.filter(item => item.isPreExisting);
        if (edgarFilingStatus && edgarFilingStatus.includes(reportingStandards.SEC) && preExistingList && preExistingList.length > 0) {
            const index = preExistingList.length - 1;
            newIncorpObj[COUNTRY] = preExistingList[index][COUNTRY];
        }

        setIncorporationView(newIncorpObj);
        setSlidePanel(true);
    };

    const handleRowClick = item => {
        setIncorporationView(item);
        setSlidePanel(true);
    };
    
    const handleRemoveRow = (e, item) => {
        e.stopPropagation(); 
        
        const updatedObj = { ...profileData.companyInfo };
        updatedObj.incorporationInfo = updatedObj.incorporationInfo.filter(obj => obj.id !== item.id);

        dispatchProfile({
            type: 'UPDATE_PROFILE',
            field: 'companyInfo',
            payload: updatedObj
        });
    };

    const slideClose = _ => {
        setSlidePanel(false);
        setIncorporationView(incorporationObj);
    };

    const handleAdd = _ => {
        setValidationError(false);

        if (!validateForm()) return;

        const updatedObj = { ...profileData.companyInfo };

        const id = createID(updatedObj.incorporationInfo);
        incorporationView.id = id;

        updatedObj.incorporationInfo.push(incorporationView);

        dispatchProfile({
            type: 'UPDATE_PROFILE',
            field: 'companyInfo',
            payload: updatedObj
        });

        slideClose();
    };

    const handleUpdate = _ => {
        setValidationError(false);
        if (!validateForm()) return;

        const updatedObj = { ...profileData.companyInfo };

        updatedObj.incorporationInfo = updatedObj.incorporationInfo.map(item => {
        if (item.id === incorporationView.id) return incorporationView;

        return item;
        });

        dispatchProfile({
            type: 'UPDATE_PROFILE',
            field: 'companyInfo',
            payload: updatedObj
        });

        slideClose();
    };

    const validateForm = _ => {
        const isStateValid = incorporationView[COUNTRY] === countries.AU
        || incorporationView[COUNTRY] === countries.CA
        || incorporationView[COUNTRY] === countries.US;

        if (!incorporationView[COUNTRY] || (isStateValid && !incorporationView[STATE]) || !incorporationView[YEAR]) {
            setValidationError(errors.INCOMPLETE_DATA);
            return false;
        }

        const hasExist = incorporationList.filter(item => ((!item[STATE] && !incorporationView[STATE]) || (item[STATE] === incorporationView[STATE]))
            && item[COUNTRY] === incorporationView[COUNTRY]
            && item[YEAR] === incorporationView[YEAR]);

        if (hasExist && hasExist.length > 0) {
            setValidationError(errors.DUPLICATE_DATA);
            return false;
        }

        return true;
    };

    return <>
        <Table columns={columns} data={sortedList} onRowClick={!isReadOnly && handleRowClick} onRemoveRow={!isReadOnly && handleRemoveRow} />
        {!isReadOnly && <Entity className='mtSm' isAdd title='Add New Incorporation History' size='small' onClick={openAddNew} />}
        <SlidePanel 
            isOpen={slidePanel}
            onClose={slideClose}
            title='Incorporation History'>
                {incorporationHistory.map((section, i) => <FormSection key={i} section={section} data={{ ...incorporationView, incorporationList, edgarFilingStatus }} handleValueChange={onValueChanged} />)}
                {validationError && <Label className='mtLg' title={validationError} isError />}
                <Controls
                    className='mtXXL'
                    cancelText='Cancel'
                    submitText={incorporationView.id ? 'Update' : 'Add'}
                    onCancelClick={slideClose}
                    onSubmitClick={incorporationView.id ? handleUpdate : handleAdd}
                />
        </SlidePanel>
    </>;
};

export default IncorporationHistory;

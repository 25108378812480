import { errors, fieldTypes } from '../index';
import { isEmail } from '../../utils/validations';

export const contactSection1 = [
    {
        fields: [
            {
                type: fieldTypes.TEXTBOX,
                label: 'First Name',
                key: 'firstName',
                disabledBy: 'isAccountPayable',
                isRequired: true,
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Middle Name',
                key: 'middleName',
                disabledBy: 'isAccountPayable',
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Last Name',
                key: 'lastName',
                disabledBy: 'isAccountPayable',
                isRequired: true,
                colSpan: 2
            }
        ]
    }
];

export const contactSection2 = [
    {
        fields: [
            {
                type: fieldTypes.PHONE,
                label: 'Phone Number',
                key: 'phone',
                key2: 'phoneCountryCode',
                labelIcon: ['far', 'lock'],
                isRequired: true,
                colSpan: 3
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Email',
                key: 'email',
                labelIcon: ['far', 'lock'],
                isRequired: true,
                isValid: data => isEmail(data.email),
                invalidText: errors.INVALID_EMAIL,
                colSpan: 3
            }
        ]
    }
];

export const existingContactSection = [
    {
        fields: [
            {
                type: fieldTypes.PHONE,
                label: 'Phone Number',
                key: 'phone',
                key2: 'phoneCountryCode',
                labelIcon: ['far', 'lock'],
                colSpan: 3
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Email',
                key: 'email',
                labelIcon: ['far', 'lock'],
                isValid: data => isEmail(data.email),
                invalidText: errors.INVALID_EMAIL,
                colSpan: 3
            }
        ]
    }
];

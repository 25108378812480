import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';
import { useSearchParams, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from 'devextreme-react/popup';
import { deleteCompanyLogo, uploadCompanyLogo, getCompanyLogoMetaData } from '../../api/company-logo';
import CompanyHeader from '../../containers/CompanyHeader';
import Header from '../../components/Header';
import TextLink from '../../components/TextLink';
import Label from '../../components/Label';
import { Button } from '@otcmarketsgroup/otcm-ui';
import { storage } from '../../constants';
import { format } from '../../utils/format-locale';
import { redirectIQ } from '../../utils/helper';
import FileUploader from '../../components/FileUploader';
import ErrorPage from '../../components/ErrorPage';
import api from '../../api/api';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import styles from './CompanyLogoPage.module.scss';

export const mimeTypes = {
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF: 'application/pdf',
  CSV: 'text/csv',
  JPG: 'image/jpeg',
  SVG: 'image/svg+xml',
  PNG: 'image/png',
  GIF: 'image/gif',
  EPS: 'application/postscript',
  AI: 'application/postscript'
};

const allowedFileExtensions = [mimeTypes.JPG, mimeTypes.SVG, mimeTypes.PNG, mimeTypes.GIF];
const maxFileSize = 2000000;

const byteSize = format(maxFileSize, maxFileSize, 'byteSize');
const requirements = [`(.jpg, .jpeg, .png, .svg, .gif only) - ${byteSize}`,
  'All fonts must be converted to outlines. All required registered marks, service marks or trademarks should be part of the logo artwork. Corporate tag lines cannot be part of the logo artwork.'
];

const processingText = 'Your file is currently being processed. This may take a few moments. You can refresh the page to check if the processing is complete.';

const rejectText = 'The file you attempted to upload has been rejected. Please try again with a valid file.';

const CompanyLogoPage = () => {
  const [logoRejectMetaInfo, setLogoRejectMetaInfo] = useState(null);
  const [logoMetaInfo, setLogoMetaInfo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // upload file
  const [imageSource, setImageSource] = useState(null);

  // user error
  const [error, setError] = useState(null);

  // update logo
  const [updateError, setUpdateError] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [successSave, setSuccessSave] = useState(false);

  // delete
  const [deleteDialogVisible, setDialogVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [successDelete, setSuccessDelete] = useState(false);


  const [isDeletingBadFile, setIsDeletingBadFile] = useState(false);
  const [isCurrentLoaded, setCurrentLoaded] = useState(false);

  // common
  const [popupWidth, setPopupWidth] = useState('60vw');

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const selectedSymbol = params.symbol;

  // render var

  const logoStatus = logoMetaInfo && logoMetaInfo.status;

  const logoProcessing = logoStatus === 'PROCESSING';

  const showLogoMetaData = (!isUpdating || logoProcessing) && logoMetaInfo;

  const logoActive = (logoStatus === 'ACTIVE');

  const showNoLogo = !logoStatus && !isUpdating &&  !selectedFile;
  const showProcessingLogo = logoProcessing && !isUpdating;

  const showLogoChangeControls = logoActive && !isUpdating && !successSave;
  const showFileUploader = !successSave && ((logoActive && isUpdating) || (!logoStatus));

  const loadingHeight = window?.innerHeight /2 || 250;
  const loadDataAllow = error ? [] : logoMetaInfo ? [logoMetaInfo] : [{}];

  const handleUnload = useCallback((e) => {
    // Recommended
    e.preventDefault();
    // Included for legacy support, e.g. Chrome/Edge < 119
    e.returnValue = true;

  }, []);

  useEffect(() => {
    updatePopupWidth(); // Set initial width
    window.addEventListener('resize', updatePopupWidth); // Update width on resize
    return () => window.removeEventListener('resize', updatePopupWidth);
  }, []);

  const updatePopupWidth = () => {
    if (window.innerWidth <= 1200) {
      setPopupWidth('80vw');
    } else {
      setPopupWidth('40vw');
    }
  };

  useEffect(() => {
    getCompanyLogoInfo();
  }, []);

  const loadCompanyLogoInfo = async () => {
    let rejectedMetaData, processMetaData, activeMetadata;

    try {
      const metaData = await getCompanyLogoMetaData(selectedSymbol);
      if (!metaData || metaData.length === 0) {
        setLogoRejectMetaInfo(null);
        setLogoMetaInfo(null);
        setImageSource(null);
        return false;
      }
      rejectedMetaData = metaData.find(item => item.status === 'REJECTED');
      processMetaData = metaData.find(item => item.status === 'PROCESSING');
      activeMetadata = metaData.find(item => item.status === 'ACTIVE');
      setLogoRejectMetaInfo(rejectedMetaData);
      if (rejectedMetaData) {
        if (activeMetadata) {
          setLogoMetaInfo(activeMetadata);
        } else {
          setLogoMetaInfo(activeMetadata);
          setImageSource(null);
          return false;
        }
      } else if (processMetaData) {
        setLogoMetaInfo(processMetaData);
        return false;
      } else if (activeMetadata) {
        setLogoMetaInfo(activeMetadata);
      }

      return true;
    } catch (err) {
      const errDetails = err.response.data;
      console.error(errDetails);
      if (errDetails && errDetails.code === 403) {
        setError('We\'re sorry, there is a problem with the current request. Details regarding this message have been logged. Please try again and Contact us if the problem continues.');
        window.sessionStorage.removeItem(storage.TOKEN); // remove expired/ invalid token
        redirectIQ();
      } else {
        setError('We\'re sorry, there is a problem with the current request. Details regarding this message have been logged. Please try again and Contact us if the problem continues.');
      }
      return false;
    }
  };

  const getCompanyLogoInfo = async () => {
    let userToken = null;
    const token = searchParams.get('token');
    if (token) {
      window.sessionStorage.setItem(storage.TOKEN, token);
      userToken = token;
      setSearchParams({});
    } else {
      userToken = window.sessionStorage.getItem(storage.TOKEN);
    }

    if (userToken) {
      const allowLogoLoad = await loadCompanyLogoInfo();
      if (allowLogoLoad) {
        try {
          //const imageBlob = await getCompanyLogo(selectedSymbol);
          //const imageObjectURL = URL.createObjectURL(imageBlob);
           const url = api.link({ url: `/company/logo?symbol=${selectedSymbol}`})
           setImageSource(url);
          setCurrentLoaded(true);
        } catch (err) {
          console.error(err);
          setError('Logo currently not available. Try again.');
        }
      }
      else{
        setCurrentLoaded(true);
      }
    } else {
      redirectIQ(); // no token
    }
  };

  const handleLogoUpdate = () => {
    setIsUpdating(true);
  };

  const cancelLogoUpdate = () => {
    if(logoActive){
    const url = api.link({ url: `/company/logo?symbol=${selectedSymbol}`})
    setImageSource(url);
  } else{
    setImageSource(null);
  }
    setSelectedFile(null);
    setUpdateError(null);
    setIsUpdating(false);
    window.removeEventListener('beforeunload', handleUnload, true);
  };

  const handleConfirmUpdateLogo = _ => {
    setUpdateError(null);
    if (!isUpdating) setIsUpdating(true);
    if (!selectedFile) {
      setUpdateError('Please select file.');
      return;
    }
    setIsSaving(true);

    uploadCompanyLogo(selectedSymbol, selectedFile)
      .then(_ => {
        setIsSaving(false);
        setSuccessSave(true);
        setSelectedFile(null);
        window.removeEventListener('beforeunload', handleUnload, true);
        loadCompanyLogoInfo();
      })
      .catch(e => {
        console.log(e)
        setIsSaving(false);
        setUpdateError('Company Logo submit failed. Try again.');
      });
  };

  const handleDeleteShow = () => {
    setDialogVisible(true);
  };

  const handleConfirmDeleteLogo = _ => {
    setDeleteError(null);
    setIsDeleting(true);

    deleteCompanyLogo(selectedSymbol, logoMetaInfo.uid)
      .then(_ => {
        setIsDeleting(false);
        setSuccessDelete(true);
        window.removeEventListener('beforeunload', handleUnload);
        loadCompanyLogoInfo();
      })
      .catch(e => {
        console.log(e)
        setIsDeleting(false);
        setDeleteError('Company Logo delete failed. Try again.');
      });
  };

  const ackRejectedDeleteLogo = _ => {
    setIsDeletingBadFile(true);
    deleteCompanyLogo(selectedSymbol, logoRejectMetaInfo.uid)
      .then(_ => {
        setIsDeletingBadFile(true);
        loadCompanyLogoInfo();
      })
      .catch(e => {
        setIsDeletingBadFile(true);
        console.log(e);
      });
  };

  const handleDeleteHide = _ => {
    setDialogVisible(false);
    if (successDelete) {
      getCompanyLogoInfo();
    }
  };

  const handleDeleteHidden = _ => {
    if (successDelete) {
      setSuccessDelete(false);
    }
  };

  const handleUploadedFile = file => {
    setUpdateError(null);
    if (file) {
      const fileReader = new FileReader();
      setSelectedFile(file);
      window.addEventListener('beforeunload', handleUnload,  true);
      fileReader.onload = () => {
        setImageSource(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setImageSource(null);
      window.removeEventListener('beforeunload', handleUnload, true);
    }
  };

  return ( 
    <div key='companyLogoPageKey' className={styles.container}>
      <Header />
      <main>
      {error ? <ErrorPage /> :<Loading  height={loadingHeight} data={loadDataAllow} loaded={isCurrentLoaded} error={error}>
        <CompanyHeader className='mbXL' symbol={selectedSymbol} />
        <div className={styles.infoContainer}>
          <div className={styles.main}>
            <h1>Company Logo</h1>
            <p>To help investors recognize companies, OTC Markets Group Inc. uses company logos instead of or in combination with trading symbols. Company logos are used on the websites maintained by OTC Markets Group Inc, <a className={styles.links} href='https://www.otcmarkets.com' target='_blank' rel='noopener noreferrer'>www.otcmarkets.com</a>, and <a className={styles.links} href='https://www.otciq.com' target='_blank' rel='noopener noreferrer'>www.otciq.com</a>.</p>
            <p>OTC Markets Group Inc.'s use of your company's logos, trademarks and service marks, and your company's respective rights, privileges, and obligations with respect to such use, are governed by the <a className={styles.links} href='https://www.otcmarkets.com/files/Issuer_Services_Agreement.pdf' target='_blank' rel='noopener noreferrer' >Issuer Services Agreement</a>.</p>
            { showNoLogo && <div id='noLogo' className={styles.noLogo}>No Logo</div>}
            { showProcessingLogo && <div id='noLogo' className={styles.noLogo}>PROCESSING</div>}

            <div id='logoPreview'>
              {imageSource && <img className={styles.previewLogo} src={imageSource} alt={selectedSymbol} />}
            </div>

            {showLogoMetaData && <div id='logoMeta' className={styles.metaInfo}>
              <div><span className={styles.logoMetaInfo}>Last Modified By </span> {logoMetaInfo.lastModifierName}</div>
              <div><span className={styles.logoMetaInfo}>Last Modified On </span> {moment(logoMetaInfo.lastModifiedDate).format('llll')}</div>
            </div>}

            {logoProcessing && <Label className='mtLg'>{processingText}</Label>}

            { showLogoChangeControls && <div className='mtXL'>
              <Button id='deleteLogo' title='Delete Logo' color="red" inactive={isDeleting} onClick={handleDeleteShow} />
              <Button id='changeLogo' className={styles.changeLogo} title='Change Logo' onClick={handleLogoUpdate} />
            </div> }

            { showFileUploader && <>
              <div className='mtMed'>
                <FileUploader
                  selectedFile={selectedFile}
                  setSelectedFile={handleUploadedFile}
                  allowedFileExtensions={allowedFileExtensions}
                  isValid
                  maxFileSize={maxFileSize}
                  disabled={isSaving}
                  requirements={requirements}
                />
              </div>
              {(selectedFile || isUpdating) && <div className={styles.saveDialogControls}>
                <TextLink text='Cancel' inactive={isSaving} onClick={cancelLogoUpdate} />
                <Button className={styles.deleteConfirm} title='Submit' inactive={isSaving} onClick={handleConfirmUpdateLogo} />
                {isSaving && <div className={styles.loaderContainer}><span className={`${styles.loader}`}></span></div> }
              </div> }
            </>
            }
            {updateError && <Label className='mtLg' isError>{updateError}</Label>}
            {error && <Label className='mtLg' isError>{error}</Label>}
            {logoRejectMetaInfo && <Label className='mtLg' isError>{rejectText}
              <div className={styles.ackReject}><Button title='Ok' inactive={isDeletingBadFile} onClick={ackRejectedDeleteLogo} />
              { isDeletingBadFile && <div className={styles.loaderContainer}><span className={`${styles.loader}`}></span></div> }
              </div> 
            </Label> }
          </div>
          <div className={cn(styles.info, styles.right)}>
          </div>
        </div>
        </Loading> }
      </main>
      <Footer />
      <Popup
        visible={deleteDialogVisible}
        dragEnabled={false}
        title={'Delete Logo'}
        hideOnOutsideClick
        showCloseButton
        position='center'
        width={popupWidth}
        height='auto'
        onHidden={handleDeleteHidden}
        onHiding={handleDeleteHide}>
        {successDelete ? <div className={styles.deleteModalContainer}>
          <div className={styles.confirmMsg}>
            <FontAwesomeIcon className={styles.deleteSuccessIcon} icon={['far', 'check-circle']} />
            {selectedSymbol} Logo has successfully been deleted.
          </div>
          <div className={styles.dialogControls}>
            <Button title='OK' onClick={_ => setDialogVisible(false)} />
          </div>
        </div> : <div className={styles.deleteModalContainer}>
          <div>
            <h3>You have elected to delete your logo. It will no longer appear on any OTC Markets Group Inc. products. Do you still want to delete?</h3>
          </div>
          <div className={styles.deleteDialogControls}>
            <TextLink text='Cancel' inactive={isDeleting} onClick={_ => setDialogVisible(false)} />
            <Button className={styles.deleteConfirm} title='Delete' inactive={isDeleting} onClick={handleConfirmDeleteLogo} />
            {isDeleting && <div className={styles.loaderContainer}><span className={`${styles.loader}`}></span></div>}
          </div>
          {deleteError && <Label className='mbLg' isError>{deleteError}</Label>}
        </div>
        }
      </Popup>
    </div>
  );
};

export default CompanyLogoPage;

import _ from 'lodash';
import moment from 'moment-timezone';
import { getCountryName, getStateName } from '../locale';
import { providers } from '../../constants/forms/serviceProviders';
import { compareField } from '../helper';
import { compareFieldTypes } from '../../constants';

const TRANSFER_AGENT = 'transferAgents';
const SECURITY_COUNSELS = 'securityCounsels';

export const getServiceProvidersCompare = (currentProfile, updatedProfile) => {
    const json = [];

    const createFieldObj = (provider, id, title, fieldName, fieldType) => {
        let oldValue = _.get(currentProfile[provider].find(p => p.id === id), fieldName);
        let newValue = _.get(updatedProfile[provider].find(p => p.id === id), fieldName);

        switch (fieldType) {
            case compareFieldTypes.BOOLEAN:
                oldValue = oldValue ? 'Yes' : 'No';
                newValue = newValue ? 'Yes' : 'No';
                break;
            case compareFieldTypes.COUNTRY:
                oldValue = getCountryName(oldValue);
                newValue = getCountryName(newValue);
                break;
            default:
            break;
        };

        const obj = {
            fieldName: title,
            oldValue,
            newValue
        };

        return obj;
    };

    const createStateFieldObj = (provider, id, title, stateFieldName, countryFieldName) => {
        const currentState = _.get(currentProfile[provider].find(p => p.id === id), stateFieldName);
        const updatedState = _.get(updatedProfile[provider].find(p => p.id === id), stateFieldName);
        const currentCountry = _.get(currentProfile[provider].find(p => p.id === id), countryFieldName);
        const updatedCountry = _.get(updatedProfile[provider].find(p => p.id === id), countryFieldName);

        const oldValue = getStateName(currentState, currentCountry);
        const newValue = getStateName(updatedState, updatedCountry);

        const obj = {
            fieldName: title,
            oldValue,
            newValue
        };

        return obj;
    };

    // NEW
    providers.forEach(provider => {
        const providerParam = provider.key;

        updatedProfile[providerParam] && updatedProfile[providerParam].forEach(item => {
            const updatedProvider = item;
    
            if (updatedProvider.isDeleted) {
                return;
            };
    
            if (!currentProfile[providerParam].find(current => current.id === updatedProvider.id)) {
                const providerObj = {
                    header: `${provider.title} - ${updatedProvider.isOwnTransferAgent ? 'Company Acts as its own Transfer Agent' : updatedProvider.noOutsideCounsel ? 'Internal Securities Counsel' : updatedProvider.name}`,
                    isNew: true,
                    fields: []
                };
                
                if (providerParam === TRANSFER_AGENT) {
                    if (updatedProvider.isOwnTransferAgent) providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Acts As Own', 'isOwnTransferAgent', compareFieldTypes.BOOLEAN));
                    if (updatedProvider.isAuthorized) providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Authorized', 'isAuthorized', compareFieldTypes.BOOLEAN));
                    if (!updatedProvider.isOwnTransferAgent) providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Name', 'name'));
                }

                if (providerParam === SECURITY_COUNSELS && updatedProvider.noOutsideCounsel) providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'No Outside Securities counsel', 'noOutsideCounsel', compareFieldTypes.BOOLEAN));

                if (providerParam !== TRANSFER_AGENT && !updatedProvider.noOutsideCounsel) {
                    providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Name', 'name'));
                    providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Firm Phone Country Code', 'phoneCountryCode'));
                    providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Firm Phone', 'phone'));
                    providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Firm Email', 'email'));
                    if (updatedProvider.website) providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Firm Website', 'website'));
                    providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Firm Address', 'address1'));
                    if (updatedProvider.address2) providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Firm Address 2', 'address2'));
                    if (updatedProvider.address3) providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Firm Address 3', 'address3'));
                    providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Country', 'countryId', compareFieldTypes.COUNTRY));
                    providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'City', 'city'));
                    if (updatedProvider.state) providerObj.fields.push(createStateFieldObj(providerParam, updatedProvider.id, 'State', 'state', 'countryId'));
                    providerObj.fields.push(createFieldObj(providerParam, updatedProvider.id, 'Postal Code', 'zip'));
                }

                json.push(providerObj);
            }
        });

        // UPDATE Provider
        currentProfile[providerParam] && currentProfile[providerParam].forEach(item => {
            const currentProvider = item;
            const updatedProvider = updatedProfile[providerParam] && updatedProfile[providerParam].find(p => p.id === currentProvider.id);

            if (!updatedProvider || updatedProvider.isDeleted) return;

            const providerObj = {
                header: `${provider.title} - ${currentProvider.isOwnTransferAgent ? 'Company Acts as its own Transfer Agent' : currentProvider.noOutsideCounsel ? 'Internal Securities Counsel' : currentProvider.name}`,
                fields: []
            };

            if (providerParam === TRANSFER_AGENT) {
                compareField(currentProvider, updatedProvider, 'isAuthorized', 'Authorized', providerObj, compareFieldTypes.BOOLEAN);
                if (!updatedProvider.isOwnTransferAgent) compareField(currentProvider, updatedProvider, 'name', 'Name', providerObj);
            }

            if (providerParam !== TRANSFER_AGENT && !updatedProvider.noOutsideCounsel) {
                compareField(currentProvider, updatedProvider, 'name', 'Name', providerObj);
                compareField(currentProvider, updatedProvider, 'phoneCountryCode', 'Firm Phone Country Code', providerObj);
                compareField(currentProvider, updatedProvider, 'phone', 'Firm Phone', providerObj);
                compareField(currentProvider, updatedProvider, 'email', 'Firm Email', providerObj);
                compareField(currentProvider, updatedProvider, 'website', 'Firm Website', providerObj);
                compareField(currentProvider, updatedProvider, 'address1', 'Firm Address 1', providerObj);
                compareField(currentProvider, updatedProvider, 'address2', 'Firm Address 2', providerObj);
                compareField(currentProvider, updatedProvider, 'address3', 'Firm Address 3', providerObj);
                compareField(currentProvider, updatedProvider, 'countryId', 'Country', providerObj, compareFieldTypes.COUNTRY);
                compareField(currentProvider, updatedProvider, 'city', 'City', providerObj);
                if ((!!currentProvider.state || !!updatedProvider.state) && currentProvider.state !==  updatedProvider.state) providerObj.fields.push(createStateFieldObj(providerParam, updatedProvider.id, 'State', 'state', 'countryId'));
                compareField(currentProvider, updatedProvider, 'zip', 'Postal Code', providerObj);
            }

            if (providerObj.fields && providerObj.fields.length > 0) json.push(providerObj);
        });

        //  DELETE Provider
        currentProfile[providerParam] && currentProfile[providerParam].forEach(item => {
            const currentProvider = item;

            if (updatedProfile[providerParam] && updatedProfile[providerParam].find(p => p.id === currentProvider.id && p.isDeleted)) {
                const providerObj = {
                    header: `${provider.title} - ${currentProvider.isOwnTransferAgent ? 'Company Acts as its own Transfer Agent' : currentProvider.noOutsideCounsel ? 'Internal Securities Counsel' : currentProvider.name}`,
                    isDeleted: true
                };
    
                json.push(providerObj);
            }
        });
    });

    return json;
};

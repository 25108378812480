import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from '../Title';
import Button from '../Button';
import styles from './Label.module.scss';

const Label = ({ title, children, className, hasButton, buttonText, onButtonClick, hasComplete, isError, completeText, isCollapsible, collapseText, isLabelOpen, onToggle }) => {
  const [isOpen, setOpen] = useState(typeof isLabelOpen === 'boolean' ? isLabelOpen : true);
  const handleToggle = () => {
    if (onToggle) onToggle(!isOpen);
    setOpen(!isOpen);
  };

  return <div className={cn(className, styles.container, {
      [styles.error]: isError
    })}>
    <div className={cn(styles.content, { [styles.collaspible]: isCollapsible, [styles.closed]: !isOpen })}>
      {isOpen && <>
        <div className={styles.titleSection}>
          <Title className={styles.title} title={title} type='h3' />
          <div className={styles.secondary}>
            {hasButton && <Button title={buttonText} buttonType='secondary' color='gray' onClick={onButtonClick} />}
            {hasComplete && <span className={styles.complete}>
              <FontAwesomeIcon className={styles.icon} icon={['far', 'check']} />
              {completeText}
            </span>}
          </div>
        </div>
        {children && children}
      </>}
      {!isOpen && <Title className={styles.title} title={collapseText} type='h3' />}
      {isCollapsible && <div className={cn(styles.toggleBtn, { [styles.closed]: !isOpen })} onClick={handleToggle}>
        <FontAwesomeIcon className={styles.icon} icon={['far', 'chevron-down']} />
      </div>}
    </div>
  </div>;
};
  

Label.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  hasButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  hasComplete: PropTypes.bool,
  isError: PropTypes.bool,
  isLabelOpen: PropTypes.bool,
  completeText: PropTypes.string,
  isCollapsible: PropTypes.bool,
  collapseText: PropTypes.string,
  onToggle: PropTypes.func
};

export default Label;

import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FileUploader as DevExFileUploader } from 'devextreme-react';
import { filesize } from 'filesize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FileUploader.module.scss';

const FileUploader = ({ selectedFile, setSelectedFile, allowedFileExtensions, maxFileSize, isValid, disabled, requirements }) => {
  const uploaderRef = useRef(null);
  const [isDropZoneActive, setIsDropZoneActive] = useState(false);
  const [fileError, setError] = useState(null);

  const onDropZoneEnter = useCallback(
    (e) => {
      if (e.dropZoneElement.id === 'dropzone-external') {
        setIsDropZoneActive(true);
      }
    },
    [setIsDropZoneActive]
  );
  const onDropZoneLeave = useCallback(
    (e) => {
      if (e.dropZoneElement.id === 'dropzone-external') {
        setIsDropZoneActive(false);
      }
    },
    [setIsDropZoneActive]
  );

  const onValueChanged = e => {
    const file = e.value[0];
    const fileType = file && file.type;

    setError(null);

    if (!allowedFileExtensions.includes(fileType)) {
      setError(`${file.name} - Unsupported File Type`);
      return;
    }

    if (file && file.size > maxFileSize) {
      setError(`${file.name} - File size too large.`);
      return;
    }

    setSelectedFile(file);
  };

  const handleRemoveFile = e => {
    e.preventDefault();
    setSelectedFile(null);
  };

  return <>
    {selectedFile ? <div className={cn(styles.selectedFile, {
      [styles.disabled]: disabled
    })}>
      {selectedFile.name} - {filesize(selectedFile.size, { base: 10 })}
    </div > : <div>
      <div
        id='dropzone-external'
        className={cn('testingdrpop', {
          [styles.dropzoneActive]: isDropZoneActive
        })}
      >
        <div
          id='dropzone-text'
          className={cn(styles.dropContainer, {
            [styles.invalid]: !isValid
          })}
        >
          <div className={styles.chooseFile}>
            <FontAwesomeIcon icon={['far', 'cloud-arrow-up']} size='2x' />
            <div className={styles.text}>Choose File</div>
          </div>
          <div className={styles.instructionsHeader}>Drag and Drop File here.</div>
          <div className={styles.instructions}>
            {requirements.map((req, i) => <div key={i}>{req}</div>)}
          </div>
        </div>
      </div>
      <DevExFileUploader
        id='file-uploader'
        ref={uploaderRef}
        disabled={!!selectedFile}
        dialogTrigger='#dropzone-external'
        dropZone='#dropzone-external'
        multiple={false}
        accept={allowedFileExtensions.toString()}
        allowedFileExtensions={allowedFileExtensions}
        uploadMode='useButtons'
        visible={false}
        maxFileSize={maxFileSize}
        onDropZoneEnter={onDropZoneEnter}
        onDropZoneLeave={onDropZoneLeave}
        onValueChanged={onValueChanged}
      ></DevExFileUploader>
      {fileError && <div className={styles.fileError}>{fileError}</div>}
    </div>}</>;
};

FileUploader.defaultProps = {
  allowedFileExtensions: [],
  requirements: []
};

FileUploader.propTypes = {
  requirements: PropTypes.array,
  selectedFile: PropTypes.object,
  setSelectedFile: PropTypes.func,
  allowedFileExtensions: PropTypes.array,
  maxFileSize: PropTypes.number,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool
};

export default FileUploader;

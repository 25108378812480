import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { contactRoles, entities, errors, fields, personObj } from '../../../constants';
import { contactSection1, contactSection2, existingContactSection } from '../../../constants/forms/designatedContacts';
import { personEntityTitle } from '../../../utils/helper';
import { isDesignatedContactComplete } from '../../../utils/completes';
import { ProfileContext } from '../../../context/Profile.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../Button';
import CompanyAffiliation from '../../CompanyAffiliation';
import Controls from '../../Controls';
import EntityList from '../../EntityList';
import FormSection from '../../FormSection';
import Label from '../../Label';
import PreviousAdded from '../../PreviousAdded';
import RemovePerson from '../../RemovePerson';
import SlidePanel from '../../SlidePanel';
import Title from '../../Title';
import styles from './DesignatedContacts.module.scss';

const CONTACT_PARAM = fields.PRIMARY_CONTACT;
const PREV_ADDED_VIEW = 'previousAdded';
const FORM_VIEW = 'formView';
const REMOVE_PERSON_VIEW = 'removePerson';

const PrimaryContactsTab = ({ onPersonAdd, onPersonUpdate, onPersonRemove }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [contactView, setContactView] = useState(personObj);
  const [slidePanel, setSlidePanel] = useState(false);
  const [viewHistory, setViewHistory] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const peopleList = profileState.profile.peopleList;
  const primaryContactList = peopleList.filter(person => person[CONTACT_PARAM]);
  const prevAddedList = peopleList.filter(person => !person[CONTACT_PARAM] && (person.isOfficer || person.isDirector || person.isAuthorizedUser || person.isPrimaryBillingContact || person.isOtherBillingContact));
  const previousAddView = !contactView.id && prevAddedList && prevAddedList.length > 0;
  const contactTotal = peopleList.filter(person => person[CONTACT_PARAM]).length;
  const [slideView, setSlideView] = useState(previousAddView ? PREV_ADDED_VIEW : FORM_VIEW);
  const contactTotalTitle = `${contactTotal} out of 3 Primary Contacts`;
  const isContactViewExisting = contactView && (contactView.isOfficer || contactView.isDirector);
  const isExistingContact = isContactViewExisting;
  const isAdd = !contactView.id || (contactView && !contactView[CONTACT_PARAM]);
  const isReadOnly = profileState.readOnly;
  const isRemoveView = slideView === REMOVE_PERSON_VIEW;
  const slidePanelTitle = isRemoveView ? `Remove - ${personEntityTitle(contactView, false)}` : isAdd ? 'Add Primary Contact' : personEntityTitle(contactView, false); 

  useEffect(() => {
    if (!slidePanel) {
      setContactView(personObj);
      setSlideView(previousAddView ? PREV_ADDED_VIEW : FORM_VIEW);
      setValidationError(false);
    }
  }, [slidePanel, previousAddView]);

  const onValueChanged = person => {
    setContactView(person);
  };

  const onAffiliationChange = value => {
    setContactView({
      ...contactView,
      title1: value
    });
  };

  const handleEdit = person => {
    setSlideView(FORM_VIEW);
    setContactView({ ...person });
    setSlidePanel(true);
  };

  const handleCancelRemove = _ => {
    setSlidePanel(false);
  };

  const handleRemove = (e, person) => {
    e.stopPropagation(); 
    onPersonRemove(person, CONTACT_PARAM);
  };

  const handleRemoveCustom = (e, person) => {
    e.stopPropagation();
    setContactView(person);
    setSlideView(REMOVE_PERSON_VIEW);
    setSlidePanel(true);
  };

  const isCustomRemove = person => {
    const totalRoles = contactRoles.filter(role => person[role]);

    return totalRoles.length > 1;
  };

  const onRemoveSubmit = person => {
    onPersonUpdate(person);
    setSlidePanel(false);
  };

  const validateComplete = person => {
    return isDesignatedContactComplete(person, CONTACT_PARAM);
  };

  const handleCancelView = () => {
    viewHistory === PREV_ADDED_VIEW && prevAddedList && prevAddedList.length > 0 ? setSlideView(PREV_ADDED_VIEW) : setSlidePanel(false);
    setContactView(personObj);
  };

  const handleSubmitPerson = _ => {
    setValidationError(false);

    const contact = contactView;
    const onSubmit = !contact.id ? onPersonAdd : onPersonUpdate;

    contact[CONTACT_PARAM] = true;

    if (!isDesignatedContactComplete(contact, CONTACT_PARAM)) {
      setValidationError(true);
      return;
    }

    onSubmit(contact);
    setSlidePanel(false);
  };

  const handleSelectPrevContact = person => {
    const newContact = person;
    setViewHistory(PREV_ADDED_VIEW);
    setContactView(newContact);
    setSlideView(FORM_VIEW);
  };

  const handleAddNew = () => {
    setViewHistory(PREV_ADDED_VIEW);
    setSlideView(FORM_VIEW);
  };

  const renderPreviouslyAdded = () => (
    <div>
      <p>
        Select an individual previously entered, or Add a New Primary Contact.
      </p>
      <PreviousAdded
        listTitle='List of Individuals Previously Entered'
        items={prevAddedList}
        handleSelect={handleSelectPrevContact} />
      <div className='mtMed'>
        <Button
          title='Add New Primary Contact' 
          buttonType='secondary' 
          fullWidth
          onClick={handleAddNew} />
      </div>
      <div className={'mtXL'}>
        <Controls
          cancelText='Cancel'
          showSubmit={false}
          onCancelClick={() => setSlidePanel(false)} />
      </div>
    </div>
  );

  const renderAddNew = _ => {
    return <div>
      <Label>
        <FontAwesomeIcon className={styles.icon} icon={['far', 'lock']} />
        <Title className={styles.labelTitle} type='h3' title='Private Information' />
        <div>
          This icon denotes that the information is private and is not publicly displayed.
        </div>
      </Label>
      {!isExistingContact && <>
        <p>
          Enter title and name of Primary Contact.
        </p>
        <div className='mtMed'>
          {contactSection1.map((section, i) => <FormSection key={i} section={section} data={contactView} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
        </div>
      </>}
      <p>
        Enter phone number and email for Primary Contact.
      </p>
      {!isExistingContact && <div className='mtXL'>
        {contactSection2.map((section, i) => <FormSection key={i} section={section} data={contactView} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
        <div className={styles.innerGrid}>
          <div className={styles.col3}>
            <CompanyAffiliation
              label='Company Affiliation'
              isRequired
              readOnly={isReadOnly}
              value={contactView.title1 && contactView.title1}
              setCompanyAffilation={onAffiliationChange} />
          </div>
        </div>
      </div>}
      {isExistingContact && <div className='mtXL'>
        {existingContactSection.map((section, i) => <FormSection key={i} section={section} data={contactView} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
      </div>}
      {validationError && <Label className='mtLg' isError title={errors.INCOMPLETE_DATA} />}
      {!isReadOnly && <Controls
        className='mtXXL'
        cancelText='Cancel'
        submitText={isAdd ? 'Add' : 'Update'}
        onCancelClick={handleCancelView}
        onSubmitClick={handleSubmitPerson}
      />}
    </div>;
  };

  return (
    <div>
      <p className='mbLg'>
        Designate up to three primary contacts to respond to inquiries regarding this application and to receive important OTCQX U.S. communications, including compliance notices.
      </p>
      <div>
        <EntityList
          className='mbXL'
          title={contactTotalTitle}
          list={primaryContactList}
          confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
          showIncompleteList={contactTotal < 1}
          incompleteMessage={`Incomplete Information. Need at least 1 complete Primary Contact.`}
          icon={'user'}
          iconComplete={'userComplete'}
          entityTitle={person => personEntityTitle(person, false)}
          validateComplete={validateComplete}
          addTitle={contactTotal < 3 ? 'Add Primary Contact' : null}
          readOnly={isReadOnly}
          handleEntityClick={handleEdit}
          hasCustomRemove={isCustomRemove}
          handleEntityRemove={!isReadOnly && handleRemove}
          handleCustomRemove={!isReadOnly && handleRemoveCustom}
          handleAddClick={() => setSlidePanel(true)}
        />
      </div>
      <SlidePanel 
        isOpen={slidePanel}
        onClose={() => setSlidePanel(false)}
        title={slidePanelTitle}>
          {slideView === PREV_ADDED_VIEW && renderPreviouslyAdded()}
          {slideView === FORM_VIEW && renderAddNew()}
          {isRemoveView && <RemovePerson
            person={{ ...contactView }}
            selectedRemove={CONTACT_PARAM}
            onCancel={handleCancelRemove}
            onRemoveSubmit={onRemoveSubmit} />}
      </SlidePanel>
    </div>
  );
};

PrimaryContactsTab.propTypes = {
  onPersonAdd: PropTypes.func,
  onPersonUpdate: PropTypes.func,
  onPersonRemove: PropTypes.func
};

export default PrimaryContactsTab;

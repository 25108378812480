import _ from 'lodash';
import { isBoolean } from '../validations';
import { compareField } from '../helper';
import { compareFieldTypes, reportingStandards, warnings } from '../../constants';

export const getPeopleCompare = (currentProfile, updatedProfile) => {
    const json = [];
    const currentPeople = currentProfile.peopleList;
    const updatedPeople = updatedProfile.peopleList;
    const edgarFilingStatus = currentProfile.edgarFilingStatus;
    const isARS = edgarFilingStatus === reportingStandards.ALTERNATIVE;

    const currentControlList = currentProfile.controlPersons;
    const updateControlList = updatedProfile.controlPersons;

    const createFieldObj = (listType, id, title, fieldName, fieldType) => {
        const currentDataList = listType === compareFieldTypes.PEOPLE ? currentPeople : currentControlList;
        const updateDataList = listType === compareFieldTypes.PEOPLE ? updatedPeople : updateControlList;

        let oldValue = _.get(currentDataList.find(p => p.id === id), fieldName);
        let newValue = _.get(updateDataList.find(p => p.id === id), fieldName);

        switch (fieldType) {
            case compareFieldTypes.BOOLEAN:
                oldValue = oldValue ? 'Yes' : 'No';
                newValue = newValue ? 'Yes' : 'No';
                break;
            default:
            break;
        };

        const obj = {
            fieldName: title,
            oldValue,
            newValue
        };

        return obj;
    };

    // New People
    updatedPeople && updatedPeople.forEach(item => {
        const updatedPerson = item;

        if (!updatedPerson || updatedPerson.isDeleted || (!updatedPerson.isOfficer && !updatedPerson.isDirector && !updatedPerson.isChairPerson)) return;

        if (!currentPeople.find(current => current.id === updatedPerson.id)) {
            const personObj = {
                id: updatedPerson.id || null,
                header: `${updatedPerson.firstName} ${updatedPerson.lastName}${updatedPerson.isOfficer ? ', ' + updatedPerson.title1 : updatedPerson.isDirector ? ', Director' : ''}`,
                isNew: true,
                fields: []
            };
            
            // Roles and titles
            if (updatedPerson.isOfficer) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Officer', 'isOfficer', compareFieldTypes.BOOLEAN));
            if (updatedPerson.isDirector) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Director', 'isDirector', compareFieldTypes.BOOLEAN));
            if (updatedPerson.isChairPerson) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Chairperson', 'isChairPerson', compareFieldTypes.BOOLEAN));

            // Officer Titles
            if (updatedPerson.title1) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Title 1', 'title1'));
            if (updatedPerson.title2) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Title 2', 'title2'));
            if (updatedPerson.title3) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Title 3', 'title3'));
            if (updatedPerson.title4) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Title 4', 'title4'));

            // Director/Chairperson fields
            if (isBoolean(updatedPerson.isIndependent)) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Independent Director', 'isIndependent', compareFieldTypes.BOOLEAN));
            if (updatedPerson.isAuditCommittee) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Audit Committee', 'isAuditCommittee', compareFieldTypes.BOOLEAN));
            if (updatedPerson.isNominatingCommittee) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Nominating Committee', 'isNominatingCommittee', compareFieldTypes.BOOLEAN));
            if (updatedPerson.isCompensationCommittee) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Compensation Committee', 'isCompensationCommittee', compareFieldTypes.BOOLEAN));

            personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'First Name', 'firstName'));
            
            if (updatedPerson.middleName) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Middle Name', 'middleName'));
            
            personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Last Name', 'lastName'));
            personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Phone Country Code', 'phoneCountryCode'));
            personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Phone', 'phone'));
            personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Email', 'email'));

            // Add't Info (optional)
            if (updatedPerson.twitterId) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'X/Twitter Profile', 'twitterId'));
            if (updatedPerson.facebookId) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Facebook Profile', 'facebookId'));
            if (updatedPerson.linkedInId) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'LinkedIn Profile', 'linkedInId'));
            if (updatedPerson.biography) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Biography', 'biography'));

            json.push(personObj);
        }
    });

    // Update PERSON
    currentPeople && currentPeople.forEach((item, i) => {
        const currentPerson = item;
        const updatedPerson = updatedPeople.find(person => person.id === currentPerson.id);

        if (!updatedPerson || updatedPerson.isDeleted) return;

        const personObj = {
            id: currentPerson.id || null,
            header: `${currentPerson.firstName} ${currentPerson.lastName}${currentPerson.isOfficer ? ', ' + currentPerson.title1 : currentPerson.isDirector ? ', Director' : ''}`,
            fields: []
        };

        // Roles and titles
        compareField(currentPerson, updatedPerson, 'isOfficer', 'Officer', personObj, compareFieldTypes.BOOLEAN);
        compareField(currentPerson, updatedPerson, 'isDirector', 'Director', personObj, compareFieldTypes.BOOLEAN);
        compareField(currentPerson, updatedPerson, 'isChairPerson', 'Chairperson', personObj, compareFieldTypes.BOOLEAN);

        if ((updatedPerson.isOfficer || updatedPerson.isDirector || updatedPerson.isChairPerson)) {
            // Officer Titles
            compareField(currentPerson, updatedPerson, 'title1', 'Title 1', personObj);
            compareField(currentPerson, updatedPerson, 'title2', 'Title 2', personObj);
            compareField(currentPerson, updatedPerson, 'title3', 'Title 3', personObj);
            compareField(currentPerson, updatedPerson, 'title4', 'Title 4', personObj);

            // Director/Chairperson fields
            compareField(currentPerson, updatedPerson, 'isIndependent', 'Independent Director', personObj, compareFieldTypes.BOOLEAN);
            compareField(currentPerson, updatedPerson, 'isAuditCommittee', 'Audit Committee', personObj, compareFieldTypes.BOOLEAN);
            compareField(currentPerson, updatedPerson, 'isNominatingCommittee', 'Nominating Committee', personObj, compareFieldTypes.BOOLEAN);
            compareField(currentPerson, updatedPerson, 'isCompensationCommittee', 'Compensation Committee', personObj, compareFieldTypes.BOOLEAN);

            compareField(currentPerson, updatedPerson, 'firstName', 'First Name', personObj);
            compareField(currentPerson, updatedPerson, 'middleName', 'Middle Name', personObj);
            compareField(currentPerson, updatedPerson, 'lastName', 'Last Name', personObj);
            compareField(currentPerson, updatedPerson, 'phoneCountryCode', 'Phone Country Code', personObj);
            compareField(currentPerson, updatedPerson, 'phone', 'Phone', personObj);
            compareField(currentPerson, updatedPerson, 'email', 'Email', personObj);

            // Add't Info (optional)
            compareField(currentPerson, updatedPerson, 'twitterId', 'X/Twitter Profile', personObj);
            compareField(currentPerson, updatedPerson, 'facebookId', 'Facebook Profile', personObj);
            compareField(currentPerson, updatedPerson, 'linkedInId', 'LinkedIn Profile', personObj);
            compareField(currentPerson, updatedPerson, 'biography', 'Biography', personObj);
        }

        if (personObj.fields && personObj.fields.length > 0) json.push(personObj);
    });

    // Delete PERSON
    currentPeople && currentPeople.forEach(item => {
        const currentPerson = item;
        const updatedPerson = updatedPeople.find(person => person.id === currentPerson.id && person.isDeleted);
        if (updatedPerson) {
            const personObj = {
                id: currentPerson.id || null,
                header: `${currentPerson.firstName} ${currentPerson.lastName}${currentPerson.isOfficer ? ', ' + currentPerson.title1 : currentPerson.isDirector ? ', Director' : ''}`,
                isDeleted: true,
                fields: []
            };

            if (currentPerson.isOfficer !== updatedPerson.isOfficer) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Officer', 'isOfficer', compareFieldTypes.BOOLEAN));
            if (currentPerson.isDirector !== updatedPerson.isDirector) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Director', 'isDirector', compareFieldTypes.BOOLEAN));
            if (currentPerson.isChairPerson !== updatedPerson.isChairPerson) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Chairperson', 'isChairPerson', compareFieldTypes.BOOLEAN));

            if (currentPerson.isOfficer || currentPerson.isDirector || currentPerson.isChairPerson) json.push(personObj);
        }
    });

    // compareFieldTypes.CONTROL PERSONS
    if (currentProfile.noControlPerson && !updatedProfile.noControlPerson) {
        json.push({
            header: 'Additional Control Persons',
            fields: [
                {
                    fieldName: 'No Control persons',
                    oldValue: currentProfile.noControlPerson ? 'Yes' : 'No',
                    newValue: updatedProfile.noControlPerson ? 'Yes' : 'No'
                }
            ]
        });
    }

    if (!currentProfile.noControlPerson && updatedProfile.noControlPerson) {
        json.push({
            header: 'Additional Control Persons',
            fields: [
                {
                    fieldName: 'No Control persons',
                    oldValue: currentProfile.noControlPerson ? 'Yes' : 'No',
                    newValue: updatedProfile.noControlPerson ? 'Yes' : 'No'
                }
            ]
        });
    } else {
        // New Control
        updateControlList && updateControlList.forEach(item => {
            const updatedControl = item;
            
            if (updatedControl.isDeleted) {
                return;
            };

            if (!currentControlList.find(current => current.id === updatedControl.id)) {
                const controlObj = {
                    header: `${updatedControl.isCorpEntity ? 'Corporate Entity - ' + updatedControl.corpEntity : updatedControl.firstName + ' ' + updatedControl.lastName + ', Individual Control Person'}`,
                    isNew: true,
                    fields: []
                };
    
                if (isARS && updatedControl.isCorpEntity && !updatedControl.hasBeneficialOwners) {
                    controlObj.warning = true;
                    controlObj.warningText = warnings.NO_BENEFICIAL_OWNERS;
                }

                if (!updatedControl.isCorpEntity) controlObj.fields.push({
                    fieldName: 'Individual Control Person',
                    newValue: 'Yes'
                });
                if (updatedControl.isCorpEntity) controlObj.fields.push(createFieldObj(compareFieldTypes.CONTROL, updatedControl.id, 'Corporate Entity', 'isCorpEntity', compareFieldTypes.BOOLEAN));
    
                if (updatedControl.isCorpEntity) controlObj.fields.push(createFieldObj(compareFieldTypes.CONTROL, updatedControl.id, 'Beneficial Owners', 'hasBeneficialOwners', compareFieldTypes.BOOLEAN));
                if (updatedControl.reasonForNone) controlObj.fields.push(createFieldObj(compareFieldTypes.CONTROL, updatedControl.id, 'Reason For No Beneficial Owners', 'reasonForNone'));
                if (updatedControl.firstName) controlObj.fields.push(createFieldObj(compareFieldTypes.CONTROL, updatedControl.id, 'First Name', 'firstName'));
                if (updatedControl.middleName) controlObj.fields.push(createFieldObj(compareFieldTypes.CONTROL, updatedControl.id, 'Middle Name', 'middleName'));
                if (updatedControl.lastName) controlObj.fields.push(createFieldObj(compareFieldTypes.CONTROL, updatedControl.id, 'Last Name', 'lastName'));
    
                json.push(controlObj);
            }
        });

        // Update Control
        currentControlList && currentControlList.forEach(item => {
            const currentControl = item;
            const updatedControl = updateControlList.find(c => c.id === currentControl.id);

            if (!updatedControl || updatedControl.isDeleted) return;

            const controlObj = {
                header: `${currentControl.isCorpEntity ? 'Corporate Entity - ' + currentControl.corpEntity : currentControl.firstName + ' ' + currentControl.lastName + ', Individual Control Person'}`,
                fields: []
            };

            if (isARS && updatedControl.isCorpEntity && !updatedControl.hasBeneficialOwners) {
                controlObj.warning = true;
                controlObj.warningText = warnings.NO_BENEFICIAL_OWNERS;
            }

            compareField(currentControl, updatedControl, 'hasBeneficialOwners', 'Beneficial Owners', controlObj, compareFieldTypes.BOOLEAN);
            compareField(currentControl, updatedControl, 'reasonForNone', 'Reason For No Beneficial Owners', controlObj);
            compareField(currentControl, updatedControl, 'firstName', 'First Name', controlObj);
            compareField(currentControl, updatedControl, 'middleName', 'Middle Name', controlObj);
            compareField(currentControl, updatedControl, 'lastName', 'Last Name', controlObj);

            if (controlObj.fields && controlObj.fields.length > 0) json.push(controlObj);
        });

        // Delete Control
        currentControlList && currentControlList.forEach(item => {
            const currentControl = item;

            if (updateControlList.find(c => c.id === currentControl.id && c.isDeleted)) {
                const corpEntName = currentControl.hasBeneficialOwners ? currentControl.corpEntity + ' ' + currentControl.firstName + ' ' + currentControl.lastName : '';
                const controlObj = {
                    header: `${currentControl.isCorpEntity ? 'Corporate Entity - ' + corpEntName : currentControl.firstName + ' ' + currentControl.lastName + ', Individual Control Person'}`,
                    isDeleted: true,
                };

                json.push(controlObj);
            }
        });
    }

    // RETURN FINAL JSON COMPARISON
    return json;
};

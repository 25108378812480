import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAddressComplete, isRequiredCountry } from '../../utils/completes';
import { errors } from '../../constants';
import { addressForm } from '../../constants/forms/contactInformation';
import Controls from '../Controls';
import Entity from '../Entity';
import Label from '../Label';
import FormSection from '../FormSection';
import SlidePanel from '../SlidePanel';
import styles from './Entity.module.scss';

const AddressEntity = ({ title, data, isStatic, staticData, addressKey, onUpdate, isReadOnly, footNote }) => {
    const [slidePanel, setSlidePanel] = useState(false);
    const [addressData, setAddressData] = useState({ ...data });
    const [validationError, setValidationError] = useState(false);
    const addressObj = isStatic ? staticData : data;
    const addressTitle = addressObj && `${addressObj.address1 ? addressObj.address1 : ''}${addressObj.address2 ?  ' ' + addressObj.address2 : ''}
    ${addressObj.city ?  ' ' + addressObj.city : ''}
    ${addressObj.state ?  ' ' + addressObj.state : ''}
    ${addressObj.zip ?  ' ' + addressObj.zip : ''}`;
    const hasAddress = addressObj && (addressObj.address1 || addressObj.address2
        || addressObj.city || addressObj.state || addressObj.zip) && addressTitle.length > 1 ? true : false;
    const isAdd = !data ? true : false;
    const isComplete = isAddressComplete({ ...addressData });

    useEffect(() => {
        if (!slidePanel) {
            setAddressData({ ...data });
        }
    }, [slidePanel, setAddressData, data]);

    const onValueChange = value => {
        if (addressData.countryId !== value.countryId) value.state = null;
        setAddressData(value);
    };

    const handleUpdate = _ => {
        setValidationError(false);

        if (!addressData.address1 || !addressData.countryId || !addressData.city || (isRequiredCountry(addressData.countryId) && !addressData.state) || !addressData.zip) {
            setValidationError(true);
            return;
        }

        onUpdate(addressData, addressKey);
        slideClose();
    };

    const slideClose = _ => {
        setSlidePanel(false);
    };

    const onEntityClick = () => {
        setSlidePanel(true);
    };

    return (
        <>
            {!isStatic && <Entity
                title={hasAddress ? addressTitle : title}
                subTitle={isReadOnly ? 'View' : hasAddress ? 'Edit' : 'Add'}
                isComplete={isComplete}
                validateComplete={isComplete}
                icon='location'
                onClick={onEntityClick}
            />}
            {isStatic && <div className={styles.static}>
                <span className={styles.icon}>
                    <FontAwesomeIcon icon={['fal', 'location-dot']} />
                </span>
                <span>{addressTitle}</span>
            </div>}
            <SlidePanel
                isOpen={slidePanel}
                onClose={slideClose}
                title={title}>
                    <div>
                        {addressForm.map((section, i) => <FormSection key={i} section={section} data={{ ...addressData }} readOnly={isReadOnly} handleValueChange={onValueChange} />)}
                    </div>
                    {footNote && <span>{footNote}</span>}
                    {validationError && <Label isError title={errors.INCOMPLETE_DATA} />}
                    {!isReadOnly && <Controls
                        className='mtXXL'
                        cancelText='Cancel'
                        submitText={isAdd ? 'Add' : 'Update'}
                        onCancelClick={slideClose}
                        onSubmitClick={handleUpdate}
                    />}
            </SlidePanel>
        </>
    );
};

AddressEntity.propTypes = {
    title: PropTypes.string,
    footNote: PropTypes.string,
    data: PropTypes.object,
    isStatic: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    staticData: PropTypes.object,
    addressKey: PropTypes.string,
    onUpdate: PropTypes.func
};

export default AddressEntity;

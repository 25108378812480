export let yearsList = [];

export let futureYearsList = [];

const current = new Date().getFullYear();
const max = current + 20;
const min = 1800;

for (let i = min; i <= current; i++) {
  yearsList.unshift(i);
};

for (let i = current; i <= max; i++) {
  futureYearsList.push(i);
};

import _ from 'lodash';
import moment from 'moment-timezone';
import { format } from '../locale';
import { entities } from '../../constants';
import { isDate } from '../validations';
import { compareField } from '../helper';
import { compareFieldTypes } from '../../constants';

export const getSecuritiesCompare = (currentProfile, updatedProfile) => {
    const json = [];
    const currentSecurities = currentProfile.securityLists;
    const updatedSecurities = updatedProfile.securityLists;

    // Check for new Inc His
    updatedSecurities && updatedSecurities.forEach(item => {
        const updatedSecurity = item;
        const symbolIdentifier = updatedSecurity.metadata[0].symbol;
        const currentSecurity = currentSecurities.find(sec => sec.metadata.find(sm => sm.symbol === symbolIdentifier));
        let title = [entities.NO_NAME];

        const securityTitles = updatedSecurity.metadata && updatedSecurity.metadata.map(metadata => {
            return `${metadata.symbol}/${metadata.classname}`;
        });
    
        if (securityTitles && securityTitles.length > 0) title = securityTitles.join(', ');

        const securityInfo = {
            header: title,
            fields: []
        };

        compareField(currentSecurity, updatedSecurity, 'authorizedShares', 'Shares Authorized', securityInfo, compareFieldTypes.NUMBER);
        compareField(currentSecurity, updatedSecurity, 'authorizedSharesAsOfDate', 'Shares Authorized As Of', securityInfo, compareFieldTypes.DATE);
        compareField(currentSecurity, updatedSecurity, 'unlimitedAuthorizedShares', 'Unlimited Number of Shares Authorized', securityInfo, compareFieldTypes.BOOLEAN);
        
        compareField(currentSecurity, updatedSecurity, 'outstandingShares', 'Total Shares Outstanding', securityInfo, compareFieldTypes.NUMBER);
        compareField(currentSecurity, updatedSecurity, 'outstandingSharesAsOfDate', 'Total Shares Outstanding As Of', securityInfo, compareFieldTypes.DATE);
        
        compareField(currentSecurity, updatedSecurity, 'roundLotShareholders', 'Beneficial Shareholders at least 100 shares', securityInfo, compareFieldTypes.NUMBER);
        compareField(currentSecurity, updatedSecurity, 'roundLotShareholdersDate', 'Beneficial Shareholders at least 100 shares As Of', securityInfo, compareFieldTypes.DATE);
        
        compareField(currentSecurity, updatedSecurity, 'parValue', 'Par Value', securityInfo, compareFieldTypes.NUMBER);
        compareField(currentSecurity, updatedSecurity, 'noParValue', 'No Par Value', securityInfo, compareFieldTypes.BOOLEAN);
        
        compareField(currentSecurity, updatedSecurity, 'publicFloat', 'Public Float', securityInfo, compareFieldTypes.NUMBER);
        compareField(currentSecurity, updatedSecurity, 'publicFloatAsOfDate', 'Public Float As Of', securityInfo, compareFieldTypes.DATE);

        if (securityInfo.fields && securityInfo.fields.length > 0) json.push(securityInfo);
    });

    // RETURN FINAL JSON COMPARISON
    return json;
};

import api from './api';

export const getCompanyLogo = async symbol => {
  const response = await api.request({
    method: 'get',
    responseType: 'blob',
    url: `/company/logo?symbol=${symbol}`
  });
  return response.data;
};

export const getCompanyLogoMetaData = async symbol => {
  const response = await api.request({
    method: 'get',
    isCompany: true,
    url: `/company/logo/metadata?symbol=${symbol}`
  });
  return response.data;
};

export const deleteCompanyLogo = async (symbol, uid) => {
  const response = await api.request({
    method: 'delete',
    isCompany: true,
    url: `/company/logo`,
    params: {
      symbol,
      uid
    }
  });
  return response.data;
};

export async function uploadCompanyLogo (symbol, file) {
  let formData = new FormData();
  formData.append('file', file);

  const response = await api.request({
    method: 'post',
    isCompany: true,
    url: '/company/logo/upload',
    params: {
      symbol
    },
    data: formData
  });
  return response.data;
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { personTitles } from '../../constants';
import FieldLabel from '../FieldLabel';
import SelectBox from '../SelectBox';
import styles from './TitleSelect.module.scss';

const ADD = 'add';

const titles = personTitles;

const TitleSelect = ({ label, onSelection, selectedTitles, appendTitles, value }) => {
  const [titleValue, setTitleValue] = useState(value ? value : null);
  const hideTitles = selectedTitles && selectedTitles.length > 0;
  let originalTitleItems = titles;
  let titleItems = titles;

  const ItemRender = item => {
    if (!originalTitleItems.includes(item)) return <div>Add {item} as title</div>;
    return <div>{item}</div>;
  };

  if (appendTitles) originalTitleItems = appendTitles.concat(originalTitleItems);
  if (hideTitles) originalTitleItems = titles.filter(title => !selectedTitles.includes(title));
  titleItems = [...originalTitleItems];

  const onSelectChanged = value => {
    setTitleValue(value);
    onSelection(value);
  };

  const handleOptionChanged = e => {
    if (e.name === 'text' && e.value) {
      const hasTitle = titles.find(t => t.toLowerCase().startsWith(e.value.toLowerCase()));
      const titlesLength = titleItems.length;
      if (!hasTitle) {
        if (titlesLength === originalTitleItems.length) titleItems.push(e.value);
        if (titlesLength !== originalTitleItems.length) titleItems.splice(titlesLength - 1, 1, e.value);
      }

      if (hasTitle && titlesLength !== originalTitleItems.length) titleItems.pop();
    }
  };

  return <div className={styles.container}>
    {label && <FieldLabel text={label} />}
    <SelectBox
      id='title-select'
      placeholder='Title'
      value={titleValue}
      items={titleItems}
      acceptCustomValue
      itemRender={ItemRender}
      onOptionChanged={handleOptionChanged}
      onValueChanged={e => onSelectChanged(e.value)} />
  </div>;
};

TitleSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onSelection: PropTypes.func,
  selectedTitles: PropTypes.array,
  appendTitles: PropTypes.array
};

export default TitleSelect;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './icons';
import 'normalize.css/normalize.css';
import './styles/dx.common.scss';
import './styles/dx.light.scss';
import './styles/global.scss';

let root;
const component = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// load OTC fonts
WebFont.load({
  google: {
    families: [
      'Nunito Sans:200,200i,400,400i,600,600i,700,700i,800,800i900,900i',
      'Source Serif Pro:400,700'
    ]
  }
});

if (window.location.port === '3004') {
  const rootElement = document.getElementById('root');
  root = ReactDOM.createRoot(rootElement);
  root.render(component);
}


window.renderMicro = (containerId) => {
  const rootElement = document.getElementById(containerId);
  if (!rootElement) return;

  root = ReactDOM.createRoot(rootElement);
  root.render(component);
};


// load pages with path without micro front end
if(window.location.pathname.includes('/company-logo')){
  window.renderMicro('root')
}


window.unmountApp = _ => {
  root.unmount();
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState, useContext } from 'react';
import cn from 'classnames';import PropTypes from 'prop-types';
import { contactRoles, entities, fields,personObj } from '../../../constants';
import { ProfileContext } from '../../../context/Profile.context';
import { isDirectorComplete } from '../../../utils/completes';
import { isTierOTCQB, isTierOTCQX, personEntityTitle } from '../../../utils/helper';
import Button from '../../Button';
import Controls from '../../Controls';
import EntityList from '../../EntityList';
import DirectorForm from './DirectorForm';
import PreviousAdded from '../../PreviousAdded';
import RemovePerson from '../../RemovePerson';
import SlidePanel from '../../SlidePanel';
import styles from './People.module.scss';

const PREV_ADDED_VIEW = 'previousAdded';
const FORM_VIEW = 'formView';
const REMOVE_PERSON_VIEW = 'removePerson';
const isDirector = fields.DIRECTOR;
const isChairPerson = fields.CHAIR_PERSON;

const Chairpersons = ({ onPersonAdd, onPersonUpdate, onPersonRemove }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [slidePanel, setSlidePanel] = useState(false);
  const [directorView, setDirectorView] = useState(personObj);
  const [listFocus, setListFocus] = useState(false);
  const [viewHistory, setViewHistory] = useState(null);
  const peopleList = profileState.profile.peopleList;
  const chairpersonList = peopleList && peopleList.filter(person => person[isDirector] && person[isChairPerson]);
  const officerList = peopleList && peopleList.filter(person => person.isOfficer && !person[isDirector]);
  const previousAddView = officerList && officerList.length > 0;
  const [slideView, setSlideView] = useState(previousAddView ? PREV_ADDED_VIEW : FORM_VIEW);
  const isReadOnly = profileState.readOnly;
  const isAdd = !directorView.id || directorView && !directorView[isDirector];
  const addTitle = 'Add Chairperson';
  const profileTier = profileState.profile.companyTier;
  const isRemoveView = slideView === REMOVE_PERSON_VIEW;
  const slidePanelTitle = isRemoveView ? `Remove - ${personEntityTitle(directorView, false)}` : isAdd ? addTitle : personEntityTitle(directorView, false); 

  useEffect(() => {
    if (!slidePanel) {
      setDirectorView(personObj);
      setSlideView(previousAddView ? PREV_ADDED_VIEW : FORM_VIEW);
    }
  }, [slidePanel, previousAddView]);

  const onValueChanged = person => {
    setDirectorView(person);
  };

  const handleCancelRemove = _ => {
    setSlidePanel(false);
  };

  const handleEdit = person => {
    setSlideView(FORM_VIEW);
    setDirectorView({ ...person });
    setSlidePanel(true);
  };

  const handlePersonAdd = person => {
    onPersonAdd(person);
    setSlidePanel(false);
  };

  const handlePersonUpdate = person => {
    onPersonUpdate(person);
    setSlidePanel(false);
  };

  const handleCancel = _ => {
    viewHistory === PREV_ADDED_VIEW && officerList && officerList.length > 0 ? setSlideView(PREV_ADDED_VIEW) : setSlidePanel(false);
  };

  const handleSelectOfficer = person => {
    const newOfficer = person;
    setViewHistory(PREV_ADDED_VIEW);
    setDirectorView(newOfficer);
    setSlideView(FORM_VIEW);
  };

  const handleRemove = (e, person) => {
    e.stopPropagation(); 
    onPersonRemove(person, isChairPerson);
  };

  const handleRemoveCustom = (e, person) => {
    e.stopPropagation();
    setDirectorView(person);
    setSlideView(REMOVE_PERSON_VIEW);
    setSlidePanel(true);
  };

  const isCustomRemove = person => {
    const totalRoles = contactRoles.filter(role => person[role]);

    return totalRoles.length > 1;
  };

  const onRemoveSubmit = person => {
    if (!person[isDirector]) person[isChairPerson] = false;
   
    delete person.isIndependent;
    delete person.isAuditCommittee;
    delete person.isNominatingCommittee;
    delete person.isCompensationCommittee;

    onPersonUpdate(person);
    setSlidePanel(false);
  };

  const handleAddNew = () => {
    setViewHistory(PREV_ADDED_VIEW);
    setSlideView(FORM_VIEW);
  };

  const validateComplete = person => {
    return isDirectorComplete(person);
  };

  const renderPreviouslyAdded = () => (
    <div>
      <p>
        Select individual previously entered as an Officer/Contact or add a new individual below
      </p>
      <PreviousAdded
        listTitle='All Officers or Contacts Previously Added'
        items={officerList}
        handleSelect={handleSelectOfficer} />
      <div className='mtMed'>
        <Button
          title='Add New Chairperson' 
          buttonType='secondary' 
          fullWidth
          onClick={handleAddNew} />
      </div>
      <div className={cn('mtXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          showSubmit={false}
          onCancelClick={() => setSlidePanel(false)} />
      </div>
    </div>
  );

  return (
    <div className='mtXL'
        onMouseEnter={() => setListFocus(true)}
        onMouseLeave={() => setListFocus(false)}>
        <div>
            <EntityList
            className='mbXL'
            title='Chairpersons'
            list={chairpersonList}
            confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
            incompleteMessage='At least one member is required on the Board of Directors.'
            icon={'user'}
            iconComplete={'userComplete'}
            entityTitle={person => personEntityTitle(person, false)}
            validateComplete={validateComplete}
            addTitle={addTitle}
            readOnly={isReadOnly}
            handleEntityClick={handleEdit}
            hasCustomRemove={isCustomRemove}
            handleEntityRemove={!isReadOnly && handleRemove}
            handleCustomRemove={!isReadOnly && handleRemoveCustom}
            handleAddClick={() => setSlidePanel(true)}
            />
            <SlidePanel 
                isOpen={slidePanel}
                onClose={() => setSlidePanel(false)}
                title={slidePanelTitle}>
                  {slideView === PREV_ADDED_VIEW && renderPreviouslyAdded()}
                  {slideView === FORM_VIEW && <DirectorForm
                    person={{ ...directorView }}
                    isChairPerson
                    isAdd={isAdd}
                    isReadOnly={isReadOnly}
                    onCancel={handleCancel}
                    onPersonAdd={handlePersonAdd}
                    onPersonUpdate={handlePersonUpdate}
                    onValueChanged={onValueChanged} />}
                  {isRemoveView && <RemovePerson
                    person={{ ...directorView }}
                    selectedRemove={isDirector}
                    onCancel={handleCancelRemove}
                    onRemoveSubmit={onRemoveSubmit} />}
            </SlidePanel>
        </div>
    </div>
  );
};

Chairpersons.propTypes = {
  onPersonAdd: PropTypes.func,
  onPersonUpdate: PropTypes.func,
  onPersonRemove: PropTypes.func
};

export default Chairpersons;

const PROFILE_PREFIX = '/app/manage-company-profile';

export const sortTierIds = [
    1,
    2,
    5,
    6,
    10,
    11,
    20,
    21,
    22,
    30,
    40,
    50,
    51,
    0
];

export const fields = {
    CORPORATE_ENTITY_LIST: 'corporateEntityList',
    PEOPLE_LIST: 'peopleList',
    CONTROL_PERSONS: 'controlPersons',
    AUTH_USER: 'isAuthorizedUser',
    BENEFICIAL_OWNER: 'isCorporateEntity',
    DIRECTOR: 'isDirector',
    OFFICER: 'isOfficer',
    OTHER_BILLING: 'isOtherBillingContact',
    PRIMARY_BILLING: 'isPrimaryBillingContact',
    PRIMARY_CONTACT: 'isPrimaryContact',
    CHAIR_PERSON: 'isChairPerson',
    DO_NOT_PUBLISH:'doNotPublish'
};

export const contactRoles = [
    fields.OFFICER,
    fields.DIRECTOR,
    fields.BENEFICIAL_OWNER,
    fields.PRIMARY_CONTACT,
    fields.PRIMARY_BILLING,
    fields.OTHER_BILLING,
    fields.AUTH_USER,
];

export const appStatuses = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    CANCELLED: 'CANCELLED',
    REOPENED: 'REOPENED',
    SUBMITTED: 'SUBMITTED',
    APPROVED: 'APPROVED',
    PENDING_REVIEW: 'PENDING_REVIEW',
    EXPIRED: 'EXPIRED',
    CLOSED: 'CLOSED',
    COMPLETE: 'COMPLETE',
    NO_APPLICATION: 'NO_APPLICATION'
};

export const sectionTitles = {
    INCORP_HISTORY: 'Incorporation History',
    COMPANY_NAME_HISTORY: 'Company Name History'
};

export const countries = {
    US: 'US',
    USA: 'USA',
    UNITED_STATES: 'United States',
    US_OF_A: 'United States of America',
    CA: 'CA',
    CAN: 'CAN',
    CANADA: 'Canada',
    AU: 'AU',
    AUS: 'AUS',
    AUSTRALIA: 'Australia'
  };

export const timezones = {
    NEW_YORK: 'America/New_York'
};

export const reportingStandards = {
    SEC: 'SEC Reporting',
    REGULATION: 'Regulation A Reporting Standard',
    ALTERNATIVE: 'Alternative Reporting Standard',
    BANK: 'Bank/Thrift',
    INTERNATIONAL: 'International Reporting'
};

export const routes = {
    DASHBOARD: '/dashboard/app/manage-company-profile',
    PROFILE_PREFIX: PROFILE_PREFIX,
    PROFILE_FORM: `${PROFILE_PREFIX}/:symbol/:formSection?/:formTab?`,
    COMPANY_LOGO: `company-logo/:symbol`,
    COMPANY_INFO: 'company-information',
    CONTACT_INFO: 'contact-information',
    PEOPLE: 'people',
    OFFICERS: 'officers-contacts',
    DIRECTORS: 'directors',
    CONTROL_PERSONS: 'control-persons',
    SERVICE_PROVIDERS: 'service-providers',
    DESIGNATE_CONTACTS: 'designate-contacts',
    PRIMARY_CONTACTS: 'primary-contacts',
    AUTHORIZED_USERS: 'authorized-users',
    BILLING_CONTACTS: 'billing-contacts',
    SECURITIES: 'securities',
    REVIEW_SUBMIT: 'review-submit'
};

export const verifiedSectionKeys = {
    COMPANY_INFO: 'companyInfo',
    CONTACT_INFO: 'contactInfo',
    PEOPLE: 'people',
    SERVICE_PROVIDERS: 'serviceProviders',
    DESIGNATE_CONTACTS: 'designatedContacts',
    SECURITIES: 'securities'
};

export const storage = {
    AP_USER: 'ap_user',
    INSTRUCTIONS: 'cuf_instructions',
    COUNTRY_CODES: 'ap_cc',
    COUNTRY_CODES_TIME: 'ap_cc_time',
    TOKEN: 'token'
};

export const globalTitles = {
    HELP: 'Help Section'
};

export const fieldTypes = {
    TEXTBOX: 'textbox',
    TEXTBOX_PHONE: 'textboxphone',
    NUMBERBOX: 'numberbox',
    TEXTAREA: 'textarea',
    SELECT: 'selectbox',
    DATEBOX: 'datebox',
    CHECKBOX: 'checkbox',
    PHONE: 'phone',
    LABEL: 'label'
};

export const errors = {
    DUPLICATE_DATA: 'Information entered above already exist.',
    INCOMPLETE_DATA: 'Incomplete Information. Please enter information for all necessary fields.',
    INCOMPLETE_DATA_FIELD: 'Incomplete Data Field. Please enter information to resolve.',
    FAILED_PROFILE: 'Failed to retrieve profile data.',
    FAILED_REPORT_PROBLEM: 'Failed to submit problem. Please try again.',
    FUTURE_DATE: 'Date cannot be Future Date.',
    INVALID_EMAIL: 'Invalid Email Format.',
    INVALID_PHONE: 'Invalid Phone Format.',
    INVALID_WEBSITE: 'Invalid Format. Please use https://www.website.com',
    INC_HISTORY_EMPTY: 'At least one Incorporation History is required',
    INC_HISTORY_INVALID: 'Ensure that the State or Province (where applicable), Country, and Year of Incorporation are entered for each entry'
};

export const filingCycles = [
    { id: 'Q', text: 'Quarterly' },
    { id: 'S', text: 'Semiannually' },
    { id: 'A', text: 'Annually' }
];

export const entities = {
    NO_NAME: '(No Name Available)',
    CONFIRM_REMOVE_PERSON: 'Are you sure you want to delete this person?',
    CONFIRM_REMOVE_CORP_ENTITY: 'Are you sure you want to delete this corporate entity?',
    CONFIRM_REMOVE_SP: 'Are you sure you want to delete this service provider?',
    CONFIRM_REMOVE_SECURITY: 'Are you sure you want to delete this security?',
    CONFIRM_REMOVE_DOCUMENT: 'Are you sure you want to delete this document?',
    CONFIRM_REMOVE_OWNER: 'Are you sure you want to delete this owner?'
};

export const noControlPersonText = 'There are No Additional Control Persons beneficially owning 10% or more of any class of any equity security of the issuer.';

export const urls = {
    GLOSSARY_PUBLIC_FLOAT: 'https://www.otcmarkets.com/glossary#public-float',
    GLOSSARY_BENEFICIAL_SHAREHOLDER: 'https://www.otcmarkets.com/glossary#beneficial-shareholder',
    GLOSSARY_IND_DIR: 'https://www.otcmarkets.com/glossary#independent-director',
    PRIVACY_POLICY: 'https://www.otcmarkets.com/privacy-statement',
    TAVSP: 'https://www.otcmarkets.com/corporate-services/transfer-agent-verified-shares-program',
    STOCK_PROMO_POLICY: 'https://www.otcmarkets.com/files/OTC_Markets_Group_Policy_on_Stock_Promotion.pdf',
    BEST_PRACTICE_ISSUERS: 'https://www.otcmarkets.com/files/Best_Practices_for_Issuers_Stock_Promotion.pdf',
    GLOSSARY: 'https://www.otcmarkets.com/glossary',
    FAQS: 'https://www.otcmarkets.com/learn/faqs',
    HOME: 'https://www.otcmarkets.com'
};

export const reviewColumns = [
    {
        name: 'fieldName',
        header: 'Field Name'
    },
    {
        name: 'oldValue',
        header: 'Old',
        wordWrap: true,
        wordBreak: true
    },
    {
        name: 'newValue',
        header: 'New',
        wordWrap: true,
        wordBreak: true
    }
];

export const warnings = {
    NO_BENEFICIAL_OWNERS: 'NOTE: No beneficial owner has been identified for this Corporate Entity',
    DO_NOT_REFRESH: 'Please wait while we process your request. Do not refresh your screen.'
};

export const personTitles = [
    'CEO',
    'CFO',
    'CMO',
    'COO',
    'CTO',
    'Chief Accounting Officer',
    'Chief Business Officer',
    'Company Secretary',
    'Executive Director',
    'General Counsel',
    'Head of Investor Relations',
    'Managing Director',
    'President',
    'Treasurer',
    'Vice President'
];

export const corporateEntityObj = {
    corpEntity: null,
    newCorpEntity: null,
    hasBeneficialOwners: null,
    isCorpEntity: null,
    reasonForNone: null,
    id: null,
    firstName: null,
    middleName: null,
    lastName: null
};

export const personObj = {
    email: null,
    corpEntity: null,
    firstName: null,
    id: null,
    isAuditCommittee: null,
    isAuthorizedUser: null,
    isBeneficialOwner: null,
    isChairPerson: null,
    isCompensationCommittee: null,
    isCorporateEntity: null,
    isDirector: null,
    isIndependent: null,
    isNominatingCommittee: null,
    isOfficer: false,
    isOtherBillingContact: null,
    isPrimaryBillingContact: null,
    isPrimaryContact: null,
    lastName: null,
    middleName: null,
    phone: null,
    phoneCountryCode: null,
    title1: null,
    title2: null,
    title3: null,
    title4: null,
    titles: null
};

export const emails = {
    ISSUER_SERVICES: 'mailto:issuers@otcmarkets.com'
};

export const compareFieldTypes = {
    BOOLEAN: 'boolean',
    DATE: 'date',
    MMDDYYYYY: 'MM/DD/YYYY',
    COUNTRY: 'country',
    STATE: 'state',
    PEOPLE: 'people',
    CONTROL: 'control',
    NUMBER: 'number'
};

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const asciiCodes = {
    NUM_ZERO: '0'.charCodeAt(0),
    NUM_NINE: '9'.charCodeAt(0),
    CHAR_BIG_A: 'A'.charCodeAt(0),
    CHAR_BIG_Z: 'Z'.charCodeAt(0),
    CHAR_SMALL_A: 'a'.charCodeAt(0),
    CHAR_SMALL_Z: 'z'.charCodeAt(0),
    CHAR_STAR: '*'.charCodeAt(0),
    CHAR_AT: '@'.charCodeAt(0),
    CHAR_POUND: '#'.charCodeAt(0)

};

export const editControls = {
    EDIT: 'Edit',
    CANCEL: 'Cancel'
};

export const phoneNumberRegex = /^[+]\d+$/;

export const mfaPhoneNumberRegex = /^[+]?[\d -]+$/;

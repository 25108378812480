import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '../Button';
import styles from './Controls.module.scss';

const Controls = ({ className, hideCancel, showSubmit, submitColor, cancelText, size, isSubmitDisabled, isLoading, isSubmitSuccess, submitText, onCancelClick, onSubmitClick }) => {
  return <div className={cn(styles.container, className, {
    [styles.oneButton] : hideCancel
  })}>
    {!hideCancel && <Button
      title={cancelText || 'Cancel'}
      buttonType='secondary'
      color='gray'
      fullWidth
      size={size}
      onClick={onCancelClick} />}
    {showSubmit && <Button
      title={submitText || 'Save'}
      color={submitColor || 'gray'}
      fullWidth
      size={size}
      showLoader={isLoading}
      inactive={!isSubmitSuccess && (isSubmitDisabled || isLoading)}
      onClick={!isSubmitSuccess && onSubmitClick} />}
  </div>;
};

Controls.propTypes = {
  className: PropTypes.string,
  hideCancel: PropTypes.bool,
  showSubmit: PropTypes.bool,
  submitColor: PropTypes.any,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  onCancelClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitSuccess: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Controls.defaultProps = {
  hideCancel: false,
  showSubmit: true,
  isSubmitDisabled: false,
  isLoading: false
};

export default Controls;

import React, { useEffect, useState } from 'react';
import { storage } from '../../constants';
import Label from '../Label';

const FormInstructions = () => {
  const INSTRUCTIONS = storage.INSTRUCTIONS;
  let storageInstructions = window.localStorage.getItem(INSTRUCTIONS);
  if (storageInstructions) storageInstructions = JSON.parse(window.localStorage.getItem(INSTRUCTIONS));
  const [isLabelOpen, setToggle] = useState(storageInstructions || { state: true });

  useEffect(() => {
    if (window.localStorage.getItem(INSTRUCTIONS)) {
      const obj = JSON.parse(window.localStorage.getItem(INSTRUCTIONS));
      setToggle(obj);
    }
  }, [INSTRUCTIONS]);

  const handleToggleClick = callback => {
    const storageObj = { state: callback, time: new Date().getTime() };
    setToggle(callback);
    window.localStorage.setItem(INSTRUCTIONS, JSON.stringify(storageObj));
  };

  return <div className='mbXL'>
    <Label isCollapsible collapseText='View Instructions' isLabelOpen={isLabelOpen.state} onToggle={handleToggleClick}>
      <div>
          To <b>Verify Profile</b>, review information on each section, make updates as needed, and check the Verified box on all 6 sections before you Review and Submit.
      </div>
      <div className='mtMed'>
        To <b>Update Profile</b>, navigate and update information in the section that requires a quick update, and proceed to Review.
      </div>
    </Label>
  </div>;
};

export default FormInstructions;

const validator = require('validator');
const { phone } = require('phone');
const moment = require('moment');
const { countries, asciiCodes, emailRegex, mfaPhoneNumberRegex } = require('../constants');

const isEmpty = value => {
  if (!value || value && typeof value === 'string' && value.trim().length === 0) {
    return true;
  } else {
    return false;
  }
};

module.exports = {
  isString: value => {
    if (value && typeof value === 'string' && value.trim().length > 0) {
      return true;
    }
    return false;
  },
  isInt: n => {
    return n % 1 === 0;
  },
  isNumber: value => {
    if (typeof value === 'number') {
      return true;
    }
    return false;
  },
  isPositiveNumber: value => {
    if (typeof value !== 'string') {
      return false;
    }
    return /^\d+$/.test(value);
  },
  isBoolean: value => {
    if (typeof value  === 'boolean' || value === 'true' || value === 'false') {
      return true;
    }
    return false;
  },
  isObject: value => {
    if (typeof value === 'object') {
      return true;
    }
    return false;
  },
  isDate: value => {
    return moment(value).isValid();
  },
  isEmail: value => {
    if (isEmpty(value)) return true;

    return value.match(emailRegex);
  },
  isPhone: value => {
    if (isEmpty(value)) return true;

    return mfaPhoneNumberRegex.test(value);
  },
  isPhoneWithCountry: (phoneValue, countryCode) => {
    if (isEmpty(phoneValue)) return true;
    if (isEmpty(countryCode)) return false;
    if (!phoneValue.startsWith('+')) return false;
    const validPhone = phone(phoneValue, { validateMobilePrefix: false });

    return validPhone.isValid;
  },
  isWebsite: value => {
    if (isEmpty(value)) return true;

    return validator.isURL(value);
  },
  isUSA: value => {
    switch (value) {
      case countries.US:
      case countries.USA:
      case countries.UNITED_STATES:
      case countries.US_OF_A:
        return true;
        break;
      default:
        return false;
    };
  },
  isCanada: value => {
    switch (value) {
      case countries.CA:
      case countries.CAN:
      case countries.CANADA:
        return true;
        break;
      default:
        return false;
    };
  },
  isAus: value => {
    switch (value) {
      case countries.AU:
      case countries.AUS:
      case countries.AUSTRALIA:
        return true;
        break;
      default:
        return false;
    };
  },
  isCusip: cusip => {
    if (!cusip || cusip.length !== 9) {
      return false;
    }

    let sum = 0;
    let charIntValue = 0;
    let c;
    for (let i = 0; i < 8; i++) {
      c = cusip[i].charCodeAt(0);
      if (c >= asciiCodes.NUM_ZERO && c <= asciiCodes.NUM_NINE) {
        charIntValue = c - 48;
      } else if (c >= asciiCodes.CHAR_BIG_A && c <= asciiCodes.CHAR_BIG_Z) {
        charIntValue = c - 55;
      } else if (c === asciiCodes.CHAR_STAR) {
        charIntValue = 36;
      } else if (c === asciiCodes.CHAR_AT) {
        charIntValue = 37;
      } else if (c === asciiCodes.CHAR_POUND) {
        charIntValue = 38;
      } else {
        return false;
      }
      if (i % 2 === 1) {
        charIntValue *= 2;
      }
      sum += (charIntValue % 10) + Math.floor((charIntValue / 10));
    }
    return cusip[8].charCodeAt(0) - 48 === (10 - (sum % 10)) % 10;
  },
  isIsin: isin => {
    if (!isin) {
      return false;
    }

    return validator.isISIN(isin.replace(/-/g, ''));
  }
};

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { contactRoles, entities, errors, fields, personObj } from '../../../constants';
import { contactSection1, contactSection2, existingContactSection } from '../../../constants/forms/designatedContacts';
import { personEntityTitle } from '../../../utils/helper';
import { isDesignatedContactComplete } from '../../../utils/completes';
import { ProfileContext } from '../../../context/Profile.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../Button';
import CompanyAffiliation from '../../CompanyAffiliation';
import Controls from '../../Controls';
import EntityList from '../../EntityList';
import FormSection from '../../FormSection';
import Label from '../../Label';
import PreviousAdded from '../../PreviousAdded';
import RemovePerson from '../../RemovePerson';
import SlidePanel from '../../SlidePanel';
import Title from '../../Title';
import styles from './DesignatedContacts.module.scss';
import CheckBoxField from '../../CheckBoxField';

const PRIMARY_CONTACT_PARAM = fields.PRIMARY_BILLING;
const ADDITIONAL_CONTACT_PARAM = fields.OTHER_BILLING;
const PREV_ADDED_VIEW = 'previousAdded';
const FORM_VIEW = 'formView';
const PRIMARY_BILLING = 'Primary Billing Contact';
const ADDITIONAL_BILLING = 'Additional Billing Contact';
const REMOVE_PERSON_VIEW = 'removePerson';

const BillingContactsTab = ({ onPersonAdd, onPersonUpdate, onPersonRemove }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [contactView, setContactView] = useState(personObj);
  const [slidePanel, setSlidePanel] = useState(false);
  const [viewHistory, setViewHistory] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const peopleList = profileState.profile.peopleList;
  const primaryBillingContact = peopleList.filter(person => person[PRIMARY_CONTACT_PARAM]);
  const additionalBillingList = peopleList.filter(person => person[ADDITIONAL_CONTACT_PARAM]);
  const prevAddedList = peopleList.filter(person => (!person[PRIMARY_CONTACT_PARAM] && !person[ADDITIONAL_CONTACT_PARAM]) && (person.isOfficer || person.isDirector || person.isAuthorizedUser || person.isPrimaryContact));
  const previousAddView = !contactView.id && prevAddedList && prevAddedList.length > 0;
  const contactTotal = peopleList.filter(person => person[PRIMARY_CONTACT_PARAM]).length;
  const [slideView, setSlideView] = useState(previousAddView ? PREV_ADDED_VIEW : FORM_VIEW);
  const isContactViewExisting = contactView && (contactView.isOfficer || contactView.isDirector);
  const isExistingContact = isContactViewExisting;
  const isAdd = !contactView.id || ((contactView && !contactView[PRIMARY_CONTACT_PARAM]) && (contactView && !contactView[ADDITIONAL_CONTACT_PARAM]));
  const isAccountPayable = contactView.isAccountPayable || (contactView.firstName === 'Accounts' && contactView.lastName === 'Payable');
  const hasAccountPayable = peopleList.find(person => !person.isDeleted && (person.isAccountPayable || (person.firstName === 'Accounts' && person.lastName === 'Payable')));
  const isReadOnly = profileState.readOnly;
  const isPrimaryBillingView = slidePanel === PRIMARY_BILLING;
  const isAdditionalBillingView = slidePanel === ADDITIONAL_BILLING;
  const isRemoveView = slideView === REMOVE_PERSON_VIEW;
  const slidePanelTitle = isRemoveView ? `Remove - ${personEntityTitle(contactView, false)}` : isAdd ? `Add ${slidePanel}` : personEntityTitle(contactView, false); 
  let CONTACT_PARAM;
  let CURRENT_VIEW;

  if (isPrimaryBillingView) {
    CONTACT_PARAM = PRIMARY_CONTACT_PARAM;
    CURRENT_VIEW = PRIMARY_BILLING;
  }
  if (isAdditionalBillingView) {
    CONTACT_PARAM = ADDITIONAL_CONTACT_PARAM;
    CURRENT_VIEW = ADDITIONAL_BILLING;
  }

  useEffect(() => {
    if (!slidePanel) {
      setContactView(personObj);
      setSlideView(previousAddView ? PREV_ADDED_VIEW : FORM_VIEW);
      setValidationError(false);
    }
  }, [slidePanel, previousAddView]);

  const onValueChanged = person => {
    setContactView(person);
  };

  const onAffiliationChange = value => {
    setContactView({
      ...contactView,
      title1: value
    });
  };

  const onAccountPayableChanged = e => {
    if (e.value === true) {
      setContactView(prevState => {
        return {
          ...prevState,
          firstName: 'Accounts',
          middleName: null,
          lastName: 'Payable',
          title1: null,
          isAccountPayable: true
        };
      });
    } else {
      setContactView(prevState => {
        return {
          ...prevState,
          firstName: null,
          lastName: null,
          title1: null,
          isAccountPayable: false
        };
      });
    }
  };

  const handleEdit = (person, panelType) => {
    setSlideView(FORM_VIEW);
    setContactView({ ...person });
    setSlidePanel(panelType);
  };

  const handleCancelRemove = _ => {
    setSlidePanel(false);
  };

  const handleRemove = (e, person, param) => {
    e.stopPropagation(); 
    onPersonRemove(person, param);
  };
  
  const handleRemoveCustom = (e, person, panelType) => {
    e.stopPropagation();
    setContactView(person);
    setSlideView(REMOVE_PERSON_VIEW);
    setSlidePanel(panelType);
  };

  const isCustomRemove = person => {
    const totalRoles = contactRoles.filter(role => person[role]);

    return totalRoles.length > 1;
  };

  const onRemoveSubmit = person => {
    onPersonUpdate(person);
    setSlidePanel(false);
  };

  const validateComplete = (person, param) => {
    return isDesignatedContactComplete(person, param);
  };

  const handleCancelView = () => {
    viewHistory === PREV_ADDED_VIEW && prevAddedList && prevAddedList.length > 0 ? setSlideView(PREV_ADDED_VIEW) : setSlidePanel(false);
    setContactView(personObj);
  };

  const handleSubmitPerson = _ => {
    setValidationError(false);

    const contact = contactView;
    const onSubmit = !contact.id ? onPersonAdd : onPersonUpdate;

    contact[CONTACT_PARAM] = true;

    if (!isDesignatedContactComplete(contact, CONTACT_PARAM)) {
      setValidationError(true);
      return;
    }

    onSubmit(contact);
    setSlidePanel(false);
  };

  const handleSelectPrevContact = person => {
    const newContact = person;
    setViewHistory(PREV_ADDED_VIEW);
    setContactView(newContact);
    setSlideView(FORM_VIEW);
  };

  const handleAddNew = () => {
    setViewHistory(PREV_ADDED_VIEW);
    setSlideView(FORM_VIEW);
  };

  const renderPreviouslyAdded = () => (
    <div>
      <p>
        {`Select an individual previously entered, or Add a New ${CURRENT_VIEW}`}.
      </p>
      <PreviousAdded
        listTitle='List of Individuals Previously Entered'
        items={prevAddedList}
        handleSelect={handleSelectPrevContact} />
      <div className='mtMed'>
        <Button
          title={`Add New ${CURRENT_VIEW}` }
          buttonType='secondary' 
          fullWidth
          onClick={handleAddNew} />
      </div>
      <div className={'mtXL'}>
        <Controls
          cancelText='Cancel'
          showSubmit={false}
          onCancelClick={() => setSlidePanel(false)} />
      </div>
    </div>
  );

  const renderAddNew = _ => {
    return <div>
      <Label>
        <FontAwesomeIcon className={styles.icon} icon={['far', 'lock']} />
        <Title className={styles.labelTitle} type='h3' title='Private Information' />
        <div>
          This icon denotes that the information is private and is not publicly displayed.
        </div>
      </Label>
      {!isExistingContact && <>
        <p>
          Enter title and name of {CURRENT_VIEW}.
        </p>
        {(isAdditionalBillingView && !hasAccountPayable && !contactView[fields.PRIMARY_CONTACT] && !contactView[fields.AUTH_USER]) && <CheckBoxField
          className='mtMed mbMed'
          text='This is an Accounts Payable Contact.'
          value={isAccountPayable}
          onValueChanged={onAccountPayableChanged} />}
        <div className='mtMed'>
          {contactSection1.map((section, i) => <FormSection key={i} section={section} data={contactView} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
        </div>
      </>}
      <p>
        Enter phone number and email for {CURRENT_VIEW}.
      </p>
      {!isExistingContact && <div className='mtXL'>
        {contactSection2.map((section, i) => <FormSection key={i} section={section} data={contactView} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
        <div className={styles.innerGrid}>
          <div className={styles.col3}>
            <CompanyAffiliation
              label='Company Affiliation'
              isRequired
              disabled={isAccountPayable}
              readOnly={isReadOnly}
              value={contactView.title1 && contactView.title1}
              setCompanyAffilation={onAffiliationChange} />
          </div>
        </div>
      </div>}
      {isExistingContact && <div className='mtXL'>
        {existingContactSection.map((section, i) => <FormSection key={i} section={section} data={contactView} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
      </div>}
      {validationError && <Label isError title={errors.INCOMPLETE_DATA} />}
      {!isReadOnly && <Controls
        className='mtXXL'
        cancelText='Cancel'
        submitText={isAdd ? 'Add' : 'Update'}
        onCancelClick={handleCancelView}
        onSubmitClick={handleSubmitPerson}
      />}
    </div>;
  };
  
  return (
    <div>
      <p className='mbLg'>
        All billing notices will be sent by email addressed to the Primary Billing Contact with Additional Billing Contact(s) copied.
      </p>
      <div>
        <EntityList
          className='mbXL'
          title={PRIMARY_BILLING}
          list={primaryBillingContact}
          confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
          showIncompleteList={contactTotal < 1}
          incompleteMessage='One Primary Billing Contact is required.'
          icon={'user'}
          iconComplete={'userComplete'}
          entityTitle={person => personEntityTitle(person, false)}
          validateComplete={person => validateComplete(person, PRIMARY_CONTACT_PARAM)}
          addTitle={contactTotal < 1 ? `Add ${PRIMARY_BILLING}` : null}
          readOnly={isReadOnly}
          handleEntityClick={person => handleEdit(person, PRIMARY_BILLING)}
          hasCustomRemove={isCustomRemove}
          handleEntityRemove={!isReadOnly && ((e, person) => handleRemove(e, person, PRIMARY_CONTACT_PARAM))}
          handleCustomRemove={!isReadOnly && ((e, person) => handleRemoveCustom(e, person, PRIMARY_BILLING))}
          handleAddClick={_ => setSlidePanel(PRIMARY_BILLING)}
        />
        <EntityList
          className='mbXL'
          title='Additional Billing Contacts'
          list={additionalBillingList}
          confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
          icon={'user'}
          iconComplete={'userComplete'}
          entityTitle={person => personEntityTitle(person, false)}
          validateComplete={person => validateComplete(person, ADDITIONAL_CONTACT_PARAM)}
          addTitle={`Add ${ADDITIONAL_BILLING}`}
          readOnly={isReadOnly}
          handleEntityClick={person => handleEdit(person, ADDITIONAL_BILLING)}
          hasCustomRemove={isCustomRemove}
          handleEntityRemove={!isReadOnly && ((e, person) => handleRemove(e, person, ADDITIONAL_CONTACT_PARAM))}
          handleCustomRemove={!isReadOnly && ((e, person) => handleRemoveCustom(e, person, ADDITIONAL_BILLING))}
          handleAddClick={() => setSlidePanel(ADDITIONAL_BILLING)}
        />
      </div>
      <SlidePanel 
        isOpen={slidePanel ? true : false}
        onClose={() => setSlidePanel(false)}
        title={slidePanelTitle}>
          {slideView === PREV_ADDED_VIEW && renderPreviouslyAdded()}
          {slideView === FORM_VIEW && renderAddNew()}
          {isRemoveView && <RemovePerson
            person={{ ...contactView }}
            selectedRemove={CONTACT_PARAM}
            onCancel={handleCancelRemove}
            onRemoveSubmit={onRemoveSubmit} />}
      </SlidePanel>
    </div>
  );
};

BillingContactsTab.propTypes = {
  onPersonAdd: PropTypes.func,
  onPersonUpdate: PropTypes.func,
  onPersonRemove: PropTypes.func
};

export default BillingContactsTab;

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ProfileContext } from '../../../context/Profile.context';
import Directors from './Directors';
import Chairpersons from './Chairpersons';

const DirectorsTab = ({ onPersonAdd, onPersonUpdate, onPersonRemove }) => {
  return (
    <div>
      <Chairpersons onPersonAdd={onPersonAdd} onPersonUpdate={onPersonUpdate} onPersonRemove={onPersonRemove} />
      <Directors onPersonAdd={onPersonAdd} onPersonUpdate={onPersonUpdate} onPersonRemove={onPersonRemove} />
    </div>
  );
};

DirectorsTab.propTypes = {
  onPersonAdd: PropTypes.func,
  onPersonRemove: PropTypes.func,
  onPersonUpdate: PropTypes.func
};

export default DirectorsTab;

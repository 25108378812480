import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { AuthContext } from '../../context/Auth.context';
// import { ThemeContext } from '../../context/Theme.context';
import { getCompanyProfile, getInsideData } from '../../api/company';
import Locale from '../../components/Locale';
import styles from './CompanyHeader.module.scss';

const CompanyHeader = ({ className, symbol }) => {
//  const [themeState, themeDispatch] = useContext(ThemeContext);
  // const [authState, authDispatch] = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [inside, setInside] = useState(null);
  const [loaded, setIsLoaded] = useState(false);
  const [noInside, setNoInside] = useState(false);
  const [unlisted, setUnlisted] = useState(false);
  const isPositive = inside?.change > 0;
  const isNegative = inside?.change < 0;

  // START TIER/THEME INFO
  let accentColor = null;
  let tierName = null;
  /*
  useEffect(() => {
    if (accentColor !== null) {
      themeDispatch({
        type: 'SET_ACCENT_COLOR',
        payload: accentColor
      });
    }
  }, [profile, accentColor, themeDispatch]); */

  if (profile) {
    switch (profile.tierGroup) {
      case 'QX':
        tierName = 'OTCQX';
        accentColor = 'green';
        break;

      case 'DQ':
        tierName = 'OTCQB';
        accentColor = 'orange';
        break;

      case 'PS':
        tierName = 'Pink';
        accentColor = 'pink';
        break;

      case 'OO':
        tierName = 'Grey Market';
        accentColor = 'gray';
        break;

      case 'EM':
        tierName = 'OTC Expert Market';
        accentColor = 'gray';
        break;

      default:
        tierName = profile.venue || 'Listed';
        accentColor = 'black';
        break;
    }

    profile.tierName = tierName;
  }

  let tierImage = null;
  let tierImageAltText = null;
  switch (profile?.currentSecurity?.tierId || 0) {
    case 1:
    case 2:
    case 5:
    case 6:
      tierImage = 'QX';
      tierImageAltText = 'OTCQX Best Market Logo';
      break;

    case 10:
      tierImage = 'QB';
      tierImageAltText = 'OTCQB Venture Market Logo';
      break;

    case 20:
      tierImage = 'PS';
      tierImageAltText = 'Pink Open Market Logo';
      break;

    case 21:
      tierImage = 'yield';
      tierImageAltText = 'Limited Information Icon';
      break;

    case 22:
      tierImage = 'stop';
      tierImageAltText = 'No Information Icon';
      break;

    case 30:
      tierImage = 'caution';
      tierImageAltText = 'Grey Market Logo';
      break;

    case 40:
      tierImage = 'expert-tier';
      tierImageAltText = 'Expert Market Icon';
      break;

    default:
      break;
  }
  if (profile && profile.isCaveatEmptor) {
    tierImage = 'caveat-emptor';
    tierImageAltText = 'Caveat Emptor Icon';
  }

  const tierImageUrl = tierImage ? `/logos/tier/${tierImage}.png` : null;

  // Awards
  let awardImageUrl;
  if (profile && profile.tierGroup === 'QX' && profile.otcAward && profile.otcAward.best50) {
    awardImageUrl = '/logos/OTCQX_Best_50.png';
    tierImageAltText = 'OTCQX Best 50 Logo';
  }
  if (profile && profile.tierGroup === 'QX' && profile.otcAward && profile.otcAward.tenYear) {
    awardImageUrl = '/logos/OTCQX_10_Years.png';
    tierImageAltText = 'OTCQX 10 Year Anniversary Logo';
  }

  const showTierDisplayName = profile &&
        profile.currentSecurity &&
        profile.currentSecurity.tierDisplayName &&
        profile.currentSecurity.tierId &&
        profile.currentSecurity.tierId !== 0;

  let basicInfo = null;

  if (profile) {
    // Tier member since
    switch (profile.tierGroup) {
      // OTCQX
      case 'QX':
        basicInfo = `OTCQX Member Since ${moment(profile.tierStartDate).format(
          'MM/YYYY'
        )}`;
        break;

        // OTCQB
      case 'DQ':
        basicInfo = `OTCQB Member Since ${moment(profile.tierStartDate).format(
          'MM/YYYY'
        )}`;
        break;
    }

    // Tier name
    tierName = (showTierDisplayName && profile.currentSecurity.tierDisplayName) || tierName;
  }

  //   END TIER/THEME INFO
  useEffect(() => {
    getCompanyProfile(symbol)
      .then((data) => {
        let currentSecurity = {};
        if (data && data.securities) {
          currentSecurity = data.securities.find(
            (security) => security.symbol.toLowerCase() === symbol.toLowerCase()
          );
        }

        // don't allow invalid phone numbers
        if (data.phone && data.phone.length < 7) data.phone = null;

        // Ensure indices sort order
        if (data.indexStatuses && data.indexStatuses.length > 0) {
          const indexOrder = [
            '.OTCQX',
            '.OTCQXBIL',
            '.OTCQXDIV',
            '.OTCQXBK',
            '.OTCQXINT',
            '.OTCQXCAN',
            '.OTCQXMJ',
            '.OTCQXUS'
          ];
          data.indexStatuses.sort(
            (a, b) =>
              indexOrder.indexOf(a.indexSymbol) -
                            indexOrder.indexOf(b.indexSymbol)
          );
        }

        // set flags for content display, etc.
        const hasQuotes = !(
          data.isCaveatEmptor &&
                    (data.tierGroup === 'OO' ||
                        data.tierGroup === 'EM' ||
                        currentSecurity.tierId === 21 ||
                        currentSecurity.tierId === 22)
        );
        const isOTC =
                    data.tierGroup === 'QX' ||
                    data.tierGroup === 'DQ' ||
                    data.tierGroup === 'PS' ||
                    data.tierGroup === 'EM';
        const isListed = !data.tierGroup;
        const isQxQB = !!(
          data.tierGroup &&
                    (data.tierGroup === 'QX' || data.tierGroup === 'DQ')
        );
        const isGrey = !!(data.tierGroup && data.tierGroup === 'OO');

        // set overview/quote warning
        let warning = [];
        if (data.tierGroup === 'OO') warning.push('grey');
        if (currentSecurity.tierId === 21) warning.push('limited-info');
        if (data.isBankrupt) warning.push('bankrupt');
        if (currentSecurity.tierId === 22) warning.push('no-info');
        if (data.isUnsolicited) warning.push('unsolicited');
        if (data.tierGroup === 'EM') warning.push('expert-tier');
        if (data.isCaveatEmptor && !hasQuotes) warning.push('caveat-emptor');
        /*
        authDispatch({
          type: 'SET_COMPANY_NAME',
          payload: data.name
        }); */

        setProfile({
          ...data,
          currentSecurity,
          warning,
          isOTC,
          isListed,
          hasQuotes,
          isQxQB,
          isGrey
        });
      })
      .catch((err) => {
        console.error(err);
        /*
        authDispatch({
          type: 'SET_INVALID_SYMBOL'
        });

        themeDispatch({
          type: 'SET_DEFAULT_ACCENT_COLOR'
        }); */
      });

    getInsideData(symbol)
      .then((data) => setInside(data))
      .catch((err) => console.error(err));
  }, [symbol]);

  useEffect(() => {
    if (!!profile && !!inside) {
      setNoInside(!profile.hasQuotes || (!inside.bidPrice && !inside.askPrice));
      setUnlisted(!profile.tierGroup);
      setIsLoaded(true);
    }
  }, [profile, inside]);

  return loaded
    ? <div className={`${styles.container} ${className}`}>
      <div className={styles.main}>
        <div className={styles.companyHeader}>
          <h2 className={styles.symbol}>{symbol.toUpperCase()}</h2>
          <div className={styles.companyName}>{profile?.name}</div>
          <div className={styles.subHeader}>
            {profile?.currentSecurity?.className}
            {profile?.currentSecurity?.statusName && <span>&nbsp;| Status:&nbsp;
              <span className={cn({ [styles.suspendedStatus]: (profile.currentSecurity.statusName === 'Suspended' || profile.currentSecurity.statusName === 'Halted' || profile.currentSecurity.statusName === 'Internal Halt') })}>
                {profile.currentSecurity.statusName}
              </span>
            </span>}
          </div>
        </div>
        {profile?.hasQuotes && (
          <div className={styles.companyBid}>
            <div>
              <div
                className={cn(styles.bid, {
                  [styles.positive]: isPositive,
                  [styles.negative]: isNegative,
                  [styles.noChange]: !(
                    inside?.change !== undefined &&
                                        (inside.change > 0 || inside.change < 0)
                  )
                })}
              >
                <h2>
                  <Locale type='price'>{inside?.lastSale}</Locale>
                </h2>
                {isPositive && <FontAwesomeIcon icon={['fas', 'up-long']} className={styles.iconArrow} />}
                {isNegative && <FontAwesomeIcon icon={['fas', 'down-long']} className={styles.iconArrow} />}
                {inside?.change !== undefined && (
                  <div className={styles.evo}>
                    <p>
                      {inside.change !== undefined ? (
                        <Locale type='price'>{inside.change}</Locale>
                      ) : (
                        ''
                      )}
                    </p>
                    <p>
                      {inside.change !== undefined ? (
                        <Locale type='percentage'>
                          {inside.percentChange}
                        </Locale>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                )}
              </div>

              {noInside && !unlisted && (
                <p className={styles.ask}>
                  <strong>No Inside Bid / Offer</strong>
                </p>
              )}
              {!noInside && !unlisted && (
                <p className={styles.ask}>
                  <strong>
                    {inside?.bidPrice ? (
                      <Locale type='price'>{inside.bidPrice}</Locale>
                    ) : (
                      'No Inside'
                    )}{' '}
                                        /{' '}
                    {inside?.askPrice ? (
                      <Locale type='price'>{inside.askPrice}</Locale>
                    ) : (
                      'No Inside'
                    )}
                  </strong>{' '}
                  {inside?.bidSize && inside?.askSize && (
                    <span>
                                            ({inside.bidSize} x {inside.askSize})
                    </span>
                  )}
                </p>
              )}
              <div className={styles.subHeader}>
                {inside?.insideTime && (
                  <div>
                                        Real-Time Best Bid & Ask:{' '}
                    {moment(inside.insideTime)
                      .tz('America/New_York')
                      .format('hh:mma MM/DD/YYYY')}
                  </div>
                )}
                {inside?.quoteDateTime && (
                  <div>
                                        Delayed (15 Min) Trade Data:{' '}
                    {moment(inside.quoteDateTime)
                      .tz('America/New_York')
                      .format('hh:mma MM/DD/YYYY')}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={cn(styles.info, styles.right)}>
        <div key='profile' className={styles.profile}>
          {(tierImageUrl || awardImageUrl || basicInfo) && (
            <div className={styles.tierSection}>
              {(tierImageUrl || awardImageUrl) && (
                <img
                  src={awardImageUrl || tierImageUrl}
                  className={awardImageUrl && styles.award}
                  alt={tierImageAltText}
                />
              )}
              {basicInfo && <div className={styles.infoText}>{basicInfo}</div>}
              {profile.indexStatuses && profile.indexStatuses.length > 0 && (
                <div key='tier' className={styles.tierText}>
                  {tierName}
                </div>
              )}
            </div>
          )}
          {!profile.indexStatuses.length && <div className={styles.infoText}>{tierName}</div>}
        </div>
      </div>
    </div>
    : null;
};

CompanyHeader.propTypes = {
  className: PropTypes.string,
  symbol: PropTypes.string
};

export default CompanyHeader;

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Title from '../Title';
import Controls from '../Controls';
import styles from './DialogBox.module.scss';

const DialogBox = ({ className, title, theme, subTitle, submitColor, children, readOnly, size, cancelText, submitText, onCancelClick, onSubmitClick, isSubmitDisabled, hideCancel, isHorizontal }) => {
  return <div className={cn(className, styles.container, {
    [styles.horizontal]: isHorizontal,
    [styles.light]: theme === 'light',
    [styles.dark]: theme === 'dark'
  })}>
    {title && <Title className={styles.title} title={title} type='h3' />}
    {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
    {children && <div className='mtLg mbLg'>
      {children}
    </div>}
    <Controls
      className={cn({
        [styles.controls]: isHorizontal
      })}
      size={size}
      fullWidth={!isHorizontal}
      cancelText={cancelText}
      submitText={submitText}
      submitColor={submitColor}
      hideCancel={hideCancel}
      showSubmit={!readOnly}
      isSubmitDisabled={isSubmitDisabled}
      onCancelClick={onCancelClick}
      onSubmitClick={onSubmitClick} />
  </div>;
};

DialogBox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  hideCancel: PropTypes.bool,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  submitColor: PropTypes.string,
  onCancelClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isHorizontal: PropTypes.bool,
  readOnly: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark'])
};

DialogBox.defaultProps = {
  theme: 'light'
};

export default DialogBox;

import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ProfileContext } from '../../../context/Profile.context';
import { contactRoles, entities, errors, fields, personObj } from '../../../constants';
import { personEntityTitle } from '../../../utils/helper';
import { isIndividualComplete } from '../../../utils/completes';
import { individualSection } from '../../../constants/forms/people';
import Controls from '../../Controls';
import Entity from '../../Entity';
import EntityList from '../../EntityList';
import FormSection from '../../FormSection';
import Label from '../../Label';
import SlidePanel from '../../SlidePanel';
import Title from '../../Title';

const FORM_VIEW = 'formView';

const IndividualControlPerson = ({ noControlPerson, onControlPersonAdd, onControlPersonUpdate, onControlPersonRemove }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [slidePanel, setSlidePanel] = useState(false);
  const [individualView, setIndividualView] = useState(personObj);
  const [slideView, setSlideView] = useState(FORM_VIEW);
  const [validationError, setValidationError] = useState(false);
  const controlPersons = profileState.profile.controlPersons;
  const individualsList = controlPersons && controlPersons.filter(person => !person.isCorpEntity && !person.isDeleted);
  const addTitle = 'Add Individual (Control Person)';
  const isAdd = !individualView.id || individualView && !individualView.id;
  const isReadOnly = profileState.readOnly;
  const slidePanelTitle = isAdd ? addTitle : personEntityTitle(individualView);  


  useEffect(() => {
    if (!slidePanel) {
      setSlideView(FORM_VIEW);
      setIndividualView(personObj);
    }
  }, [slidePanel]);

  const onValueChanged = person => {
    setIndividualView(person);
  };

  const handleEdit = person => {
    setSlideView(FORM_VIEW);
    setIndividualView({ ...person });
    setSlidePanel(true);
  };

  const handleCancelView = _ => {
    setSlidePanel(false);
  };

  const handleRemove = (e, person) => {
    e.stopPropagation(); 
    onControlPersonRemove(person);
  };

  const isCustomRemove = person => {
    const totalRoles = contactRoles.filter(role => person[role]);

    return totalRoles.length > 1;
  };

  const handleCancelRemove = _ => {
    setSlidePanel(false);
  };

  const handleSubmitPerson = _ => {
    setValidationError(false);

    const individual = individualView;
    const onSubmit = !individualView.id ? onControlPersonAdd : onControlPersonUpdate;

    if (!isIndividualComplete(individual)) {
      setValidationError(true);
      return;
    }

    onSubmit(individual);
    setSlidePanel(false);
  };

  const validateComplete = person => {
    return isIndividualComplete(person);
  };

  const renderAddNew = _ => <div>
    <p>
      Enter Name of Control Person.
    </p>
    <div className='mtXL'>
      {individualSection.map((section, i) => <FormSection key={i} section={section} data={individualView} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
    </div>
    {validationError && <Label isError title={errors.INCOMPLETE_DATA} />}
    {!isReadOnly && <Controls
      className='mtXXL'
      cancelText='Cancel'
      submitText={isAdd ? 'Add' : 'Update'}
      onCancelClick={handleCancelView}
      onSubmitClick={handleSubmitPerson}
    />}
  </div>;

  return (
    <div>
      {!noControlPerson && <EntityList
        className='mbXL'
        title='Individuals'
        list={individualsList}
        confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
        icon={'user'}
        iconComplete={'userComplete'}
        entityTitle={person => personEntityTitle(person, false)}
        validateComplete={validateComplete}
        addTitle='Add Individual (Control Person)'
        readOnly={isReadOnly}
        handleEntityClick={handleEdit}
        hasCustomRemove={isCustomRemove}
        handleEntityRemove={!isReadOnly && handleRemove}
        handleAddClick={() => setSlidePanel(true)}
      />}
      {noControlPerson && <div className='mbLg'>
        <Entity
          title='Not Available'
          icon='userComplete'
          isComplete
          subTitle='Complete' />
      </div>}
      <SlidePanel 
        isOpen={slidePanel}
        onClose={() => setSlidePanel(false)}
        title={slidePanelTitle}>
          {slideView === FORM_VIEW && renderAddNew()}
      </SlidePanel>
    </div>
  );
};

IndividualControlPerson.propTypes = {
  noControlPerson: PropTypes.bool,
  onControlPersonAdd: PropTypes.func,
  onControlPersonUpdate: PropTypes.func,
  onControlPersonRemove: PropTypes.func
};

export default IndividualControlPerson;

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimes as faTimesLight,
  faUser as faUserLight,
  faUserCheck as faUserCheckLight,
  faCircleCheck as faCircleCheckLight,
  faCircleXmark as faCircleXmarkLight,
  faFileCertificate as faFileCertificateLight,
  faUserLock as faUserLockLight,
  faExclamationTriangle as faExclamationTriangleLight,
  faFile as faFileLight,
  faFileCheck as faFileCheckLight,
  faQuestionCircle as faQuestionCircleLight,
  faSave as faSaveLight,
  faLocationDot as faLocationDotLight,
} from '@fortawesome/pro-light-svg-icons';

import {
  faPlus as faPlusReg,
  faTimes as faTimesReg,
  faLongArrowLeft as faLongArrowLeftReg,
  faLongArrowRight as faLongArrowRightReg,
  faPlusCircle as faPlusCircleReg,
  faCircleCheck as faCircleCheckReg,
  faCircleXmark as faCircleXmarkReg,
  faCheck as faCheckReg,
  faCircleInfo as faCircleInfoReg,
  faChevronDown as faChevronDownReg,
  faLock as faLockReg,
  faEnvelope as faEnvelopeReg,
  faPhone as faPhoneReg,
  faCheckCircle as faCheckCircleReg,
  faFilePdf as faFilePdfReg,
  faCloudArrowUp as faCloudArrowUpReg
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCircleCheck as faCircleCheckSolid,
  faCircleXmark as faCircleXmarkSolid,
  faShieldHalved as faShieldHalvedSolid,
  faArrowLeftLong as faArrowLeftLongSolid,
  faUpLong as faUpLongSolid,
  faDownLong as faDownLongSolid,
  faDownload as faDownloadSolid,
  faSearch as faSearchSolid,
  faXmark as faXmarkSolid
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faPlusReg,
  faTimesLight,
  faTimesReg,
  faCheckReg,
  faLongArrowLeftReg,
  faLongArrowRightReg,
  faPlusCircleReg,
  faCircleInfoReg,
  faCircleCheckLight,
  faCircleCheckReg,
  faCircleXmarkLight,
  faCircleXmarkReg,
  faFileLight,
  faFileCheckLight,
  faFileCertificateLight,
  faUserLockLight,
  faLocationDotLight,
  faQuestionCircleLight,
  faSaveLight,
  faExclamationTriangleLight,
  faUserLight,
  faUserCheckLight,
  faChevronDownReg,
  faCircleCheckSolid,
  faCircleXmarkSolid,
  faLockReg,
  faPhoneReg,
  faEnvelopeReg,
  faShieldHalvedSolid,
  faArrowLeftLongSolid,
  faUpLongSolid,
  faDownLongSolid,
  faDownloadSolid,
  faSearchSolid,
  faXmarkSolid,
  faCheckCircleReg,
  faFilePdfReg,
  faCloudArrowUpReg
);

import React, { useEffect, useContext, useState } from 'react';
import { ProfileContext } from '../../context/Profile.context';
import { errors } from '../../constants';
import { createID, isFutureDate } from '../../utils/helper';
import DateBox from '../DateBox';
import Entity from '../Entity';
import Controls from '../Controls';
import SlidePanel from '../SlidePanel';
import Table from '../Table';
import TextBox from '../TextBox';
import styles from './CompanyNameHistory.module.scss';
import Label from '../Label';

const maxDate = new Date();

const columns = [
    {
        header: 'Description',
        field: 'description'
    },
    {
        header: 'Effective Date',
        field: 'date',
        type: 'date'
    }
];

const FROM_NAME = 'fromName';
const TO_NAME = 'toName';
const EFFECTIVE_DATE = 'date';
const PREEXISTING = 'isPreExisting';

const historyObj = {
    [FROM_NAME]: null,
    [TO_NAME]: null,
    [EFFECTIVE_DATE]: null
};

const CompanyNameHistory = _ => {
    const [profileState, dispatchProfile] = useContext(ProfileContext);
    const [slidePanel, setSlidePanel] = useState(false);
    const [history, setHistory] = useState(historyObj);
    const [validationError, setValidationError] = useState(null);
    const profileData = profileState.profile;
    const isReadOnly = profileState.readOnly;
    const companyNameHistoryList = profileData.companyInfo.companyNameHistoryList && profileData.companyInfo.companyNameHistoryList.map(item => {
        return {
            ...item,
            description: `Company name changed from ${item[FROM_NAME]} to ${item[TO_NAME]}`
        };
    });

    useEffect(() => {
        if (!slidePanel) {
            setHistory(historyObj);
            setValidationError(false);
        }
    }, [slidePanel]);

    const slideClose = _ => {
        setSlidePanel(false);
    };

    const onValueChanged = (value, field) => {
        setHistory({ ...history, [field]: value });
    };

    const handleRowClick = item => {
        setHistory(item);
        setSlidePanel(true);
    };

    const handleAdd = _ => {
        setValidationError(false);

        if (!validateForm()) return;
        const updatedObj = { ...profileData.companyInfo };

        const id = createID(updatedObj.companyNameHistoryList);
        history.id = id;

        if (!updatedObj.companyNameHistoryList) updatedObj.companyNameHistoryList = [];
        
        updatedObj.companyNameHistoryList.push({
            ...history,
            description: `Company name changed from ${history[FROM_NAME]} to ${history[TO_NAME]}`
        });

        dispatchProfile({
            type: 'UPDATE_PROFILE',
            field: 'companyInfo',
            payload: updatedObj
        });

        slideClose();
    };

    const handleUpdate = _ => {
        setValidationError(false);
        if (!validateForm()) return;

        const updatedObj = { ...profileData.companyInfo };

        updatedObj.companyNameHistoryList = updatedObj.companyNameHistoryList.map(item => {
        if (item.id === history.id) {
            history.description = `Company name changed from ${history[FROM_NAME]} to ${history[TO_NAME]}`;
            return history;
        }

        return item;
        });

        dispatchProfile({
            type: 'UPDATE_PROFILE',
            field: 'companyInfo',
            payload: updatedObj
        });

        slideClose();
    };

    const validateForm = _ => {
        if (!history[FROM_NAME] || !history[TO_NAME] || !history[EFFECTIVE_DATE] || isFutureDate(history[EFFECTIVE_DATE])) {
            setValidationError(true);
            return false;
        }

        return true;
    };

    const handleRemoveRow = (e, item) => {
        e.stopPropagation(); 

        const updatedObj = { ...profileData.companyInfo };
        updatedObj.companyNameHistoryList = updatedObj.companyNameHistoryList.filter(obj => obj.id !== item.id);

        dispatchProfile({
            type: 'UPDATE_PROFILE',
            field: 'companyInfo',
            payload: updatedObj
        });
    };

    return <>
        <Table columns={columns} data={companyNameHistoryList} onRemoveRow={!isReadOnly && handleRemoveRow} onRowClick={!isReadOnly && handleRowClick} allowRowClickField={item => !item[PREEXISTING]} allowRemoveField={item => !item[PREEXISTING]} />
        {!isReadOnly && <Entity className='mtSm' isAdd title='Add Company Name History' size='small' onClick={() => setSlidePanel(true)} />}
        <SlidePanel 
            isOpen={slidePanel}
            onClose={slideClose}
            title='Company Name History'>
                <>
                    <div>
                        <p>Company name changed from</p>
                        <TextBox
                            label='Previous Name'
                            placeholder='Enter Previous Name'
                            showClearButton
                            maxLength={75}
                            isRequired
                            value={history[FROM_NAME]}
                            onValueChanged={e => onValueChanged(e.value, FROM_NAME)}
                        />
                    </div>
                    <div>
                        <p>to</p>
                        <TextBox
                            label='New Name'
                            placeholder='Enter New Name'
                            showClearButton
                            maxLength={75}
                            isRequired
                            value={history[TO_NAME]}
                            onValueChanged={e => onValueChanged(e.value, TO_NAME)}
                        />
                    </div>
                    <div className={styles.dateBox}>
                        <DateBox
                            label='Effective Date'
                            placeholder={'MM/DD/YYYY'}
                            showClearButton
                            type='date'
                            max={maxDate}
                            isRequired
                            errorText='Incomplete Data. Please enter date of historical company name change.'
                            value={history[EFFECTIVE_DATE]}
                            onValueChanged={e => onValueChanged(e.value, EFFECTIVE_DATE)} 
                        />
                    </div>
                    {validationError && <Label className='mtLg' title={errors.INCOMPLETE_DATA} isError />}
                    <Controls
                        className='mtXXL'
                        cancelText='Cancel'
                        submitText={history.id ? 'Update' : 'Add'}
                        onCancelClick={slideClose}
                        onSubmitClick={history.id ? handleUpdate : handleAdd}
                    />
                </>
        </SlidePanel>
    </>;
};

CompanyNameHistory.propTypes = {

};

export default CompanyNameHistory;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { entities } from '../../constants';
import Title from '../Title';
import Button from '../Button';
import Entity from '../Entity';
import styles from './PreviousAdded.module.scss';

const PreviousAdded = ({ listTitle, items, max, handleSelect }) => {
  const [renderList, setRenderList] = useState(items && items.slice(0, max));
  const showMore = items.length > max && renderList.length <= max;

  return <>
    <div className='mtXL'>
      <Title title={listTitle} type='h3' />
    </div>
    <div className={styles.list}>
      {renderList.map((item, i) => {
        const title = (!item.firstName && !item.lastName ) ? entities.NO_NAME : `${item.firstName} ${item.lastName}`;
        return (
          <Entity
            key={i}
            title={title}
            subTitle='Select'
            size='small'
            onClick={() => handleSelect(item)} />
        );
      })}
    </div>
    {showMore && <div className='mtMed'>
      <Button
        title='Show More Individuals...' 
        buttonType='secondary'
        color='gray'
        fullWidth
        onClick={() => setRenderList(items)} />
    </div>}
  </>;
};

PreviousAdded.propTypes = {
  listTitle: PropTypes.string,
  items: PropTypes.array,
  max: PropTypes.number,
  handleSelect: PropTypes.func
};

PreviousAdded.defaultProps = {
  max: 10
};

export default PreviousAdded;

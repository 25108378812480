import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getCodes } from '../../utils/locale';
import Field from './Field';
import Item from './Item';
import TextBoxWithoutTrim from '../TextBox/TextBoxWithoutTrim';
import SelectBox from '../SelectBox';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { errors } from '../../constants';
import { isPhoneWithCountry } from '../../utils/validations';
import styles from './DynamicPhoneInput.module.scss';

const rules = { 'X': /[02-9]/ };
const phoneNumberMaxLength = 19;

const DynamicPhoneInput = ({ label, labelIcon, phoneValue, readOnly, phoneNumberField, countryCodeValue, countryCodeField, disabled, onValueChanged, isRequired }) => {
  const [isFocus, setFocus] = useState(false);
  const localeCodes = getCodes();
  const countries = localeCodes && localeCodes.countries ? localeCodes.countries : [];
  const countryPhoneCodeMap = (countries && countries.length > 0) ? new Map(countries.map(obj => [obj.id, obj.phoneCode])) : [];
  const isPhoneEmpty = (!phoneValue || (phoneValue && phoneValue.trim().length === 0)) ? true : false;
  const didCountryCodeMount = useRef(false);
  const didPhoneMount = useRef(false);
  const isPhoneCountryValid = countryCodeValue ? true : false;
  const showRequired = !disabled && isRequired ? true : false;
  let errorText;
  let isPhoneValid = true;

  if (showRequired && isPhoneEmpty && !isPhoneCountryValid) {
    errorText = errors.INCOMPLETE_DATA_FIELD;
  } else if (!isPhoneCountryValid) {
    errorText = 'Select Country';
  } else if (!isPhoneWithCountry(phoneValue, countryCodeValue)) {
    isPhoneValid = false;
    errorText = 'Invalid Phone Format. Format must be Country Code Phone Number. e.g. +1 2128964420';
  }

  useEffect(() => {
    if (!didCountryCodeMount.current) {
      didCountryCodeMount.current = true;
    } else {
      const newCountryPhoneCode = countryPhoneCodeMap.get(countryCodeValue);
      const phoneValueStr = (phoneValue && phoneValue.trim()) || '';
      let newPhoneNumber = phoneValueStr;
      if (newCountryPhoneCode && newCountryPhoneCode !== '') {
        let primaryPhoneParts = phoneValueStr.split(' ');

        // if space is present, replace first part with new code
        if (primaryPhoneParts && primaryPhoneParts.length > 1 && primaryPhoneParts[0].startsWith('+')) {
          newPhoneNumber = '+' + newCountryPhoneCode + ' ' + phoneValueStr.substr(phoneValueStr.indexOf(' ') + 1).trim();
        } else {
          newPhoneNumber = '+' + newCountryPhoneCode + ' ' + phoneValueStr.trim();
        }
        newPhoneNumber = newPhoneNumber.substring(0, phoneNumberMaxLength);
      }

      onValueChanged(newPhoneNumber, phoneNumberField);
    }
  }, [countryCodeValue]);

  useEffect(() => {
    if (!didPhoneMount.current) {
      didPhoneMount.current = true;
    } else {
      let newCountryCode = null;

      // get first part of which is limited by space
      let newPhoneCode = phoneValue && phoneValue.substr(0, phoneValue.indexOf(' '));
      if (newPhoneCode === '') {
        newPhoneCode = phoneValue;
      }

      newPhoneCode = newPhoneCode && newPhoneCode.replace('+', '');
      for (let [key, value] of countryPhoneCodeMap.entries()) {
        if (value === newPhoneCode) {
          newCountryCode = newCountryCode || key;
          if (key === countryCodeValue) {
            newCountryCode = countryCodeValue;
            break;
          }
        };
      }

      onValueChanged(newCountryCode, countryCodeField);
    }
  }, [phoneValue]);

  return (
    <div>
      <div>
        <FieldLabel text={label} icon={labelIcon} />
        {(!readOnly && showRequired && (!isPhoneValid || !isPhoneCountryValid)) && <FieldError isFocus={isFocus} error={errorText} />}
      </div>
      <div className={cn(styles.container, 'phone-select', 'hasCountryCode')}>
        <SelectBox id='custom-templates-country-code'
          items={countries}
          displayExpr='name'
          valueExpr='id'
          width='50px'
          disabled={readOnly || disabled}
          dropDownOptions={{
            'minWidth': '300px'
          }}
          fieldRender={Field}
          itemRender={Item}
          isRequired={showRequired}
          value={countryCodeValue}
          hideErrorIcon
          isValid={showRequired ? isPhoneCountryValid : true}
          onValueChanged={e => e.event && onValueChanged(e.value, countryCodeField)} />

        <div className={styles.input}>
          <TextBoxWithoutTrim
            placeholder={disabled ? 'N/A' : 'Phone Number'}
            maskRules={rules}
            value={phoneValue}
            disabled={readOnly || disabled}
            isRequired={showRequired}
            showClearButton
            isIncomplete={!isPhoneValid}
            onFocusIn={() => setFocus(true)}
            onFocusOut={() => setFocus(false)}
            onValueChanged={e => e.event && onValueChanged(e.value, phoneNumberField)} />
        </div>
      </div>
    </div>
  );
};

DynamicPhoneInput.defaultProps = {
  countryCodeField: 'phoneCountryCode',
  phoneNumberField: 'phoneNumber'
};

DynamicPhoneInput.propTypes = {
  phoneValue: PropTypes.any,
  countryCodeValue: PropTypes.any,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  countryCodeField: PropTypes.string,
  phoneNumberField: PropTypes.string,
  onValueChanged: PropTypes.func,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelIcon: PropTypes.array
};

export default DynamicPhoneInput;

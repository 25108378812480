import { errors, fieldTypes } from '../index';
import { getCodes } from '../../utils/locale';
import { isRequiredCountry, isRequiredContactWebsite } from '../../utils/completes';
import { isEmail, isWebsite, isPhone } from '../../utils/validations';
const localeCodes = getCodes();
const countryList = localeCodes && localeCodes.countries ? localeCodes.countries : [];
const stateList = localeCodes && localeCodes.states ? localeCodes.states : [];

export const formSections = [
    {
        title: 'Company Contact Information',
        fields: [
            {
                type: fieldTypes.TEXTBOX_PHONE,
                label: 'Phone Number',
                key: 'contactInfo.basicContactInfo.phone',
                isValid: data => isPhone(data.contactInfo.basicContactInfo.phone),
                invalidText: errors.INVALID_PHONE,
                isRequired: true,
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Email',
                key: 'contactInfo.basicContactInfo.email',
                isValid: data => isEmail(data.contactInfo.basicContactInfo.email),
                invalidText: errors.INVALID_EMAIL,
                isRequired: true,
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Website',
                key: 'contactInfo.basicContactInfo.website',
                isValid: data => isWebsite(data.contactInfo.basicContactInfo.website),
                invalidText: errors.INVALID_WEBSITE,
                isRequired: data=> isRequiredContactWebsite(data),
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'LinkedIn',
                key: 'contactInfo.socialMedia.linkedInId',
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Instagram',
                key: 'contactInfo.socialMedia.instagramId',
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'X/Twitter',
                key: 'contactInfo.socialMedia.twitterId',
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Facebook',
                key: 'contactInfo.socialMedia.facebookId',
                colSpan: 2
            }
        ]
    }
];

export const addressForm = [
    {
        fields: [
            {
                type: fieldTypes.TEXTBOX,
                label: 'Address',
                key: 'address1',
                placeholder: 'Address 1',
                isRequired: true,
                colSpan: 6
            },
            {
                type: fieldTypes.TEXTBOX,
                key: 'address2',
                placeholder: 'Address 2',
                colSpan: 6
            },
            {
                type: fieldTypes.TEXTBOX,
                key: 'address3',
                placeholder: 'Address 3',
                colSpan: 6
            },
            {
                type: fieldTypes.SELECT,
                label: 'Country',
                key: 'countryId',
                valueExpr: 'id',
                displayExpr: 'name',
                options: countryList,
                isRequired: true,
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'City',
                key: 'city',
                placeholder: 'City',
                isRequired: true,
                colSpan: 2
            },
            {
                type: fieldTypes.SELECT,
                label: 'State',
                key: 'state',
                placeholder: 'State',
                valueExpr: 'id',
                displayExpr: 'name',
                options: data => {
                    const country = countryList.find(c => c.id === data.countryId);
                    if (!country) return [];
                    return stateList.filter(option => option.countryId === country.id);
                },
                isRequired: data => isRequiredCountry(data.countryId),
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Postal Code',
                key: 'zip',
                placeholder: 'Postal Code',
                isRequired: true,
                colSpan: 2
            }
        ]
    }
];

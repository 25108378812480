import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { reviewColumns, routes } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from '@otcmarketsgroup/otcm-ui';
import Title from '../Title';
import styles from './ReviewSection.module.scss';

const ReviewSection = ({ identifier, data, isVerified, name, symbol, path }) => {
  const sectionData = data && data[identifier] ? data[identifier] : [];
  const addNewColumns = reviewColumns.filter(col => col.name !== 'oldValue');

  return <div key={identifier} className={styles.container}>
    <div className={styles.header}>
      <div className={styles.title}>
        {isVerified && <FontAwesomeIcon className={styles.icon} icon={['far', 'circle-check']} />}
        {!isVerified && <FontAwesomeIcon className={cn(styles.icon, styles.error)} icon={['far', 'circle-xmark']} />}
        <Title type='h2' title={name} />
      </div>
      <Link className={styles.link} to={`${routes.PROFILE_PREFIX}/${symbol}/${path}`}>
        View/Edit
      </Link>
    </div>
    <div>
      {sectionData && sectionData.length > 0 && sectionData.map((section, i) => <div key={`${section.header}-${i}`} className={styles.section}>
        <div className={styles.sectionHeader}>
          {section.isNew && <FontAwesomeIcon className={styles.addIcon} icon={['far', 'plus']} />}
          {section.isDeleted && <FontAwesomeIcon className={styles.deleteIcon} icon={['far', 'times']} />}
          {section.header}
        </div>
        {(section.fields && section.fields.length > 0) && <Table columns={section.isNew ? addNewColumns : reviewColumns} data={section.fields} size='small' />}
      </div>)}
      {!sectionData || sectionData.length === 0 && <div className={styles.noChange}>No changes were made</div>}
    </div>
  </div>;
};

ReviewSection.propTypes = {
  identifier: PropTypes.string,
  isVerified: PropTypes.bool,
  name: PropTypes.string,
  path: PropTypes.string,
  symbol: PropTypes.string,
  data: PropTypes.object
};

export default ReviewSection;

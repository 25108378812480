/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import equal from 'fast-deep-equal/es6';
import { routes, errors, storage } from '../../constants';
import { sections } from '../../constants/sections';
import { getApplication, saveCuf, submitCuf } from '../../api/cuf';
import { getBadgeInfo } from '../../api/company';
import { NotificationsContext } from '../../context/Notifications.context';
import { ThemeContext } from '../../context/Theme.context';
import { ProfileContext } from '../../context/Profile.context';
import { withRouter } from '../../components/WithRouter';
import ProfileNav from '../../components/ProfileNav';
import ProfileHeader from '../../components/ProfileHeader';
import GlobalActions from '../../components/GlobalActions';
import UpdateProfileSubmitted from '../../components/UpdateProfileSubmitted';
import VerifiedProfileSubmitted from '../../components/VerifiedProfileSubmitted';
import styles from './FormPage.module.scss';

const loggedInUser = JSON.parse(window.sessionStorage.getItem(storage.AP_USER));

const FormPage = ({ navigate, params: { formSection, symbol } }) => {
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const theme = useContext(ThemeContext);
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState(null);
  const profile = profileState.profile;
  const backupProfile = profileState.backupProfile;
  const isVerifiedSubmitSuccess = isSubmitSuccess === 'verify';
  const isUpdateSubmitSuccess = isSubmitSuccess === 'update';

  useEffect(() => {
    const params = {
      symbol
    };

    getApplication(params)
      .then(data => {
        dispatchProfile({
          type: 'SET_PROFILE',
          payload: { ...data }
        });
        getBadgeInfo(symbol)
          .then(badgeInfo => {
            dispatchProfile({
              type: 'SET_VERIFIED_PROFILE',
              verifiedProfile: badgeInfo ? badgeInfo.verifiedProfile : false,
              verifiedDate: badgeInfo ? badgeInfo.verifiedDate : null
            });
          })
          .catch(err => {
            console.error('Could not retrieve company verify status');

            dispatchProfile({
              type: 'SET_PROFILE',
              payload: data
            });
          });
      })
      .catch(err => setError(errors.FAILED_PROFILE));
  }, [symbol, dispatchProfile]);

  const checkAppChanges = _ => {
    if (profile && !equal(backupProfile, profile)) {
      saveApplication();
    }
  };

  const saveApplication = async callback => {
    const data = {
      cufId: profile.openCufId,
      email: loggedInUser.email,
      formData: profile
    };

    try {
      const response = await saveCuf(data);

      dispatchProfile({
        type: 'SET_PROFILE',
        payload: response.formData
      });

      displaySaveNotification();

      callback && callback(true, null);
    } catch (err) {
      callback && callback(false, null);

      displayFailNotification();
    };
    // saveCuf(data).then(res => {
    //   dispatchProfile({
    //     type: 'SET_PROFILE',
    //     payload: res.formData
    //   });

    //   displaySaveNotification();
    // }).catch(_ => displayFailNotification());
  };

  useEffect(() => {
    checkAppChanges();

    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }, [formSection]);

  const displaySaveNotification = _ => {
    const id = new Date().getTime();

    dispatchNotification({
      type: 'ADD_NOTIFICATION',
      payload: {
        id: id,
        description: `Your profile has been saved. ${moment().format('MMMM D, YYYY')}`,
        type: 'save'
      }
    });

    setTimeout(() => {
      dispatchNotification({
        type: 'REMOVE_NOTIFICATION',
        id: id
      });
    }, 3000);
  };

  const displayFailNotification = _ => {
    const id = new Date().getTime();

    dispatchNotification({
      type: 'FAILED_SAVE_APPLICATION',
      payload: {
        id: id,
        description: `Your application failed to be saved. ${moment().format('MMMM D, YYYY')}`,
        type: 'error'
      }
    });
  };
  
  const handleSubmit = (submitType, appDiff) => {
    if (profile && !equal(backupProfile, profile)) {
      saveApplication((data, error) => {
        if (data) {
          console.log('API call successful:', data);
          // Handle the data as needed
          postSubmit(submitType, appDiff);
        } else if (error) {
          console.error('API call error:', error);
          // Handle the error as needed
        }
      });
    } else postSubmit(submitType, appDiff);
  };

  const postSubmit = (submitType, appDiff) => {
    const data = {
      cufId: profile.openCufId,
      submittedBy: {
        email: loggedInUser.email,
        firstName: loggedInUser.firstName,
        lastName: loggedInUser.lastName
      },
      appDiff
    };

    theme.dispatch({ type: 'SET_LOCK' });

    submitCuf(data).then(_ => {
      theme.dispatch({ type: 'SET_UNLOCK' });
      setSubmitSuccess(submitType);
    })
    .catch(err => {
      const id = new Date().getTime();
      theme.dispatch({ type: 'SET_UNLOCK' });

      dispatchNotification({
        type: 'FAILED_SUBMIT_APPLICATION',
        payload: {
          id: id,
          description: `Your application failed to submit. ${moment().format('MMMM D, YYYY')}`,
          type: 'error'
        }
      });

      console.error(err);
    });
  };

  const getAppSection = () => {
    if (!formSection) navigate(`${routes.PROFILE_PREFIX}/${symbol}/${routes.COMPANY_INFO}`);
    const section = sections && sections.find(section => section.path === formSection);
    const Component = section ? section.component : sections[0].component;

    return <Component saveApplication={saveApplication} handleSubmit={handleSubmit} />; 
  };

  return (
    <>
      {!isSubmitSuccess && profileState.isLoaded && <div className={styles.container}>
        <ProfileNav />
        <main>
          <ProfileHeader company={profile.companyInfo.companyName} isVerified={profileState.verifiedProfile} verifiedDate={profileState.verifiedDate} />
          {getAppSection()}
          {error && <p className={styles.error}>{error}</p>}
        </main>
        <div className={styles.rightRail}>
          <GlobalActions user={loggedInUser} onSaveClick={saveApplication} symbol={symbol} />
        </div>
      </div>}
      {isVerifiedSubmitSuccess && <VerifiedProfileSubmitted />}
      {isUpdateSubmitSuccess && <UpdateProfileSubmitted />}
    </>
  );
};

FormPage.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string,
    formSection: PropTypes.string
  }),
  navigate: PropTypes.func
};

export default withRouter(FormPage);

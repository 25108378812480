import React, { useState } from 'react';
import { emails, urls } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { errors } from '../../constants';
import { helpCuf } from '../../api/cuf';
import { withRouter } from '../WithRouter';
import Button from '../Button';
import DialogBox from '../DialogBox';
import Label from '../Label';
import Title from '../Title';
import styles from './HelpSection.module.scss';
import TextArea from '../TextArea';

const HelpSection = ({ params: { symbol }, companyName, userEmail, userFullName }) => {
  const [showReportProblem, setReportProblem] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [problemText, setProblemText] = useState('');

  const handleReportProblemClick = _ => {
    setSubmitSuccess(false);
    setReportProblem(true);
  };

  const handleSendReportProblem = _ => {
    const body = {
      senderEmail: userEmail,
      fullName: userFullName,
      companyName: companyName,
      symbol: symbol,
      textBox: problemText
    };

    setIsSending(true);

    helpCuf(body)
      .then(_ => {
        setIsSending(false);
        setSubmitSuccess(true);
        setReportProblem(false);
        setProblemText('');
      })
      .catch(e => {
        console.error(e);
        setIsSending(false);
        setSubmitError(errors.FAILED_REPORT_PROBLEM);
      });
  };

  const onOptionChanged = e => {
    if (e.name === 'text') {
      const value = e.value;
      setProblemText(value);
    }
  };

  return <section>
    <Title title='Saving Progress' type='h2' />
    <p>
      You can save your work at any time and resume later by clicking on the Save button on the top right corner of your screen. Your progress is also automatically saved as you move between different sections of the application form.
    </p>
    <Title className='mtXL' title='Helpful Links' type='h2' />
    <ul className={styles.links}>
      <li>
        <a href={urls.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer'>
          Privacy Policy
        </a>
      </li>
      <li>
        <a href={urls.STOCK_PROMO_POLICY} target='_blank' rel='noopener noreferrer'>
          Stock Promotion Policy
        </a>
      </li>
      <li>
        <a href={urls.BEST_PRACTICE_ISSUERS} target='_blank' rel='noopener noreferrer'>
          Best Practices for Issuers
        </a>
      </li>
      <li>
        <a href={urls.GLOSSARY} target='_blank' rel='noopener noreferrer'>
          Glossary of Terms
        </a>
      </li>
      <li>
        <a href={urls.FAQS} target='_blank' rel='noopener noreferrer'>
          FAQs
        </a>
      </li>
    </ul>
    <div className='mtLg'>
      <Button title='Report a Problem' onClick={handleReportProblemClick} />
      {submitSuccess && <div className={styles.success}>
          <i>Problem has been successfully submitted.</i>
        </div>}
      {showReportProblem && <DialogBox
            title='Report a Problem'
            subTitle='Provide a quick summary of the problem you are experiencing while completing your Company Profile and an analyst from Issuer Services will be in touch. Please make sure to leave your preferred contact details below.'
            onCancelClick={() => setReportProblem(false)}
            onSubmitClick={handleSendReportProblem}
            submitText='Submit'
            isSubmitDisabled={(!problemText || problemText.trim().length === 0) || isSending}>
              <TextArea value={problemText} onOptionChanged={onOptionChanged} />
              <p>
                To reach Issuer Services immediately, please refer to the contact details below.
              </p>
              {submitError && <Label isError title={submitError} />}
        </DialogBox>}
    </div>
    <Title className='mtXL' title='Contact Issuer Services' type='h2' />
    <Title className='mtLg' title='Phone:' type='h3' />
    <div className={styles.phone}>
      <FontAwesomeIcon icon={['far', 'phone']} /> (212)-896-4420
    </div>
    <Title className='mtLg' title='Email:' type='h3' />
    <a href={emails.ISSUER_SERVICES}>
      <FontAwesomeIcon icon={['far', 'envelope']} /> issuers@otcmarkets.com
    </a>
  </section>;
};

HelpSection.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string
  }),
  companyName: PropTypes.string,
  userEmail: PropTypes.string,
  userFullName: PropTypes.string
};

export default withRouter(HelpSection);

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ProfileHeader.module.scss';

const ProfileHeader = ({ isVerified, verifiedDate, company, inline }) => {
  return <div className={cn(styles.container, { [styles.inline]: inline})}>
    <div>
      Company Profile | {company}
    </div>
    <div className={styles.profileStatus}>
      {isVerified && <span className={styles.verified}><FontAwesomeIcon className={styles.checkIcon} icon={['fa', 'circle-check']} /> Verified on {moment(verifiedDate).format('MM/DD/YYYY')}</span>}
      {((typeof isVerified !== 'null' && typeof isVerified !== 'undefined' && typeof isVerified === 'boolean') && !isVerified) && <span className={styles.notVerified}><FontAwesomeIcon className={styles.xmarkIcon} icon={['fa', 'circle-xmark']} /> Profile not verified</span>}
    </div>
  </div>;
};

ProfileHeader.propTypes = {
  isVerified: PropTypes.bool,
  inline: PropTypes.bool,
  verifiedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  company: PropTypes.string
};

export default ProfileHeader;

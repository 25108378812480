import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ProfileContext } from '../../context/Profile.context';
import Label from '../Label';
import CheckBoxField from '../CheckBoxField';

const VerifyBox = ({ section }) => {
    const [profileState, dispatchProfile] = useContext(ProfileContext);
    const profileData = profileState.profile;
    const verifiedSections = profileData.verifiedSections;
    const enableSection = profileState.enableVerifiedSections;
    const disabled = !enableSection[section];

    const onValueChanged = e => {
        const updatedObj = { ...verifiedSections, [section]: e.value };

        dispatchProfile({
            type: 'UPDATE_PROFILE',
            field: 'verifiedSections',
            payload: updatedObj
        });
    };

    return <Label className='mbLg'>
        <CheckBoxField
            text='I have verified all information on this page.'
            value={verifiedSections[section]}
            disabled={disabled}
            onValueChanged={onValueChanged} />
    </Label>;
};

VerifyBox.propTypes = {
    section: PropTypes.string
};

export default VerifyBox;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { SelectBox as SelectBoxDevEx } from 'devextreme-react';
import { errors } from '../../constants';
import DataSource from 'devextreme/data/data_source';

const SelectBox = ({...props}) => {
  const [isFocus, setFocus] = useState(false);
  const isLabel = props.label || !props.isValid;
  const showRequired = props.isRequired && (!props.value || (typeof props.value === 'string' && props.value.trim().length === 0));

  const dataSource = new DataSource({
    store: props.items,
    key: props.valueExpr,
    group: props.group
  });

  const handleValueChange = e => {
    const event = e;
    e.value && props.onValueChanged && props.onValueChanged(event);
  };

  const handleFocusIn = e => {
    e.event.target.select();
    setFocus(true);
  };

  const handleFocusOut = e => {
    setFocus(false);
  };

  const customItemCreating = e => {
    // Generates a new 'id'
    let nextId;
    dataSource.store().totalCount().done(count => { nextId = count + 1; });
    // Creates a new entry
    e.customItem = props.displayExpr ? { id: nextId, [props.displayExpr]: e.text } : e.text;
    // Adds the entry to the data source
    dataSource.store().insert(e.customItem);
    // Reloads the data source
    dataSource.reload();
  };

  return <>
    {isLabel && <div>
      {props.label && <FieldLabel text={props.label} icon={props.labelIcon} moreInfo={props.moreInfo} />}
      {!props.hideErrorIcon && !props.readOnly && (!props.isValid || showRequired) && <FieldError isFocus={isFocus} error={props.errorText || errors.INCOMPLETE_DATA_FIELD} />}
    </div>}
    <SelectBoxDevEx
      {...props}
      labelMode='hidden'
      placeholder={props.disabled ? 'N/A' : 'Select'}
      dataSource={dataSource}
      onFocusIn={handleFocusIn}
      onFocusOut={handleFocusOut}
      searchEnabled={props.searchEnabled}
      onCustomItemCreating={props.acceptCustomValue && customItemCreating}
      searchMode='startswith'
      value={props.value ? props.value : undefined}
      isValid={(showRequired && !props.readOnly) ? props.value && props.value.trim().length > 0 : props.isValid}
      onValueChanged={handleValueChange}
      inputAttr={{
        autocomplete: 'no-thanks'
      }}
      showClearButton={props.showClearButton && isFocus} />
  </>;
};

SelectBox.propTypes = {
  label: PropTypes.string,
  labelIcon: PropTypes.array,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showClearButton: PropTypes.bool,
  group: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorText: PropTypes.string,
  items: PropTypes.array,
  onFocusIn: PropTypes.func,
  onFocusOut: PropTypes.func,
  onValueChanged: PropTypes.func,
  hideErrorIcon: PropTypes.bool,
  valueExpr: PropTypes.string,
  displayExpr: PropTypes.string,
  moreInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  searchEnabled: PropTypes.bool,
  acceptCustomValue: PropTypes.bool
};

SelectBox.defaultProps = {
  isValid: true,
  searchEnabled: true
};

export default SelectBox;
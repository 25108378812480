import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TabButton.module.scss';

const TabButton = props => {
  return (
    <div id={props.id} className={cn(styles.container, {
      [styles.active]: props.active
    })} onClick={props.onClick}>
      <div>
        {props.text}
      </div>
      {props.error && <div className={styles.errorContainer}>
        <FontAwesomeIcon
          id={`${props.id}-Error`}
          icon={['fal', 'exclamation-triangle']} />
      </div>}
    </div>
  );
};

TabButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  active: PropTypes.bool,
  error: PropTypes.bool
};

TabButton.defaultProps = {
  error: false,
};

export default TabButton;

import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import equal from 'fast-deep-equal/es6';
import PropTypes from 'prop-types';
import { ProfileContext } from '../../../context/Profile.context';
import { editControls, routes, verifiedSectionKeys } from '../../../constants';
import { withRouter } from '../../WithRouter';
import { formSections } from '../../../constants/forms/companyInformation';
import FormInstructions from '../../FormInstructions';
import FormSection from '../../FormSection';
import VerifyBox from '../../VerifyBox';
import Button from '../../Button';
import ApplicationDescription from '../../ApplicationDescription/ApplicationDescription';
import styles from './CompanyInformation.module.scss';

const CompanyInformation = ({ params: { symbol }, saveApplication }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [isEditView, setEditView] = useState(true);
  const profileData = profileState.profile;
  const backupProfile = profileState.backupProfile;
  const isReadOnly = profileState.readOnly;

  useEffect(() => {
    const isIncorporationChange = !equal({ ...backupProfile.companyInfo.incorporationInfo }, { ...profileData.companyInfo.incorporationInfo });
    const isCompNameChange = !equal({ ...backupProfile.companyInfo.companyNameHistoryList }, { ...profileData.companyInfo.companyNameHistoryList });

    if (isIncorporationChange || isCompNameChange) {
      saveApplication();
    }
  }, [backupProfile.companyInfo.companyNameHistoryList, backupProfile.companyInfo.incorporationInfo, profileData.companyInfo.companyNameHistoryList, profileData.companyInfo.incorporationInfo, saveApplication]);

  const handleEditClick = () => {
    setEditView(!isEditView);
  };

  const onValueChanged = updatedObj => {
    dispatchProfile({
      type: 'UPDATE_PROFILE',
      field: 'companyInfo',
      payload: updatedObj.companyInfo
    });
  };

  return <div className={styles.container}>
    <ApplicationDescription title='Company Information' />
    <FormInstructions />
    {formSections.map(section => <FormSection key={section.title} readOnly={isReadOnly || isEditView} section={section} data={profileData} handleValueChange={onValueChanged} />)}
    {!isReadOnly && <Button className={styles.editBtn} title={isEditView ? editControls.EDIT : editControls.CANCEL} buttonType='secondary' size='small' onClick={handleEditClick} />}
    {!isReadOnly && <VerifyBox section={verifiedSectionKeys.COMPANY_INFO} />}
    <Link to={`${routes.PROFILE_PREFIX}/${symbol}/${routes.CONTACT_INFO}`}>
      <Button title='Proceed to Contact Information' fullWidth />
    </Link>
  </div>;
};

CompanyInformation.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string
  }),
  saveApplication: PropTypes.func
};

export default withRouter(CompanyInformation);

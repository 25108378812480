import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip } from 'devextreme-react/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from '../Title';
import Entity from '../Entity';
import FieldError from '../FieldError';
import Footnote from '../Footnote';
import styles from './EntityList.module.scss';

const ENTER_INFORMATION = 'Enter Information';

const EntityList = ({ className, children, title, moreInfo, icon, iconComplete, addTitle, readOnly, entityTitle, entityTitles, subTitle, footNote, confirmRemoveText, hideSubTitle, handleHideRemove, emptyTitle, showIncompleteList, showIncompletes, incompleteMessage, validateComplete, handleAddClick, handleEntityClick, handleEntityRemove, handleCustomRemove, hasCustomRemove, list }) => {
  const [listFocus, setListFocus] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const showEmpty = emptyTitle && (!list || (list && list.length < 1));  
  const onEntityClick = item => handleEntityClick && handleEntityClick(item);
  const labelId = `entity-title${Math.floor(Math.random()*90000) + 10000}`;

  const handleMouseToggle = () => {
    setFocus(!isFocus);
  };
  const handleMouseEnter = () => {
    showIncompleteList && setListFocus(true);
  };

  const handleMouseLeave = () => {
    showIncompleteList && setListFocus(false);
  };

  return (
    <div
      className={cn(styles.container, className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {title && <div className={cn({
          [styles.incomplete]: showIncompleteList
        })}>
        <Title className={styles.title} title={title} type='h3' />
        {moreInfo && <>
          <FontAwesomeIcon
            id={labelId}
            className={cn(styles.icon, styles.moreInfo)}
            icon={['far', 'circle-info']}
            onMouseEnter={handleMouseToggle}
            onMouseLeave={handleMouseToggle} />
          <Tooltip
            target={`#${labelId}`}
            visible={isFocus}
            position='bottom'
            maxWidth='375px'
            hideOnOutsideClick={false}
          >
            <div>
              {moreInfo}
            </div>
          </Tooltip>
        </>}
        {showIncompleteList && <FieldError error={incompleteMessage} isFocus={listFocus} />}
        {footNote && <Footnote text={footNote} />}
      </div>}
      {list && <div className={styles.list}>
          {list.map((item, i) => {
            const isComplete = validateComplete && validateComplete(item);
            const subTitleText = isComplete ? 'Complete' : subTitle ? subTitle : ENTER_INFORMATION;
            const hideClose = handleHideRemove && handleHideRemove(item);
            const isCustomRemove = hasCustomRemove ? hasCustomRemove(item) : false;
            const confirmRemoveItemText = typeof confirmRemoveText === 'function' ? confirmRemoveText(item) : confirmRemoveText;
            let entityIcon = icon;

            if (isComplete) entityIcon = iconComplete;
            if (showIncompletes && !isComplete) entityIcon = 'incomplete';

            const EntityItem = () => (
              <Entity
                key={i}
                title={entityTitle && entityTitle(item) || entityTitles && entityTitles(item)}
                icon={entityIcon}
                isComplete={isComplete}
                hideSubTitle
                showIncompletes={showIncompletes && !isComplete}
                confirmRemoveText={confirmRemoveItemText}
                subTitle={readOnly ? 'View' : hideSubTitle ? '' : subTitleText}
                subTitleHighlight={!readOnly && subTitleText === ENTER_INFORMATION}
                onClick={() => onEntityClick(item)}
                hideClose={hideClose}
                handleCustomRemove={(isCustomRemove && handleCustomRemove) ? (e => handleCustomRemove(e, item)) : undefined}
                handleRemove={(!isCustomRemove && handleEntityRemove) ? (e => handleEntityRemove(e, item)) : undefined} />
            );

            if (children) return children(EntityItem, item, i);
              
            return <EntityItem key={i} />;
          })}
        </div>
      }
      {((readOnly && !showEmpty) && (!list || list.length === 0)) && <Entity
        title={`No ${title}`}
        isEmpty
        size='small' />}
        
      {(addTitle && !readOnly) && <Entity
        title={addTitle}
        isAdd
        onClick={handleAddClick} />}
      {showEmpty && <Entity
        title={emptyTitle}
        isEmpty />}
    </div>
  );
};

EntityList.propTypes = {
  addTitle: PropTypes.string,
  title: PropTypes.string,
  moreInfo: PropTypes.string,
  className: PropTypes.string,
  hideSubTitle: PropTypes.bool,
  handleAddClick: PropTypes.func,
  list: PropTypes.array,
  footNote: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  entityTitle: PropTypes.func,
  entityTitles: PropTypes.func,
  confirmRemoveText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  validateComplete: PropTypes.func,
  children: PropTypes.node,
  icon: PropTypes.string,
  iconComplete: PropTypes.string,
  readOnly: PropTypes.bool,
  subTitle: PropTypes.string,
  handleHideRemove: PropTypes.func,
  emptyTitle: PropTypes.string,
  showIncompleteList: PropTypes.bool,
  showIncompletes: PropTypes.bool,
  incompleteMessage: PropTypes.string,
  handleEntityClick: PropTypes.func,
  handleEntityRemove: PropTypes.func,
  handleCustomRemove: PropTypes.func,
  hasCustomRemove: PropTypes.func
};

export default EntityList;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip } from 'devextreme-react/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { timezones } from '../../constants';
import DialogBox from '../DialogBox';
import styles from './Table.module.scss';

const Row = ({ item, columns, onRowClick, onRemoveRow, allowRemoveField, allowRowClickField }) => {
  const [confirmRemove, setConfirmRemove] = useState(false);
  const id = `target-${item.id}`;
  const isRowClick = (onRowClick && (allowRowClickField ? allowRowClickField(item) : true));
  const isRowRemove = (allowRemoveField ? allowRemoveField(item) : true);

  const handleRemoveClick = e => {
    e.stopPropagation();
    setConfirmRemove(true);
  };

  const hideDialog = e => {
    e.stopPropagation();
    setConfirmRemove(false);
  };

  const onSubmit = e => {
    e.stopPropagation();
    setConfirmRemove(false);
    onRemoveRow(e, item);
  };

  return (
    <>
      <tr id={id} className={cn({[styles.rowClick]: isRowClick})} onClick={() => isRowClick && onRowClick(item)}>
        {columns.map((column, i) => <td key={i} className={styles.cell} width={column.type === 'remove' && '45px' ? '45px' : null}>
          {column.type === 'date' && moment(item[column.field]).format('MM/DD/YYYY')}
          {(column.type === 'string' || !column.type) && item[column.field]}
          {(column.type === 'remove' && isRowRemove) && <FontAwesomeIcon icon={['fal', 'times']} onClick={handleRemoveClick} />}
        </td>)}
        {confirmRemove && <td className={styles.dialogCell}>
          <DialogBox
              className={styles.dialog}
              subTitle={'Are you sure you want to delete?'}
              cancelText='Cancel'
              submitText='Yes'
              isHorizontal
              size='small'
              onCancelClick={e => hideDialog(e)}
              onSubmitClick={e => onSubmit(e)} />
        </td>}
      </tr>
    </>
  );
};

Row.propTypes = {
  item: PropTypes.object,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  onRemoveRow: PropTypes.func,
  allowRemoveField: PropTypes.func,
  allowRowClickField: PropTypes.func
};

export default Row;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorPage.module.scss';

const ErrorPage = ({ className }) => {
    return <div className={`${styles.container} ${className}`}>
        <h2>ERROR</h2>
        <p>
            We're sorry, there is a problem with the current request. Details regarding this message have been logged. Please try again and <a href='mailto:issuers@otcmarkets.com'>Contact us</a> if the problem continues.
            <br />Press Back to Previous page.
        </p>
    </div>;
};

ErrorPage.propTypes = {
    className: PropTypes.string
};

export default ErrorPage;

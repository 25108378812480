import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './CharacterCount.module.scss';


const CharacterCount = ({ inputLength, total }) => {
  const isLimit = inputLength >= total;
  return <div className={styles.container}>
    {isLimit && <span className={cn(styles.error, styles.errorMsg)}>You have reached the character limit.</span>}
    <span className={cn({
      [styles.error]: isLimit
    })}>{inputLength}/{total}</span>
  </div>;
};

CharacterCount.propTypes = {
  inputLength: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};


export default CharacterCount;
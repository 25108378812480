import React from 'react';
import PropTypes from 'prop-types';

export default function OTCPink({
  size = 16, // or any default size of your choice
  color = '#FFFFFF' // or any color of your choice
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 88.45 36.5'
      fill={color}
      height={size} // added size here
    >
      <g>
        <g>
            <path d='M29.2.75h4.4V5H29.2Zm.25,9.9H33.3V36.5H29.45Z'/>
            <path d='M4,5.15H12.8c5.65,0,9.4,2.6,9.4,7.6v.1c0,4.6-3.8,7.7-9.6,7.7H7.8v3.6h4.65c7.45,0,13.75-3.9,13.75-11.45v-.1C26.2,5.7,21,1.5,13.1,1.5H0v35H4Z'/>
            <path d='M37.8,10.65h3.85v4.5A9.79,9.79,0,0,1,50.5,10.1c6.25,0,9.9,4.2,9.9,10.35V36.5H56.55V21.4c0-4.8-2.6-7.8-7.15-7.8s-7.75,3.25-7.75,8.1V36.5H37.8Z'/>
            <path d='M65.5,0h3.85V25.2l14-14.55h4.8L77.25,21.75,88.45,36.5h-4.6l-9.25-12-5.25,5.3V36.5H65.5Z'/>
        </g>
      </g>
    </svg>
  );
};

OTCPink.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'devextreme-react/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from './Row';
import cn from 'classnames';
import styles from './Table.module.scss';

const removeObj = {
  header: '',
  type: 'remove'
};

const Table = ({ className, columns, data, tooltip, onRowClick, onRemoveRow, allowRemoveField, allowRowClickField, hideTooltip }) => {
  const [isFocus, setFocus] = useState(false);
  const cols = [...columns];

  if (onRemoveRow) cols.push(removeObj);

  const handleMouseToggle = () => {
    setFocus(!isFocus);
  };
 
  return (
    <table className={cn(className, styles.container)}>
      <thead>
        <tr>
          {cols && cols.map((col, i) => (
            <th key={i} className={styles.cell}>
              {col.header}
              {col.moreInfo && <>
                <FontAwesomeIcon
                  id={col.header}
                  className={styles.moreInfo}
                  icon={['far', 'circle-info']}
                  onMouseEnter={handleMouseToggle}
                  onMouseLeave={handleMouseToggle} />
                <Tooltip
                  target={`#${col.header}`}
                  visible={isFocus}
                  position='bottom'
                  maxWidth='375px'
                  hideOnOutsideClick={false}
                >
                  <>
                    {col.moreInfo}
                  </>
                </Tooltip>
              </>}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data && data.map((item, i) => (
          <Row key={i} item={item} columns={cols} tooltip={tooltip} onRowClick={onRowClick} onRemoveRow={onRemoveRow} allowRemoveField={allowRemoveField} allowRowClickField={allowRowClickField} hideTooltip={hideTooltip} />
        ) )}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  tooltip: PropTypes.node,
  hideTooltip: PropTypes.bool,
  className: PropTypes.string,
  allowRemoveField: PropTypes.func,
  allowRowClickField: PropTypes.func,
  onRowClick: PropTypes.func,
  onRemoveRow: PropTypes.func
};

export default Table;

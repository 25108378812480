import { errors, fieldTypes } from '../index';
import { isEmail } from '../../utils/validations';

export const personNames = [
    {
        type: fieldTypes.TEXTBOX,
        label: 'First Name',
        key: 'firstName',
        isRequired: true,
        colSpan: 2
    },
    {
        type: fieldTypes.TEXTBOX,
        label: 'Middle Name',
        key: 'middleName',
        colSpan: 2
    },
    {
        type: fieldTypes.TEXTBOX,
        label: 'Last Name',
        key: 'lastName',
        isRequired: true,
        colSpan: 2
    }
];

const peopleSection1 = [
    ...personNames,
    {
        type: fieldTypes.PHONE,
        label: 'Phone Number',
        labelIcon: ['far', 'lock'],
        key: 'phone',
        key2: 'phoneCountryCode',
        colSpan: 3
    },
    {
        type: fieldTypes.TEXTBOX,
        label: 'Email',
        labelIcon: ['far', 'lock'],
        key: 'email',
        isValid: data => isEmail(data.email),
        invalidText: errors.INVALID_EMAIL,
        colSpan: 3
    }
];

const peopleSection2 = [
    {
        type: fieldTypes.TEXTBOX,
        label: 'X/Twitter Profile',
        key: 'twitterId',
        colSpan: 3
    },
    {
        type: fieldTypes.TEXTBOX,
        label: 'Facebook Profile',
        key: 'facebookId',
        colSpan: 3
    },
    {
        type: fieldTypes.TEXTBOX,
        label: 'LinkedIn Profile',
        key: 'linkedInId',
        colSpan: 3
    },
    {
        type: fieldTypes.TEXTAREA,
        label: 'Biography',
        key: 'biography',
        colSpan: 6
    }
];

export const officerSection1 = [
    {
        fields: peopleSection1
    }
];

export const officerSection2 = [
    {
        fields: peopleSection2
    }
];

export const directorSection1 = [
    {
        subTitle: 'Provide legal name, board position and contact information below.',
        fields: peopleSection1
    }
];

export const directorSection2 = [
    {
        subTitle: 'Select all Committee Memberships that apply to this Director.',
        fields: [
            {
                type: fieldTypes.CHECKBOX,
                text: 'Audit Committee',
                key: 'isAuditCommittee',
                colSpan: 6
            },
            {
                type: fieldTypes.CHECKBOX,
                text: 'Nominating Committee',
                key: 'isNominatingCommittee',
                colSpan: 6
            },
            {
                type: fieldTypes.CHECKBOX,
                text: 'Compensation Committee',
                key: 'isCompensationCommittee',
                colSpan: 6
            },
            ...peopleSection2
        ]
    }
];

export const individualSection = [
    {
        fields: [
            ...personNames
        ]
    }
];

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment-timezone';
import { appStatuses, warnings } from '../../constants';
import { isTierOTCQB, isTierOTCQX, isTierPink } from '../../utils/helper';
import Button from  '../Button';
import Loading from '../Loading';
import DialogBox from  '../DialogBox';
import GATEWAY_DEFAULT from '../../svgs/logos/GATEWAY_DEFAULT';
import OTCQXLogo from '../../svgs/logos/OTCQX';
import OTCQBLogo from '../../svgs/logos/OTCQB';
import OTCPinkLogo from '../../svgs/logos/OTCPink';
import { Table as OTCTable } from '@otcmarketsgroup/otcm-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './DashboardCard.module.scss';

const cols = [
  {
    name: 'action'
  },
  {
    name: 'date',
    component: item => moment(item.item.date).format('MM/DD/YYYY')
  }
];

const DashboardCard = ({ item, inactive, error, onCreateClick, onCancelClick, onReopenClick }) => {
  const [cancelDialog, setCancelDialog] = useState(false);
  const [reopenDialog, setReopenDialog] = useState(false);
  const [isHistoryOpen, setHistory] = useState(false);
  const appType = item.tier;
  const verifiedProfile = item.verifiedProfile;
  const isOTCQX = isTierOTCQX(appType);
  const isOTCQB = isTierOTCQB(appType);
  const isPink = isTierPink(appType);
  const isInProgress = item.status.id === appStatuses.IN_PROGRESS;
  const isSubmitted = item.status.id === appStatuses.SUBMITTED;
  const isCancelled = item.status.id === appStatuses.CANCELLED;
  const isApproved = item.status.id === appStatuses.APPROVED;
  const isExpired = item.status.id === appStatuses.EXPIRED;
  const isClosed = item.status.id === appStatuses.CLOSED;
  const showCancel = isInProgress || isSubmitted;
  const showReopen = isSubmitted;
  const hasHistory = item.history && item.history.length > 0;
  const noStatus = item.status.id === appStatuses.NO_APPLICATION;
  let applicationLink = `/app/manage-company-profile/${item.symbol}`;
  let dateText;
  let ctaText = 'View';
  let AppLogo = GATEWAY_DEFAULT;

  if (isOTCQX) AppLogo = OTCQXLogo;
  if (isOTCQB) AppLogo = OTCQBLogo;
  if (isPink) AppLogo = OTCPinkLogo;

  if (noStatus) ctaText = 'Start';

  if (isInProgress) {
    dateText = 'Last Updated on';
    ctaText = 'View/Edit';
  };

  if (isCancelled) dateText = 'Cancelled on';
  if (isApproved) dateText = 'Approved on';
  if (isExpired) dateText = 'Expired on';
  if (isClosed) dateText = 'Closed on';
  if (isSubmitted) {
    applicationLink = `${applicationLink}/supplemental-documents`;
    dateText = 'Last Updated on';
    ctaText = 'View';
  };

  const handleCancel = _ => {
    onCancelClick(item);
    setCancelDialog(false);
  };

  const handleReopen = _ => {
    onReopenClick(item);
    setReopenDialog(false);
  };

  const handleHistoryClick = () => {
    setHistory(!isHistoryOpen);
  };

  return <div className={cn(styles.container)}>
    {inactive && <div className={styles.inactive}>
      <Loading loadingMessage={warnings.DO_NOT_REFRESH} />
    </div>}
    <div className={styles.titleBar}>
      <div className={styles.typeContainer}>
        <div className={cn(styles.typeIcon, {
          [styles.green]: isOTCQX,
          [styles.orange]: isOTCQB,
          [styles.pink]: isPink
        })}>
          <AppLogo />
        </div>
        {verifiedProfile && <div className={styles.verifiedStatus}>
          <FontAwesomeIcon className={styles.checkIcon} icon={['fa', 'circle-check']} />
          <div className={styles.status}>Verified Profile</div>
        </div>}
        {!verifiedProfile && <div className={styles.verifiedStatus}>
          <FontAwesomeIcon className={styles.xmarkIcon} icon={['fa', 'circle-xmark']} />
          <div className={styles.status}>Profile Not Verified</div>
        </div>}
      </div>
    </div>
    {!noStatus && <div className={styles.statusContainer}>
        {item.status && <div className={styles.status}>
          {item.status.description}
        </div>}
        {item.lastUpdatedBy && <div className={styles.modifiedBy}>
          Last modified by {item.lastUpdatedBy} on {moment(item.lastUpdated).format('MM/DD/YYYY')}
        </div>}
    </div>}
    <div className={styles.info}>
      <div className={styles.name}>
        {item.companyName && item.companyName}
      </div>
      <div className={styles.flexContainer}>
        <div className={styles.bottomRow}>
          <div className={cn({[styles.hide]: inactive})}>
            {showCancel && <div className={styles.cancel} onClick={() => setCancelDialog(true)}>Cancel</div>}
            {showReopen && <div className={styles.reopen} onClick={() => setReopenDialog(true)}>Reopen</div>}
            {error && <div className={styles.errorMsg}>{error}</div>}
          </div>
          {hasHistory && <div className={styles.historyLink} onClick={handleHistoryClick}>
            See Verification History
            <FontAwesomeIcon className={cn(styles.chevron, { [styles.open]: isHistoryOpen })} icon={['far', 'chevron-down']} />
          </div>}
        </div>
        {noStatus && <Button className={cn({[styles.hide]: inactive})} title={ctaText} buttonType='secondary' color='gray' size='large' onClick={_ => onCreateClick(item)} />}
        {!noStatus && <a href={applicationLink}>
          <Button className={cn({[styles.hide]: inactive})} title={ctaText} buttonType='secondary' color='gray' size='large' />
        </a>}
      </div>
      {hasHistory && <div className={cn(styles.divider, { [styles.open]: isHistoryOpen })} />}
      {hasHistory && <div className={cn(styles.historyTable, { [styles.open]: isHistoryOpen })}>
        <OTCTable columns={cols} data={item.history} />
      </div>}
    </div>
    {cancelDialog && <div className={styles.dialogContainer}>
      <DialogBox
        className={styles.dialog}
        subTitle='Are you sure you want to cancel this profile? This will permanently cancel for all owners.'
        cancelText='No, go back'
        submitText='Yes, I understand'
        onCancelClick={() => setCancelDialog(false)}
        onSubmitClick={() => handleCancel()} />
    </div>}
    {reopenDialog && <div className={styles.dialogContainer}>
      <DialogBox
        className={styles.dialog}
        subTitle={`Are you sure you want to Modify this Verified Profile?  If you proceed, the original submission will be recalled and no changes will be made to the company's information until a new Verified Profile is submitted`}
        cancelText='No, go back'
        submitText='Yes'
        onCancelClick={() => setReopenDialog(false)}
        onSubmitClick={() => handleReopen()} />
    </div>}
  </div>;
};

DashboardCard.propTypes = {
  item: PropTypes.object,
  error: PropTypes.string,
  inactive: PropTypes.bool,
  onCreateClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onReopenClick: PropTypes.func
};

export default DashboardCard;

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from '../../WithRouter';
import { ProfileContext } from '../../../context/Profile.context';
import { editControls, routes, verifiedSectionKeys } from '../../../constants';
import { formSections } from '../../../constants/forms/contactInformation';
import { AddressEntity } from '../../Entities';
import CheckBoxField from '../../CheckBoxField';
import FormInstructions from '../../FormInstructions';
import FormSection from '../../FormSection';
import VerifyBox from '../../VerifyBox';
import Title from '../../Title';
import Button from '../../Button';
import ApplicationDescription from '../../ApplicationDescription/ApplicationDescription';
import styles from './ContactInformation.module.scss';

const SAME_AS_ADDRESS = 'Same as Principal Place of Business';
const BILLING_ADDRESS_FOOTNOTE= 'If your accounting department requires a purchase order please include it in the address section';

const ContactInformation = ({ params: { symbol } }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [isEditView, setEditView] = useState(true);
  const profileData = profileState.profile;
  const contactInfo = profileData && profileData.contactInfo;
  const principalAddressData = contactInfo && profileData.contactInfo.address;
  const billingAddressData = contactInfo && profileData.contactInfo.billingAddress;
  const execAddressData = contactInfo && profileData.contactInfo.executiveAddress;
  const isReadOnly = profileState.readOnly;

  const handleEditClick = () => {
    setEditView(!isEditView);
  };

  const onCheckboxChanged = (value, field) => {
    const updateProfile = { ...contactInfo };
    let addressObj;
    updateProfile.address[field] = value;

    if (field === 'sameBillingAddress') addressObj = 'billingAddress';
    if (field === 'sameExecAddress') addressObj = 'executiveAddress';
    updateProfile[addressObj] = { ...updateProfile.address };

    delete updateProfile[addressObj][field];

    dispatchProfile({
      type: 'UPDATE_PROFILE',
      field: 'contactInfo',
      payload: updateProfile
    });
  };

  const onAddressChanged = (updatedObj, field) => {
    const updatedContactInfo = { ...contactInfo };
    updatedContactInfo[field] = updatedObj;

    dispatchProfile({
      type: 'UPDATE_PROFILE',
      field: 'contactInfo',
      payload: updatedContactInfo
    });
  };

  const onValueChanged = updatedObj => {
    dispatchProfile({
      type: 'UPDATE_PROFILE',
      field: 'contactInfo',
      payload: updatedObj.contactInfo
    });
  };

  return <div>
    <ApplicationDescription title='Contact Information' />
    <FormInstructions />
    <div className='mbXL'>
      <Title title='Corporate Addresses' type='h2' />
      <div className='mbMed'>
        <Title title='Principal Place of Business' type='h3' />
        <AddressEntity data={principalAddressData} title='Principal Place of Business' addressKey='address' isReadOnly={isReadOnly} onUpdate={onAddressChanged} />
      </div>
      <div className='mbMed'>
        <div className={styles.addressTitleContainer}>
          <Title title='Billing Address' type='h3' />
          {!isReadOnly && <CheckBoxField
            className={styles.addressCheckbox}
            text={SAME_AS_ADDRESS}
            size='small'
            value={principalAddressData.sameBillingAddress}
            onValueChanged={e => onCheckboxChanged(e.value, 'sameBillingAddress')} />}
        </div>
        <AddressEntity data={billingAddressData} title='Billing Address' isStatic={principalAddressData.sameBillingAddress} staticData={principalAddressData} isReadOnly={isReadOnly} addressKey='billingAddress' 
        onUpdate={onAddressChanged} footNote={BILLING_ADDRESS_FOOTNOTE} />
      </div>
      <div className='mbMed'>
        <div className={styles.addressTitleContainer}>
          <Title title='Principal Executive Office' type='h3' />
          {!isReadOnly && <CheckBoxField
            className={styles.addressCheckbox}
            text={SAME_AS_ADDRESS}
            size='small'
            value={principalAddressData.sameExecAddress}
            onValueChanged={e => onCheckboxChanged(e.value, 'sameExecAddress')} />}
        </div>
        <AddressEntity data={execAddressData} title='Principal Executive Office' isStatic={principalAddressData.sameExecAddress} staticData={principalAddressData} isReadOnly={isReadOnly} addressKey='executiveAddress' onUpdate={onAddressChanged} />
      </div>
    </div>
    
    <div className={styles.section}>
      {formSections.map(section => <FormSection key={section.title} readOnly={isEditView} section={section} data={profileData} handleValueChange={onValueChanged} />)}
      {!isReadOnly && <Button className={styles.editBtn} title={isEditView ? editControls.EDIT : editControls.CANCEL} buttonType='secondary' size='small' onClick={handleEditClick} />}
    </div>
    {!isReadOnly && <VerifyBox section={verifiedSectionKeys.CONTACT_INFO} />}
    <Link to={`${routes.PROFILE_PREFIX}/${symbol}/${routes.PEOPLE}/officers-contacts`}>
      <Button title='Proceed to People' fullWidth />
    </Link>
  </div>;
};

ContactInformation.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string
  })
};

export default withRouter(ContactInformation);

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { noControlPersonText } from '../../../constants';
import { ProfileContext } from '../../../context/Profile.context';
import { isControlPersonsTabComplete } from '../../../utils/completes';
import FieldError from '../..//FieldError';
import IndividualControlPerson from './IndividualControlPerson';
import CheckBoxField from '../../CheckBoxField';
import CorporateEntities from './CorporateEntities';
import styles from './People.module.scss';

const ControlPersonTab = ({ onControlPersonAdd, onControlPersonUpdate, onControlPersonListUpdate, onControlPersonRemove }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [isFocus, setFocus] = useState(false);
  const profile = profileState.profile;
  const controlPersons = profile.controlPersons;
  const individualsList = controlPersons && controlPersons.filter(person => !person.isCorpEntity && !person.isDeleted);
  const hasIndividuals = individualsList && individualsList.length > 0;
  const corporateEntityList = controlPersons && controlPersons.filter((person, index) => (person.corpEntity && !person.hasBeneficialOwners && !person.isDeleted)
    || (person.corpEntity && person.hasBeneficialOwners && controlPersons.findIndex((item) => item.corpEntity === person.corpEntity && !item.isDeleted) === index));
  const hasCorpEntities = corporateEntityList && corporateEntityList.length > 0;
  const isTabComplete = isControlPersonsTabComplete(profile);
  const isReadOnly = profileState.readOnly;

  const onValueChanged = (value, field) => {
    dispatchProfile({
      type: 'UPDATE_PROFILE',
      field: field,
      payload: value
    });
  };

  return (
    <div
      onMouseEnter={!isTabComplete ? () => setFocus(true) : undefined}
      onMouseLeave={!isTabComplete ? () => setFocus(false) : undefined}>
      <div className={cn({
          [styles.incomplete]: !isTabComplete
        })} style={{ paddingBottom: '8px' }}>
          {!isTabComplete && <FieldError error='At least one person or entity is required. Or select No Control Persons.' isFocus={isFocus} />}
      </div>
      <div className='mbLg'>
        <p>
          Enter all persons or entities beneficially owning 10% or more of any class of the issuer's shares; do not include Officers and Directors previously listed.
        </p>
        <p>
        A Beneficial Shareholder is any person who, directly or indirectly, has or shares the power to vote or dispose of a stock. Beneficial ownership includes the right to acquire the security within 60 days including but not limited to the exercise of options or warrants, or through conversion. See Securities Exchange Act Rule 13d-3.
        </p>
      </div>
      <CheckBoxField
        className='mbXL'
        value={profile.noControlPerson}
        disabled={isReadOnly || (hasIndividuals || hasCorpEntities)}
        text={noControlPersonText}
        onValueChanged={e => onValueChanged(e.value, 'noControlPerson')} />
      <IndividualControlPerson noControlPerson={profile.noControlPerson} onControlPersonAdd={onControlPersonAdd} onControlPersonUpdate={onControlPersonUpdate} onControlPersonRemove={onControlPersonRemove} />
      <CorporateEntities
        noControlPerson={profile.noControlPerson}
        onControlPersonAdd={onControlPersonAdd}
        onControlPersonUpdate={onControlPersonUpdate}
        onControlPersonListUpdate={onControlPersonListUpdate}
        onControlPersonRemove={onControlPersonRemove}
      />
    </div>
  );
};

ControlPersonTab.propTypes = {
  onControlPersonAdd: PropTypes.func,
  onControlPersonUpdate: PropTypes.func,
  onControlPersonListUpdate: PropTypes.func,
  onControlPersonRemove: PropTypes.func
};

export default ControlPersonTab;

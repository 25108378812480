import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal/es6';
import { withRouter } from '../../WithRouter';
import { contactRoles, fields, routes, verifiedSectionKeys } from '../../../constants';
import { isOfficersContactsTabComplete, isControlPersonsTabComplete, isDirectorsTabComplete } from '../../../utils/completes';
import { createID } from '../../../utils/helper';
import { ProfileContext } from '../../../context/Profile.context';
import FormInstructions from '../../FormInstructions';
import OfficersTab from './OfficersTab';
import DirectorsTab from './DirectorsTab';
import ControlPersonTab from './ControlPersonTab';
import TabMenu from '../../TabMenu';
import VerifyBox from '../../VerifyBox';
import Button from '../../Button';
import ApplicationDescription from '../../ApplicationDescription/ApplicationDescription';

const pageTabItems = [
  {
    title: 'Officers & Contacts',
    path: routes.OFFICERS,
    component: OfficersTab,
    validateComplete: isOfficersContactsTabComplete
  },
  {
    title: 'Directors',
    path: routes.DIRECTORS,
    component: DirectorsTab,
    validateComplete: isDirectorsTabComplete
  },
  {
    title: 'Control Persons',
    path: routes.CONTROL_PERSONS,
    component: ControlPersonTab,
    validateComplete: isControlPersonsTabComplete
  }
];

const getTabItems = (profile) => {

  const newTabs = pageTabItems.map((item, i) => {
    let completed = true;
    if (profile && item.validateComplete) {
      completed = item.validateComplete(profile);
    }
    item.completed = completed;
    return item;
  });
  return newTabs;


};

const People = ({ navigate, params: { symbol, formSection, formTab }, saveApplication }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const profile = profileState.profile;
  const backupProfile = profileState.backupProfile;
  const controlPersons = profile.controlPersons;
  const peopleList = profile.peopleList;
  const tabItems = getTabItems(profile);
  const currentTab = tabItems.find(item => item.path === formTab);
  const [activeTab, setActiveTab] = useState(currentTab || tabItems[0]);
  const isReadOnly = profileState.readOnly;

  useEffect(() => {
    const tab = tabItems[0].path;
    if (!formTab) {
      navigate(`${routes.PROFILE_PREFIX}/${symbol}/${formSection}/${tab}`, { replace: true });
    }
  }, [navigate, formTab, symbol, formSection]);

  useEffect(() => {
    setActiveTab(tabItems.find(tab => tab.path === formTab));
  }, [formTab]);

  useEffect(() => {
    const isPeopleChange = !equal({ ...backupProfile.peopleList }, { ...peopleList });
    const isControlPersons = !equal({ ...backupProfile.controlPersons }, { ...controlPersons });
    const isNoControlChange = backupProfile.noControlPerson !== profile.noControlPerson;

    if (isPeopleChange || isControlPersons || isNoControlChange) {
      saveApplication();
    }
  }, [backupProfile.controlPersons, backupProfile.noControlPerson, backupProfile.peopleList, controlPersons, peopleList, profile, saveApplication]);

  const onUpdate = (data, list) => {
    dispatchProfile({
      type: 'UPDATE_PROFILE',
      field: list,
      payload: data
    });
  };

  const onPersonAdd = person => {
    const newPerson = person;
    if (!person.id) {
      const id = createID(peopleList);
      newPerson.id = id;
    }

    onUpdate([...peopleList, newPerson], fields.PEOPLE_LIST);
  };
  
  const onControlPersonAdd = person => {
    const newPerson = person;
    if (!person.id) {
      const id = createID(controlPersons);
      newPerson.id = id;
    }

    onUpdate([...controlPersons, newPerson], fields.CONTROL_PERSONS);
  };

  const onPersonUpdate = person => {
    let updatePeopleList = [...peopleList];

    updatePeopleList = updatePeopleList.map(item => {
      if (item.id && (item.id === person.id)) return person;

      return item;
    });

    onUpdate(updatePeopleList, fields.PEOPLE_LIST);
  };

  const onControlPersonUpdate = person => {
    let updatePeopleList = [...controlPersons];

    updatePeopleList = updatePeopleList.map(item => {
      if (item.id && (item.id === person.id)) return person;

      return item;
    });

    onUpdate(updatePeopleList, fields.CONTROL_PERSONS);
  };

  const onControlPersonListUpdate = list => {
    onUpdate(list, fields.CONTROL_PERSONS);
  };

  const onPersonRemove = (person, type) => {
    const updatePeopleList = peopleList;
    const personUpdate = updatePeopleList.find(item => item.id && item.id === person.id);
    
    if (type === fields.CHAIR_PERSON) personUpdate.isDirector = false;
    if (type === fields.CHAIR_PERSON || type === fields.DIRECTOR) {
      delete person.isIndependent;
      delete person.isAuditCommittee;
      delete person.isNominatingCommittee;
      delete person.isCompensationCommittee;
    }

    personUpdate[type] = false;

    const hasRole = contactRoles.find(role => person[role]);
    if (!hasRole) personUpdate.isDeleted = true;

    onUpdate(updatePeopleList, fields.PEOPLE_LIST);
  };

  const onControlPersonRemove = person => {
    const updateControlPersons = controlPersons;
    const isListUpdate = person.isCorpEntity && person.hasBeneficialOwners;

    if (isListUpdate) {
      const listUpdate = updateControlPersons.map(item =>{
        if (item.corpEntity && item.corpEntity === person.corpEntity) {
          item.isDeleted = true;
          return item;
        };
        return item;
      });

      onUpdate(listUpdate, fields.CONTROL_PERSONS);
    }

    if (!isListUpdate) {
      const personUpdate = updateControlPersons.find(item => item.id && item.id === person.id);
    
      personUpdate.isDeleted = true;
  
      onUpdate(updateControlPersons, fields.CONTROL_PERSONS);
    }
  };

  const renderTab = () => {
    const TabComponent = currentTab && currentTab.component;

    return TabComponent && <TabComponent
      onPersonAdd={onPersonAdd}
      onPersonUpdate={onPersonUpdate}
      onPersonRemove={onPersonRemove}
      onControlPersonAdd={onControlPersonAdd}
      onControlPersonUpdate={onControlPersonUpdate}
      onControlPersonListUpdate={onControlPersonListUpdate}
      onControlPersonRemove={onControlPersonRemove}
    />;
  };

  const handleTabClick = tab => {
    navigate(`${routes.PROFILE_PREFIX}/${symbol}/${formSection}/${tab.path}`);
  };

  return <div>
    <ApplicationDescription title='People' />
    <FormInstructions />
    {activeTab && <>
      <TabMenu items={tabItems} activeTab={activeTab.title} handleTabClick={handleTabClick} />
      {renderTab()}
    </>}
    {!isReadOnly && <VerifyBox section={verifiedSectionKeys.PEOPLE} />}
    <Link to={`${routes.PROFILE_PREFIX}/${symbol}/${routes.SERVICE_PROVIDERS}`}>
      <Button title='Proceed to Service Providers' fullWidth />
    </Link>
  </div>;
};

People.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string,
    formSection: PropTypes.string,
    formTab: PropTypes.string
  }),
  navigate: PropTypes.func,
  saveApplication: PropTypes.func
};

export default withRouter(People);

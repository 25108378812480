import React from 'react';
import PropTypes from 'prop-types';
import TabButton from '../TabButton';
import styles from './TabMenu.module.scss';

const TabMenu = ({ items, activeTab, handleTabClick }) => {
  const handleOnClick = item => {
    handleTabClick(item);
  };

  return (
    <div className={styles.container}>
      {items.map(item => <TabButton
          key={item.title}
          text={item.title}
          error={!item.completed}
          active={activeTab === item.title}
          onClick={() => handleOnClick(item)} />)}
    </div>
  );
};

TabMenu.propTypes = {
  activeTab: PropTypes.string,
  items: PropTypes.array,
  handleTabClick: PropTypes.func
};

export default TabMenu;

import { getCompanyInfoCompare } from './companyInformation';
import { getContactInfoCompare } from './contactInfo';
import { getPeopleCompare } from './people';
import { getServiceProvidersCompare } from './serviceProviders';
import { getDesignatedContactsCompare } from './designatedContacts';
import { getSecuritiesCompare } from './securities';

export const compareProfiles = (currentProfile, updatedProfile) => {
    const json = {
        companyInfo: getCompanyInfoCompare(currentProfile, updatedProfile),
        contactInfo: getContactInfoCompare(currentProfile, updatedProfile),
        people: getPeopleCompare(currentProfile, updatedProfile),
        serviceProviders: getServiceProvidersCompare(currentProfile, updatedProfile),
        designatedContacts: getDesignatedContactsCompare(currentProfile, updatedProfile),
        securities: getSecuritiesCompare(currentProfile, updatedProfile),
        questionComment: updatedProfile.questionComment
    };

    return json;
};

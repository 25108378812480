import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { NumberBox as NumberBoxDevEx } from 'devextreme-react';
import { errors } from '../../constants';

const NumberBox = ({...props}) => {
  const [isFocus, setFocus] = useState(false);
  const isLabel = props.label || (!props.isValid && !props.hideErrorIcon);
  const value = props.value;
  const isNumber = typeof value === 'number';
  const showRequired = props.isRequired && !isNumber;

  const handleFocusIn = e => {
    props.onFocusIn && props.onFocusIn();
    setFocus(true);
  };

  const handleFocusOut = e => {
    props.onFocusOut && props.onFocusOut();
    setFocus(false);
  };

  return <>
    {isLabel && <div>
      {props.label && <FieldLabel text={props.label} icon={props.labelIcon} moreInfo={props.moreInfo} />}
      {!props.hideErrorIcon && (!props.isValid || !props.readOnly && showRequired) && <FieldError isFocus={isFocus} error={props.errorText || errors.INCOMPLETE_DATA_FIELD} />}
    </div>}
    <NumberBoxDevEx
      {...props}
      labelMode='hidden'
      onFocusIn={handleFocusIn}
      onFocusOut={handleFocusOut}
      min={props.min || 0}
      value={isNumber ? value : null}
      isValid={showRequired ? isNumber && value.trim().length > 0 : props.isValid}
      inputAttr={{
        autocomplete: 'no-thanks'
      }}
      showClearButton={props.showClearButton && isFocus} />
  </>;
};

NumberBox.propTypes = {
  label: PropTypes.string,
  labelIcon: PropTypes.array,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  showClearButton: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  moreInfo: PropTypes.string,
  errorText: PropTypes.string,
  min: PropTypes.number,
  onFocusIn: PropTypes.func,
  onFocusOut: PropTypes.func,
  readOnly: PropTypes.bool,
  hideErrorIcon: PropTypes.bool
};

NumberBox.defaultProps = {
  isValid: true
};

export default NumberBox;
import React, { useContext } from 'react';
import { withRouter } from '../WithRouter';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ProfileContext } from '../../context/Profile.context';
import NavButton from '../NavButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sections } from '../../constants/sections';
import { routes } from '../../constants';
import styles from './ProfileNav.module.scss';

const ProfileNav = ({ params: { symbol, formSection } }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const profileData = profileState.profile;
  const verifiedSections = profileData.verifiedSections;

  return (
    <nav className={styles.container}>
      <a href={routes.DASHBOARD}>
        <NavButton className='mbSm' text='Back to Company Profiles' icon='back' />
      </a>
      {sections.map((section, i) => {
        let path = section.path;
        const isActive = path === formSection;
        const isComplete = verifiedSections[section.key];
        const navIndex = i + 1;

        if (section.tabs) path = `${path}/${section.tabs[0]}`;

        return <Link key={section.name} to={`/app/manage-company-profile/${symbol}/${path}`} className={cn(styles.navItem, {
          [styles.active]: isActive,
          [styles.complete]: isComplete,
          [styles.static]: section.static
        })}>
          {isComplete && <FontAwesomeIcon className={styles.completeIcon} icon={['far', 'check']} />}
          <div className={cn(styles.navTitle, { [styles.static]: section.static })}>
            {section.name}
          </div>
          {!section.static && <div className={styles.navIndex}>
            {navIndex}
          </div>}
        </Link>;
      })}
    </nav>
  );
};

ProfileNav.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string,
    formSection: PropTypes.string
  })
};

export default withRouter(ProfileNav);

import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Button from '../Button';
import Entity from '../Entity';
import Title from '../Title';
import TextArea from '../TextArea';
import FieldError from '../FieldError';
import { isString } from '../../utils/validations';
import { errors } from '../../constants';
import styles from './QuestionCard.module.scss';

const QuestionCard = ({ item, section, type, handleQuestionClick, readOnly, isValid, disabled, onValueChanged, onShowDetail}) => {
  const [isFocus, setFocus] = useState(false);
  const value = section && section[item.fieldName];
  const isNoSelected = value === false ? 'green' : 'gray';
  const isYesSelected = value === true ? 'green' : 'gray';
  const showDetails = (value === true || value === false);
  const SubTitleComponent = item.subTitle;
  const isComponent = typeof SubTitleComponent === 'function';
  const ExtraInfoComponent = item.extraInfo;
  const isExtraInfoComponent = typeof ExtraInfoComponent === 'function';
 
  const renderDetail = detailObj => {
    if (detailObj.component) {
      const Component = detailObj.component;
      return <Component />;
    }

    return detailObj.map((obj, i) => {
      const DescComponent = obj.desc;
      const isDescComponent = typeof DescComponent === 'function';

      if (onShowDetail && !onShowDetail(obj)) {
        return;
      }

      return <div key={i}>
        {isDescComponent ? <DescComponent /> :<p>
          {obj.desc}
        </p>}
        {!obj.hideDetails && <TextArea
          label='Details'
          showClearButton
          placeholder={obj.placeholder || 'Enter text here.'}
          name='details'
          height={80}
          maxLength={1500}
          readOnly={readOnly}
          disabled={disabled}
          isValid={isString(section[obj.fieldName])}
          value={section[obj.fieldName]}
          onValueChanged={e => onValueChanged(e.value, obj.fieldName)} />}
      </div>;
    });
  };

  return <div className={cn(styles.container, {
        [styles.secondaryContainer]: type === 'secondary',
        [styles.nestedContainer]: type === 'nested',
        [styles.notValid]: !isValid
      })}
      onMouseOver={() => setFocus(true)}
      onMouseOut={() => setFocus(false)}
      >
      <div>
        <Title className={styles.title} type='h3' title={item.title} />
        {!isValid && <FieldError isFocus={isFocus} error={errors.INCOMPLETE_DATA_FIELD} />}
      </div>
      {isComponent ? <SubTitleComponent /> :<p>
        {item.subTitle}
      </p>}
    {!item.options && <div className={styles.controls}>
        <Button title='No' readOnly={readOnly} buttonType='secondary' color={isNoSelected} inactive={disabled} onClick={() => handleQuestionClick(false, item.fieldName)} />
        <Button title='Yes' readOnly={readOnly} buttonType='secondary' color={isYesSelected} inactive={disabled} onClick={() => handleQuestionClick(true, item.fieldName)} />
    </div>}
    {item.options && <div className={styles.options}>
      {item.options.map((option, i) => <Entity key={i} title={option.text} size='small' onClick={() => handleQuestionClick(option.value, item.fieldName)} />)}
    </div>}
    {showDetails && <div>
     {(!value && item.falseDetails) && renderDetail(item.falseDetails)}
     {(value && item.trueDetails) && renderDetail(item.trueDetails)}
    </div>}

    {isExtraInfoComponent ? <ExtraInfoComponent /> : <p>{item.extraInfo} </p> }
  </div>;
};

QuestionCard.propTypes = {
  item: PropTypes.object,
  section: PropTypes.object,
  type: PropTypes.oneOf(['primary', 'secondary', 'nested']),
  isValid: PropTypes.bool,
  handleQuestionClick: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onValueChanged: PropTypes.func,
  onShowDetail: PropTypes.func
};

QuestionCard.defaultProps = {
  type: 'primary',
  isValid: true
};

export default QuestionCard;

import api from './api';

export const getBadgeInfo = async symbol => {
  const response = await api.request({
    method: 'get',
    url: `/otcapi/company/profile/${symbol}/badges`
  });
  return response.data;
};

export async function getCompanyProfile (symbol) {
  const response = await api.request({
    method: 'get',
    url: `/otcapi/company/profile/full/${symbol}`,
    params: {
      symbol: symbol
    }
  });

  return response.data;
};

export async function getInsideData (symbol) {
  const response = await api.request({
    method: 'get',
    url: `/otcapi/stock/trade/inside/${symbol}`,
    params: {
      symbol: symbol
    }
  });

  return response.data;
};

import { routes } from './index';
import { CompanyInformation, ContactInformation, People, ServiceProviders, DesignatedContacts, Securities, ReviewSubmit } from '../components/ProfileSections';
import { ReviewCompanyInformation } from '../components/ReviewSection';
export const sections = [
    {
      name: 'Company Information',
      path: routes.COMPANY_INFO,
      key: 'companyInfo',
      component: CompanyInformation,
      reviewComponent: ReviewCompanyInformation
    },
    {
      name: 'Contact Information',
      path: routes.CONTACT_INFO,
      key: 'contactInfo',
      component: ContactInformation
    },
    {
      name: 'People',
      path: routes.PEOPLE,
      tabs: [routes.OFFICERS, routes.DIRECTORS, routes.CONTROL_PERSONS],
      key: 'people',
      component: People
    },
    {
      name: 'Service Providers',
      path: routes.SERVICE_PROVIDERS,
      key: 'serviceProviders',
      component: ServiceProviders
    },
    {
      name: 'Designated Contacts',
      path: routes.DESIGNATE_CONTACTS,
      tabs: [routes.PRIMARY_CONTACTS, routes.AUTHORIZED_USERS, routes.BILLING_CONTACTS],
      key: 'designatedContacts',
      component: DesignatedContacts
    },
    {
      name: 'Securities',
      path: routes.SECURITIES,
      key: 'securities',
      component: Securities
    },
    {
      name: 'Review and Submit',
      path: routes.REVIEW_SUBMIT,
      component: ReviewSubmit,
      static: true
    }
  ];
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldLabel from '../FieldLabel';
import cn from 'classnames';
import FieldError from '../FieldError';
import { TextBox as TextBoxDevEx } from 'devextreme-react';
import { errors } from '../../constants';
import styles from './TextBox.module.scss';

const TextBox = ({...props}) => {
  const [isFocus, setFocus] = useState(false);
  const isLabel = props.label || !props.isValid;
  const showRequired = props.isRequired && (!props.value || props.value.trim().length === 0);
  const errorText = props.value ? props.errorText || errors.INCOMPLETE_DATA_FIELD : errors.INCOMPLETE_DATA_FIELD;
  
  const handleFocusIn = e => {
    props.onFocusIn && props.onFocusIn();
    setFocus(true);
  };

  const handleFocusOut = e => {
    props.onFocusOut && props.onFocusOut();
    setFocus(false);
  };

  const handleValueChange = e => {
    if (!e.event) return;
    e.value = e.value && e.value.trim();
    typeof e.value === 'string' && props.onValueChanged && props.onValueChanged(e);
  };
  
  const handleOptionChanged = e => {
    if (e.name === 'text' && e.value) {
      e.value = e.value && e.value.trim();
      props.onOptionChanged && props.onOptionChanged(e);
    }
  };

  return <div>
    {isLabel && <div>
      {props.label && <FieldLabel text={props.label} size={props.labelSize} icon={props.labelIcon} moreInfo={props.moreInfo} />}
      {!props.hideErrorIcon  && !props.readOnly && (!props.isValid || showRequired) && <FieldError isFocus={isFocus} error={errorText} />}
    </div>}
    <TextBoxDevEx
      {...props}
      inputAttr={{
        autocomplete: 'no'
      }}
      className={cn({
        [styles.incomplete]: props.isIncomplete
      })}
      labelMode='hidden'
      isValid={(showRequired) ? props.value && props.value.trim().length > 0 ? true : false : props.isValid}
      onFocusIn={handleFocusIn}
      onFocusOut={handleFocusOut}
      onValueChanged={handleValueChange}
      onOptionChanged={handleOptionChanged}
      showClearButton={props.showClearButton && isFocus}>
        {props.children && props.children}
      </TextBoxDevEx>
  </div>;
};

TextBox.defaultProps = {
  isValid: true
};

TextBox.propTypes = {
  label: PropTypes.string,
  labelSize: PropTypes.number,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  showClearButton: PropTypes.bool,
  isIncomplete: PropTypes.bool,
  value: PropTypes.string,
  errorText: PropTypes.string,
  moreInfo: PropTypes.string,
  labelIcon: PropTypes.array,
  onValueChanged: PropTypes.func,
  onOptionChanged: PropTypes.func,
  onFocusIn: PropTypes.func,
  onFocusOut: PropTypes.func,
  hideErrorIcon: PropTypes.bool,
  children: PropTypes.node
};

export default TextBox;
import React from 'react';
import PropTypes from 'prop-types';

export default function OTCQX({
  size = 16, // or any default size of your choice
  color = '#FFFFFF' // or any color of your choice
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 182.2 36.4'
      fill={color}
      height={size} // added size here
    >
      <path d="M146.4,30l-2-1.7l6.7-10.4l-6.5-9.8l0,0C141,2.9,135-0.1,128.7,0c-10.8,0-18.9,8.1-18.9,18.2v0.1
        c0,10,8,18.1,18.8,18.1c2.7,0,5.4-0.5,7.8-1.6l-8-7c-5.2-0.1-8.7-4.5-8.7-9.5v-0.1c0-5,3.6-9.5,8.9-9.5s9,4.5,9,9.5v0.1
        c0,1.2-0.2,2.3-0.6,3.4l-4.8-4.3l-4.9,5.5l14.5,12.7h15.6l6.8-10.5l6.7,10.5h11.1l-12-17.9l11.5-17.1h-10.9l-6.2,9.9l-6.2-9.9h-11.2
        l11.5,17.2L150.5,30H146.4z"/>
      <polygon points="69.1,19.5 62.6,19.5 63.6,16.6 70.1,16.6 "/>
      <polygon points="75.5,19.5 69.1,19.5 70.1,16.6 76.5,16.6 "/>
      <polygon points="82,19.5 75.5,19.5 76.5,16.6 83,16.6 "/>
      <path d="M50.3,3.3v13.4h6.1V3.3h1.8c7.6,0,9.5,1.3,9.9,5.5H70V0.7H36.8v8.1h1.9c0.4-4.2,2.7-5.5,9.8-5.5L50.3,3.3z"/>
      <path d="M19.5,33.7c-6.1,0-12.1-5-12.9-14.2H0c0.6,10.2,8.9,16.9,18.9,16.9c10.1,0,18.2-6.6,18.9-16.9h-6.6
        C30.9,27.3,26.7,33.7,19.5,33.7z"/>
      <path d="M18.2,2.7c8.1,0,12.2,7.1,12.8,14.1h6.6C37,6.3,28.4,0,19,0C10.1,0,0.8,5.5,0,16.7h6.6
        C6.8,10.4,10,2.7,18.2,2.7z"/>
      <path d="M56.4,29v-9.5h-6.1V29c0,3.2-0.2,4.7-4.5,4.7h-1.6v2h18.2v-2h-1.6C57,33.7,56.4,32.7,56.4,29z"/>
      <path d="M89.3,2.7c8.4,0,11.3,4.9,11.9,8.5h1.9v-9C98.6,0.9,94,0.2,89.3,0c-12.5,0-18.6,7.4-20,14.7h6.5
        C76.8,7.5,81.2,2.7,89.3,2.7z"/>
      <path d="M89.4,36.3c4.6-0.1,9.2-0.8,13.7-2.2V25h-1.9c-1,5.4-4.6,8.6-11.9,8.6c-8.6,0-12.5-5.4-13.5-12.1h-6.5
        C70.6,28.7,76.4,36.3,89.4,36.3z"/>
    </svg>
  );
};

OTCQX.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from 'devextreme-react/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FieldLabel.module.scss';

const FieldLabel = ({ text, size, icon, moreInfo }) => {
  const [isFocus, setFocus] = useState(false);
  const labelId = `${text.replace(/\s+/g, '-').toLowerCase()}${Math.floor(Math.random()*90000) + 10000}`;
  const Component = moreInfo;
  const isComponent = typeof Component === 'function';

  const handleMouseToggle = () => {
    setFocus(!isFocus);
  };

  return <label className={cn(styles.container, styles[size])}>
    {icon && <FontAwesomeIcon className={cn(styles.icon, styles.green)} icon={icon} />}
    {text}
    {moreInfo && <>
      <FontAwesomeIcon
        id={labelId}
        className={cn(styles.icon, styles.moreInfo)}
        icon={['far', 'circle-info']}
        onMouseEnter={handleMouseToggle}
        onMouseLeave={handleMouseToggle} />
      <Tooltip
        target={`#${labelId}`}
        visible={isFocus}
        position='bottom'
        maxWidth='375px'
        hideOnOutsideClick={false}
      >
        <div>
          {isComponent ? <Component /> : moreInfo}
        </div>
      </Tooltip>
    </>}
  </label>;
};

FieldLabel.defaultProps = {
  size: 'small'
};

FieldLabel.propTypes = {
  text: PropTypes.string,
  moreInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  size: PropTypes.oneOf(['small', 'big']),
  icon: PropTypes.array
};

export default FieldLabel;

import _ from 'lodash';
import { compareField } from '../helper';
import { compareFieldTypes } from '../../constants';

export const getDesignatedContactsCompare = (currentProfile, updatedProfile) => {
    const json = [];
    const currentPeople = currentProfile.peopleList;
    const updatedPeople = updatedProfile.peopleList;

    const currentControlList = currentProfile.controlPersons;
    const updateControlList = updatedProfile.controlPersons;

    const createFieldObj = (listType, id, title, fieldName, fieldType) => {
        const currentDataList = listType === compareFieldTypes.PEOPLE ? currentPeople : currentControlList;
        const updateDataList = listType === compareFieldTypes.PEOPLE ? updatedPeople : updateControlList;

        let oldValue = _.get(currentDataList.find(p => p.id === id), fieldName);
        let newValue = _.get(updateDataList.find(p => p.id === id), fieldName);

        switch (fieldType) {
            case compareFieldTypes.BOOLEAN:
                oldValue = oldValue ? 'Yes' : 'No';
                newValue = newValue ? 'Yes' : 'No';
                break;
            default:
            break;
        };

        const obj = {
            fieldName: title,
            oldValue,
            newValue
        };

        return obj;
    };

    // New People
    updatedPeople && updatedPeople.forEach(item => {
        const updatedPerson = item;

        if (!updatedPerson || updatedPerson.isDeleted || (!updatedPerson.isPrimaryContact && !updatedPerson.isAuthorizedUser && !updatedPerson.isPrimaryBillingContact && !updatedPerson.isOtherBillingContact)) return;

        if (!currentPeople.find(current => current.id === updatedPerson.id)) {
            const personObj = {
                id: updatedPerson.id || null,
                header: `${updatedPerson.firstName} ${updatedPerson.lastName}`,
                isNew: true,
                fields: []
            };
            
            // Roles and titles
            if (updatedPerson.isPrimaryContact) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Primary Contact', 'isPrimaryContact', compareFieldTypes.BOOLEAN));
            if (updatedPerson.isAuthorizedUser) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Authorized User', 'isAuthorizedUser', compareFieldTypes.BOOLEAN));
            if (updatedPerson.isPrimaryBillingContact) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Primary Billing Contact', 'isPrimaryBillingContact', compareFieldTypes.BOOLEAN));
            if (updatedPerson.isOtherBillingContact) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Additional Billing Contact', 'isOtherBillingContact', compareFieldTypes.BOOLEAN));

            if (updatedPerson.isAccountPayable) {
                personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Accounts Payable', 'isAccountPayable', compareFieldTypes.BOOLEAN));
            } else {
                personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Company Affiliation', 'title1'));
                personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'First Name', 'firstName'));
                if (updatedPerson.middleName) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Middle Name', 'middleName'));
                personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Last Name', 'lastName'));
            }
            
            personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Phone Country Code', 'phoneCountryCode'));
            personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Phone', 'phone'));
            personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Email', 'email'));

            json.push(personObj);
        }
    });

    // Update PERSON
    currentPeople && currentPeople.forEach((item, i) => {
        const currentPerson = item;
        const updatedPerson = updatedPeople.find(person => person.id === currentPerson.id);
        
        if (!updatedPerson || updatedPerson.isDeleted) return;
        
        const isOfficerDirector = updatedPerson.isOfficer || updatedPerson.isDirector || updatedPerson.isChairPerson;

        if (!updatedPerson || updatedPerson.isDeleted || (!currentPerson.isPrimaryContact && !updatedPerson.isPrimaryContact
            && !currentPerson.isAuthorizedUser && !updatedPerson.isAuthorizedUser
            && !currentPerson.isPrimaryBillingContact && !updatedPerson.isPrimaryBillingContact
            && !currentPerson.isOtherBillingContact && !updatedPerson.isOtherBillingContact)) return;

        const personObj = {
            id: currentPerson.id || null,
            header: `${currentPerson.firstName} ${currentPerson.lastName}${updatedPerson.isOfficer ? ', ' + currentPerson.title1 : currentPerson.isDirector ? ', Director' : ''}`,
            fields: []
        };

        // Roles and titles
        compareField(currentPerson, updatedPerson, 'isPrimaryContact', 'Primary Contact', personObj, compareFieldTypes.BOOLEAN);
        compareField(currentPerson, updatedPerson, 'isAuthorizedUser', 'Authorized User', personObj, compareFieldTypes.BOOLEAN);
        compareField(currentPerson, updatedPerson, 'isPrimaryBillingContact', 'Primary Billing Contact', personObj, compareFieldTypes.BOOLEAN);
        compareField(currentPerson, updatedPerson, 'isOtherBillingContact', 'Additional Billing Contact', personObj, compareFieldTypes.BOOLEAN);

        if (!isOfficerDirector) {
            compareField(currentPerson, updatedPerson, 'firstName', 'First Name', personObj);
            compareField(currentPerson, updatedPerson, 'middleName', 'Middle Name', personObj);
            compareField(currentPerson, updatedPerson, 'lastName', 'Last Name', personObj);
            compareField(currentPerson, updatedPerson, 'phoneCountryCode', 'Phone Country Code', personObj);
            compareField(currentPerson, updatedPerson, 'phone', 'Phone', personObj);
            compareField(currentPerson, updatedPerson, 'email', 'Email', personObj);
        }

        if (personObj.fields && personObj.fields.length > 0) json.push(personObj);
    });

    // Delete PERSON
    currentPeople && currentPeople.forEach(item => {
        const currentPerson = item;
        const updatedPerson = updatedPeople.find(person => person.id === currentPerson.id && person.isDeleted);

        if (updatedPerson) {
            if (!currentPerson.isPrimaryContact && !updatedPerson.isPrimaryContact
                && !currentPerson.isAuthorizedUser && !updatedPerson.isAuthorizedUser
                && !currentPerson.isPrimaryBillingContact && !updatedPerson.isPrimaryBillingContact
                && !currentPerson.isOtherBillingContact && !updatedPerson.isOtherBillingContact) return;

            const personObj = {
                id: currentPerson.id || null,
                header: `${currentPerson.firstName} ${currentPerson.lastName}${currentPerson.isOfficer ? ', ' + currentPerson.title1 : currentPerson.isDirector ? ', Director' : ''}`,
                isDeleted: true,
                fields: []
            };

            if (currentPerson.isPrimaryContact !== updatedPerson.isPrimaryContact) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Primary Contact', 'isPrimaryContact', compareFieldTypes.BOOLEAN));
            if (currentPerson.isAuthorizedUser !== updatedPerson.isAuthorizedUser) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Authorized User', 'isAuthorizedUser', compareFieldTypes.BOOLEAN));
            if (currentPerson.isPrimaryBillingContact !== updatedPerson.isPrimaryBillingContact) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Primary Billing Contact', 'isPrimaryBillingContact', compareFieldTypes.BOOLEAN));
            if (currentPerson.isOtherBillingContact !== updatedPerson.isOtherBillingContact) personObj.fields.push(createFieldObj(compareFieldTypes.PEOPLE, updatedPerson.id, 'Additional Billing Contact', 'isOtherBillingContact', compareFieldTypes.BOOLEAN));

            json.push(personObj);
        }
    });

    // RETURN FINAL JSON COMPARISON
    return json;
};

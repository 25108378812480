import React, { useEffect, useContext, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'devextreme-react/tooltip';
import { withRouter } from '../../WithRouter';
import { getCurrentProfile } from '../../../api/cuf';
import { appStatuses, urls } from '../../../constants';
import { sections } from '../../../constants/sections';
import { compareProfiles } from '../../../utils/compare';
import { ProfileContext } from '../../../context/Profile.context';
import ApplicationDescription from '../../ApplicationDescription/ApplicationDescription';
import Button from '../../Button';
import Label from '../../Label';
import Title from '../../Title';
import TextArea from '../../TextArea';
import Loading from '../../Loading';
import styles from './ReviewSubmit.module.scss';
import { ReviewSection } from '../../ReviewSection';

const ReviewSubmit = ({ params: { symbol }, handleSubmit }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [totalSections] = useState(sections.length - 1);
  const [totalCompleted, setTotalCompleted] = useState(null);
  const [isCurrentLoaded, setCurrentLoaded] = useState(false);
  const [isCurrentError, setCurrentError] = useState(null);
  const [compareJson, setCompareJson] = useState(null);
  const [isTooltipFocus, setTooltipFocus] = useState(false);
  const profileData = profileState.profile;
  const currentData = profileState.currentProfile;
  const verifiedSections = profileData.verifiedSections;
  const isVerified = profileState.verifiedProfile;
  const isInProgress = profileData.status.id === appStatuses.IN_PROGRESS;
  const allSectionsVerified = totalCompleted === totalSections;
  const allVerifyActive = Object.keys(profileState.enableVerifiedSections).every(key => profileState.enableVerifiedSections[key] === true);
  const submitCta = allSectionsVerified ? 'Submit Verified Profile': 'Submit Update';
  const submitType = allSectionsVerified ? 'verify' : 'update';

  useEffect(() => {
    if (!currentData) {
      const currentParams = {
        symbol
      };

      getCurrentProfile(currentParams)
      .then(data => {
        if (data) setCurrentLoaded(true);

        dispatchProfile({
          type: 'SET_CURRENT_PROFILE',
          payload: data
        });
      })
      .catch(err => {
        const isStatus504 = err?.response?.data?.code === 504;
        setCurrentError(`Error loading data.${isStatus504 ? ' Please wait a few minutes and try again.' : ''}`);
      });
    } else {
      setCompareJson(compareProfiles(currentData, profileData));
      setCurrentLoaded(true);
    }
  }, [dispatchProfile, currentData, profileData, symbol]);

  useEffect(() => {
    if (verifiedSections) {
      let completedCount = 0;

      for (let property in verifiedSections) {
        if (property !== 'allVerified' && verifiedSections[property]) completedCount++;
      }

      setTotalCompleted(completedCount);
    }
  }, [verifiedSections]);

  const onValueChanged = e => {
    dispatchProfile({
      type: 'UPDATE_PROFILE',
      field: 'questionComment',
      payload: e.value
    });
  };

  const handleMouseToggle = () => {
    setTooltipFocus(!isTooltipFocus);
  };

  const onSubmitClick = _ => {
    let compareDiff = { ...compareJson };
    const authorizedUsers = profileData.peopleList.filter(person => person.isAuthorizedUser);
    const newAuthorizedUsers = compareJson.designatedContacts.filter(person => person.isNew
      && person.fields && person.fields.find(field => field.fieldName === 'Authorized User' && field.newValue === 'Yes'));

    if (!allVerifyActive && isVerified) compareDiff = { ...compareDiff, removeVerify: true };
    if (!authorizedUsers || authorizedUsers.length === 0) compareDiff = { ...compareDiff, noAuthorizedUsers: true };
    if (newAuthorizedUsers && newAuthorizedUsers.length >= 1) compareDiff = { ...compareDiff, backgroundcheckMissing: true };

    handleSubmit(submitType, compareDiff);
  };

  return <div>
    <ApplicationDescription title='Review and Submit' />
    <Loading loaded={isCurrentLoaded} error={isCurrentError}>
      <>
        <Label className='mbLg' isError={!allSectionsVerified}>
        {allSectionsVerified && <>
          <div className={styles.title}>
            <FontAwesomeIcon className={styles.icon} icon={['far', 'circle-check']} />
            <Title type='h3' title={`${totalCompleted} out of ${totalSections} sections have been verified`} />
          </div>
          <p>
            Submit the changes displayed on this page to verify company profile for {profileData.companyInfo.companyName}.
          </p>
        </>}
        {!allSectionsVerified && <>
          <div className={styles.title}>
            <FontAwesomeIcon className={cn(styles.icon, styles.error)} icon={['far', 'circle-xmark']} />
            <Title type='h3' title={`Only ${totalCompleted} out of ${totalSections} sections have been verified`} />
          </div>
          {(isVerified && !allVerifyActive) ? <p>
            Submit the changes displayed on this page to update your profile. Note that your profile will no longer be Verified because you are missing required information.
          </p> : <p>
            Submit the changes displayed on this page now OR verify remaining sections to Verify Profile.
          </p>}
        </>}
      </Label>
      <div>
        <div>
          {compareJson && sections.map(section => {
            let path = section.path;

            if (section.tabs) path = `${path}/${section.tabs[0]}`;
            if (section.static) return;

            return !section.static && <ReviewSection key={section.path} identifier={section.key} name={section.name} symbol={symbol} data={compareJson} path={path} isVerified={verifiedSections[section.key]} />;
          })}
        </div>
      </div>
      {isInProgress && <>
        <div className='mbXL'>
          <div>
            <Title className={styles.questionTitle} title='Questions & Comments' type='h2' />
            <FontAwesomeIcon
              id='moreInfo'
              className={cn(styles.icon, styles.moreInfo)}
              icon={['far', 'circle-info']}
              onMouseEnter={handleMouseToggle}
              onMouseLeave={handleMouseToggle} />
            <Tooltip
              target={'#moreInfo'}
              visible={isTooltipFocus}
              position='bottom'
              maxWidth='375px'
              hideOnOutsideClick={false}
            >
              Feel free to add any additional information that OTC Markets would need to process your Company Profile changes.
            </Tooltip>
          </div>
          <TextArea
            value={profileData.questionComment}
            onValueChanged={onValueChanged}
            placeholder='Enter text here.'
            maxLength={1500}
          />
        </div>
        <p>
          By submitting this profile update, I agree to the terms stated within OTC Markets Group's <a href={urls.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer'>Privacy Policy Statement</a> with regards to use of personal data.
        </p>
        <Button title={submitCta} fullWidth onClick={onSubmitClick} />
      </>}
      </>
    </Loading>
  </div>;
};

ReviewSubmit.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string
  }),
  handleSubmit: PropTypes.func
};

export default withRouter(ReviewSubmit);

import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal/es6';
import { withRouter } from '../../WithRouter';
import { ProfileContext } from '../../../context/Profile.context';
import { createID, sortList } from '../../../utils/helper';
import { isServiceProviderComplete } from '../../../utils/completes';
import { countries, entities, errors, routes, verifiedSectionKeys, urls, reportingStandards } from '../../../constants';
import { providers, firmSection, securityCounselSection } from '../../../constants/forms/serviceProviders';
import ServiceProviderSearch from '../../../containers/ServiceProviderSearch';
import CheckBoxField from '../../CheckBoxField';
import Controls from '../../Controls';
import EntityList from '../../EntityList';
import FormInstructions from '../../FormInstructions';
import VerifyBox from '../../VerifyBox';
import ApplicationDescription from '../../ApplicationDescription/ApplicationDescription';
import Button from '../../Button';
import FormSection from '../../FormSection';
import SlidePanel from '../../SlidePanel';
import Title from '../../Title';
import Label from '../../Label';
import { isCanada, isUSA } from '../../../utils/validations';

const providerEmptyObj = {
  name: null, 
  phone: null,
  website: null, 
  email: null, 
  address1: null,
  address2: null,
  address3: null,
  city: null,
  state: null,
  zip: null,
  isOwnTransferAgent: null,
  country: countries.US,
  countryId: countries.US,
  noOutsideCounsel: null,
  primaryFirstName: null,
  primaryMiddleName: null,
  primaryLastName: null,
  primaryContactTitle: null,
  primaryContactPhone: null,
  primaryContactEmail: null,
  verifiedSharesAuth: false
};

const ServiceProviders = ({ params: { symbol }, saveApplication }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [slidePanel, setSlidePanel] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerView, setProviderView] = useState(null);
  const [formSection, setFormSection] = useState(firmSection);
  const [validationError, setValidationError] = useState(false);
  const profileData = profileState.profile;
  const backupProfile = profileState.backupProfile;
  const isAdd = !providerView || !providerView.id;
  const isTransferAgent = selectedProvider && selectedProvider.typeId === '6' ? true : false;
  const isSecurityCounsels = selectedProvider && selectedProvider.typeId === '3' ? true : false;
  const isPreExisting = providerView && providerView.isPreExisting;
  const incorporationList = profileData && profileData.companyInfo && profileData.companyInfo.incorporationInfo;
  const sortedList = sortList(incorporationList, 'yearOfIncorporation', 'desc');
  const showAuthoirzeTA = isTransferAgent && !isPreExisting && sortedList && sortedList[0] && sortedList[0].countryOfIncorporation && (isUSA(sortedList[0].countryOfIncorporation) || isCanada(sortedList[0].countryOfIncorporation));
  const showActsOwnTA = isTransferAgent && profileData.transferAgents && !profileData.transferAgents.find(ta => ta.isOwnTransferAgent && !ta.isDeleted);
  const showNoSecCounsels = isSecurityCounsels && profileData.securityCounsels && !profileData.securityCounsels.find(sc => sc.noOutsideCounsel && !sc.isDeleted);
  const profileTier = profileData.companyTier.id;
  const edgarFilingStatus = profileData.edgarFilingStatus;
  const isInternationalReporting = edgarFilingStatus && edgarFilingStatus.includes(reportingStandards.INTERNATIONAL) ? true : false;
  const isOTCQX = profileTier === 1 || profileTier === 2 || profileTier === 5 || profileTier === 6;
  const isOTCQB = profileTier === 10;
  const isReadOnly = profileState.readOnly;
  const serviceProviderFormObj = providerView || providerEmptyObj;

  useEffect(() => {
    if (selectedProvider) {
      setSlidePanel(true);
      (selectedProvider.typeId === '6') ? setFormSection([]) : setFormSection(firmSection);
      (selectedProvider.typeId === '3') ? setFormSection(securityCounselSection) : setFormSection(firmSection);
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (!slidePanel) {
      setValidationError(false);
      setProviderView(null);  
      setSelectedProvider(null);
    }
  }, [slidePanel]);

  useEffect(() => {
    let isProviderChange = false;

    providers.forEach(provider => {
      if (!equal({ ...backupProfile[provider.key] }, { ...profileData[provider.key] })) isProviderChange = true;
      return;
    });
    
    if (isProviderChange) {
      saveApplication();
    }
  }, [backupProfile, profileData, saveApplication]);

  const onSearchChange = (value, field) => {
    const updatedObj = { ...providerView };
    updatedObj[field] = value;

    setProviderView(updatedObj);
  };

  const onOwnTaChange = e => {
    const updatedObj = { ...providerView };
    
    if (e.value) updatedObj.name = null;

    updatedObj.isOwnTransferAgent = e.value;

    setProviderView(updatedObj);
  };
  
  const onNoSecCounselChange = e => {
    if (!e.event) return;

    let updatedObj = { ...providerView };
    
    if (e.value) updatedObj = {
      ...providerEmptyObj
    };

    updatedObj.noOutsideCounsel = e.value;

    setProviderView(updatedObj);
  };

  const onCheckboxChanged = (value, field) => {
    const updatedObj = { ...providerView };
    updatedObj[field] = value;

    onValueChanged(updatedObj);
  };

  const onValueChanged = updatedObj => {
    if (providerView && providerView.countryId !== updatedObj.countryId) updatedObj.state = null;
    setProviderView(updatedObj);
  };

  const handleEdit = (data, provider) => {
    setSelectedProvider({ ...provider });
    setProviderView(data);
  };

  const handleRemove = (e, data, type) => {
    e.stopPropagation(); 
    const provider = type.key;
    onUpdate(profileData[provider].map(item => {
      if (item.id === data.id) item.isDeleted = true;
      return item;
    }), provider);
  };

  const onUpdate = (data, field) => {
    dispatchProfile({
      type: 'UPDATE_PROFILE',
      payload: data,
      field: field
    });
  };

  const handleUpdate = _ => {
    setValidationError(false);

    const provider = selectedProvider.key;

    if (!isServiceProviderComplete(providerView, selectedProvider.typeId)) {
      setValidationError(true);
      return;
    }

    let updateProvderList = profileData[provider];

    updateProvderList = updateProvderList.map(provider => {
      if (provider.id === providerView.id) return providerView;

      return provider;
    });

    onUpdate(updateProvderList, provider);

    setSlidePanel(false);
  };

  const handleAdd = _ => {
    setValidationError(false);

    const provider = selectedProvider.key;
    const newProvider = providerView;

    if (!isServiceProviderComplete(providerView, selectedProvider.typeId)) {
      setValidationError(true);
      return;
    }

    const id = createID(profileData[provider] ? profileData[provider] : []);
    newProvider.id = id;
    onUpdate([...profileData[provider] || [], newProvider], provider);
    setSlidePanel(false);
  };

  const entityTitle = provider => {
    if (provider.noOutsideCounsel) return 'Internal Securities Counsel';
    if (provider.isOwnTransferAgent) return 'Company Acts as its own Transfer Agent';
    const title = provider.name;

    if (!title || title.trim().length === 0) return entities.NO_NAME;
    return title;
  };

  const hasProvider = key => {
    const hasProvider = profileData[key].filter(data => !data.isDeleted).length > 0 ? true : false;
    const isTransferAgent = key === 'transferAgents';
    const isAuditor = key === 'auditors' && (isOTCQX || isOTCQB);
    const isSecurityCounsels = key === 'securityCounsels' && (isOTCQX || isOTCQB) && !isInternationalReporting;

    if (isTransferAgent || isAuditor || isSecurityCounsels) {
      return hasProvider;
    }

    return true;
  };

  const renderProviders = () => {
    return <div className='mbLg'>
      {providers.map((provider, i) => {
        const list = profileData[provider.key].filter(item => !item.isDeleted);

        const onRemoveClick = (e, data) => handleRemove(e, data, provider);

        return <EntityList
            key={i}
            className={i > 0 ? 'mtXL' : ''}
            title={provider.title}
            moreInfo={provider.moreInfo}
            footNote={provider.subTitle}
            list={list}
            confirmRemoveText={entities.CONFIRM_REMOVE_SP}
            showIncompleteList={provider.isRequired && (provider.key === 'transferAgents' ? !isInternationalReporting : true) && !hasProvider(provider.key)}
            incompleteMessage={`Incomplete Information. Need at least 1 complete ${provider.title}.`}
            icon={'user'}
            iconComplete={'userComplete'}
            entityTitle={entityTitle}
            validateComplete={data => isServiceProviderComplete(data, provider.typeId)}
            addTitle={(provider.max && list ? list.length < provider.max : true) && !isReadOnly && provider.addTitle ? provider.addTitle : undefined}
            readOnly={isReadOnly}
            handleEntityClick={d => handleEdit(d, provider)}
            handleEntityRemove={!isReadOnly && onRemoveClick}
            handleAddClick={(() => setSelectedProvider(provider))}
          />;
        })
      }
    </div>;
  };

  return <div>
    <ApplicationDescription title='Service Providers' />
    <FormInstructions />
    {renderProviders()}
    {!isReadOnly && <VerifyBox section={verifiedSectionKeys.SERVICE_PROVIDERS} />}
    <Link to={`${routes.PROFILE_PREFIX}/${symbol}/${routes.DESIGNATE_CONTACTS}/primary-contacts`}>
      <Button title='Proceed to Designated Contacts' fullWidth />
    </Link>
    <SlidePanel 
        isOpen={slidePanel}
        onClose={() => setSlidePanel(false)}
        title='Service Provider'>
        {selectedProvider && <div>
            <Title title={selectedProvider.title} type='h2' />
            {selectedProvider.key === 'transferAgents' && <p>
              Companies incorporated in the U.S. or Canada must retain a transfer agent that participates in the <a href={urls.TAVSP} target='_blank' rel='noopener noreferrer'>Transfer Agent Verified Shares Program</a>.
            </p>}
            {(showActsOwnTA && isAdd) && <CheckBoxField
              className='mtMed mbMed'
              text='The Company Acts as its own Transfer Agent.'
              value={providerView && providerView.isOwnTransferAgent}
              readOnly={isReadOnly}
              onValueChanged={onOwnTaChange}
            />}
            {isTransferAgent && <ServiceProviderSearch
              typeId={selectedProvider.typeId}
              isRequired={providerView && !providerView.isOwnTransferAgent}
              notAvailable={providerView && providerView.isOwnTransferAgent}
              readOnly={isReadOnly || selectedProvider.isPreExisting}
              value={providerView && providerView.name || null}
              disabled={providerView && providerView.isOwnTransferAgent}
              onValueChanged={value => onSearchChange(value, 'name')}
            />}
            {(showNoSecCounsels && isAdd) && <CheckBoxField
              className='mtMed mbMed'
              text='The Company does not currently have an outside securities counsel.'
              value={providerView && providerView.noOutsideCounsel}
              readOnly={isReadOnly}
              onValueChanged={onNoSecCounselChange}
            />}
            {(isSecurityCounsels && providerView && providerView.noOutsideCounsel) && <Label title='Internal General Counsel should be listed as an Officer/Contact in the People section.' />}
            {!isTransferAgent && formSection.map((section, i) => <FormSection key={i} section={section} data={{ ...serviceProviderFormObj, companyTier: { ...profileData.companyTier }, edgarFilingStatus}} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
        </div>}
        {showAuthoirzeTA && <Label className='mtLg'>
          <CheckBoxField
              text={`The Company has authorized this transfer agent to provide to OTC Markets Group, upon its request, information related to the Company's securities, including, but not limited to, shares authorized, shares issued and outstanding, and share issuance history.`}
              value={providerView && providerView.isAuthorized}
              readOnly={isReadOnly}
              onValueChanged={e => onCheckboxChanged(e.value, 'isAuthorized')} />
        </Label>}
        {validationError && <Label className='mtLg' isError title={errors.INCOMPLETE_DATA} />}
        {!isReadOnly && <Controls
            className='mtXXL'
            cancelText='Cancel'
            submitText={isAdd ? 'Add' : 'Update'}
            onCancelClick={() => setSlidePanel(false)}
            onSubmitClick={isAdd ? handleAdd : handleUpdate}
        />}
    </SlidePanel>
  </div>;
};

ServiceProviders.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string
  }),
  saveApplication: PropTypes.func
};

export default withRouter(ServiceProviders);

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NotificationsContext } from '../../context/Notifications.context';
import { ProfileContext } from '../../context/Profile.context';
import { Tooltip } from 'devextreme-react/tooltip';
import { isTierOTCQB, isTierOTCQX, isTierPink } from '../../utils/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OTCQXLogo from '../../svgs/logos/OTCQX';
import OTCQBLogo from '../../svgs/logos/OTCQB';
import OTCPinkLogo from '../../svgs/logos/OTCPink';
import GATEWAY_DEFAULT from '../../svgs/logos/GATEWAY_DEFAULT';
import { globalTitles } from '../../constants';
import HelpSection from '../HelpSection';
import SlidePanel from '../SlidePanel';
import styles from './GlobalActions.module.scss';
import getConfig from '../../config';
const { REACT_APP_OTC_BASE_URL } = getConfig();

const APP_TYPE = 'app-type';
const SAVE = 'save';
const HELP = 'help';

const GlobalActions = ({ user, onSaveClick, symbol }) => {
  const [slidePanel, setSlidePanel] = useState(false);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [companyLogoError, setCompanyLogoError] = useState(false);
  const [isHover, setHover] = useState(null);
  const profile = profileState.profile;
  const profileTier = profile.companyTier;
  const companyName = profile.companyInfo.companyName;
  const isOTCQX = isTierOTCQX(profileTier);
  const isOTCQB = isTierOTCQB(profileTier);
  const isPink = isTierPink(profileTier);
  const isDefault = !isOTCQX && !isOTCQB && !isPink;
  const hasSaveNotification = notificationState.notifications.find(n => n.type === 'save');
  const isReadOnly = profileState.readOnly;
  const disableSave = hasSaveNotification || isReadOnly;

  const handleSaveClick = () => {
    !disableSave && onSaveClick();
  };

  const handleIncompleteClick = () => {
    if (profileState.showIncompletes) {
      hasIncompleteNotification && dispatchNotification({
        type: 'REMOVE_NOTIFICATION',
        id: hasIncompleteNotification.id
      });

      dispatch({
        type: 'SET_INCOMPLETES',
        payload: false
      });
    } else {
      dispatchNotification({
        type: 'ADD_NOTIFICATION',
        payload: {
          id: new Date().getTime(),
          description: 'You are now able to view all Incomplete Data Fields',
          type: 'incompletes'
        }
      });

      dispatch({
        type: 'SET_INCOMPLETES',
        payload: true
      });
    }
  };

  return (
    <div className={styles.container}>
      {companyLogoError && <div id={APP_TYPE} className={cn(styles.item, styles.application, {
        [styles.green]: isOTCQX,
        [styles.orange]: isOTCQB,
        [styles.pink]: isPink,
        [styles.default]: isDefault
      })}
        onMouseEnter={() => setHover(APP_TYPE)}
        onMouseLeave={() => setHover(null)}>
          {isOTCQX && <OTCQXLogo />}
          {isOTCQB && <OTCQBLogo />}
          {isPink && <OTCPinkLogo size={12} />}
          {isDefault && <GATEWAY_DEFAULT />}
        <Tooltip
          target={`#${APP_TYPE}`}
          visible={isHover && isHover === APP_TYPE}
          position='left'
          hideOnOutsideClick={false}
        >
          {companyName}
        </Tooltip>
      </div>}
      {!companyLogoError && <div id={APP_TYPE} className={cn(styles.item)}
        onMouseEnter={() => setHover(APP_TYPE)}
        onMouseLeave={() => setHover(null)}>
          <img className={styles.logo} src={`${REACT_APP_OTC_BASE_URL}/company-otcapi/company/logo/${symbol}`} alt={companyName} onError={()=> setCompanyLogoError(true)} />
        <Tooltip
          target={`#${APP_TYPE}`}
          visible={isHover && isHover === APP_TYPE}
          position='left'
          hideOnOutsideClick={false}
        >
          {companyName}
        </Tooltip>
      </div>}
      <div id={SAVE} className={cn(styles.item, {
        [styles.inactive]: disableSave
      })}
        onClick={handleSaveClick}
        onMouseEnter={() => setHover(SAVE)}
        onMouseLeave={() => setHover(null)}>
        <FontAwesomeIcon icon={['fal', 'save']} size='xl' />
        <Tooltip
          target={`#${SAVE}`}
          visible={isHover && isHover === SAVE}
          position='left'
          hideOnOutsideClick={false}
        >
          Save Profile
        </Tooltip>
      </div>
      <div id={HELP} className={styles.item}
        onClick={() => setSlidePanel(HELP)}
        onMouseEnter={() => setHover(HELP)}
        onMouseLeave={() => setHover(null)}>
        <FontAwesomeIcon icon={['fal', 'question-circle']} size='xl' />
        <Tooltip
          target={`#${HELP}`}
          visible={isHover && isHover === HELP}
          position='left'
          hideOnOutsideClick={false}
        >
          Help Section
        </Tooltip>
      </div>
      <SlidePanel 
        isOpen={slidePanel ? true : false}
        onClose={() => setSlidePanel(false)}
        title={globalTitles.HELP}>
          <HelpSection userEmail={user.email} userFullName={user.fullName} companyName={companyName} />
      </SlidePanel>
    </div>
  );
};

GlobalActions.propTypes = {
  user: PropTypes.object,
  onSaveClick: PropTypes.func
};

export default GlobalActions;

import _ from 'lodash';
import { getCountryName, getStateName } from '../locale';
import { compareField } from '../helper';
import { compareFieldTypes } from '../../constants';

export const getContactInfoCompare = (currentProfile, updatedProfile) => {
    const json = [];
    const currentContactInfo = currentProfile.contactInfo;
    const updatedContactInfo = updatedProfile.contactInfo;

    const createFieldObj = (title, fieldName, fieldType) => {
        let oldValue = _.get(currentContactInfo, fieldName);
        let newValue = _.get(updatedContactInfo, fieldName);

        switch (fieldType) {
            case compareFieldTypes.BOOLEAN:
                oldValue = oldValue ? 'Yes' : 'No';
                newValue = newValue ? 'Yes' : 'No';
                break;
            case compareFieldTypes.COUNTRY:
                oldValue = getCountryName(oldValue);
                newValue = getCountryName(newValue);
                break;
            default:
            break;
        };

        const obj = {
            fieldName: title,
            oldValue,
            newValue
        };

        return obj;
    };

    const createStateFieldObj = (title, stateFieldName, countryFieldName) => {
        const currentState = _.get(currentContactInfo, stateFieldName);
        const updatedState = _.get(updatedContactInfo, stateFieldName);
        const currentCountry = _.get(currentContactInfo, countryFieldName);
        const updatedCountry = _.get(updatedContactInfo, countryFieldName);

        const oldValue = getStateName(currentState, currentCountry);
        const newValue = getStateName(updatedState, updatedCountry);

        const obj = {
            fieldName: title,
            oldValue,
            newValue
        };

        return obj;
    };

    const addressTypes = [
        {
            title: 'Principal Place of Business',
            key: 'address'
        },
        {
            title: 'Billing Address',
            key: 'billingAddress'
        },
        {
            title: 'Principal Executive Office',
            key: 'executiveAddress'
        }
    ];

    // *Address - Principal

    addressTypes.forEach(address => {
        const ADDRESS_FIELD = address.key;
        const addressInfo = {
            header: address.title,
            fields: []
        };
    
        if (!currentContactInfo[ADDRESS_FIELD] && !updatedContactInfo[ADDRESS_FIELD]) return;
        
        const hasPropertyChange = field => {
            const hasCurrentField = currentContactInfo[ADDRESS_FIELD] && currentContactInfo[ADDRESS_FIELD].hasOwnProperty(field) ? true : false;
            const hasUpdatedField = updatedContactInfo[ADDRESS_FIELD] && updatedContactInfo[ADDRESS_FIELD].hasOwnProperty(field) ? true : false;

            if (!hasCurrentField && !hasUpdatedField) return false;

            if (hasCurrentField && hasUpdatedField && currentContactInfo[ADDRESS_FIELD][field] !== updatedContactInfo[ADDRESS_FIELD][field]
                || hasCurrentField && !hasUpdatedField
                || !hasCurrentField && hasUpdatedField) return true;
        };

        // Same as logic
        if (address.key === 'billingAddress' && currentContactInfo.address.sameBillingAddress !== updatedContactInfo.address.sameBillingAddress) {
            addressInfo.fields.push(createFieldObj('Same as Principal Place of Business', 'address.sameBillingAddress', compareFieldTypes.BOOLEAN));
            json.push(addressInfo);

            return;
        }
        
        if (address.key === 'executiveAddress' && currentContactInfo.address.sameExecAddress !== updatedContactInfo.address.sameExecAddress) {
            addressInfo.fields.push(createFieldObj('Same as Principal Place of Business', 'address.sameExecAddress', compareFieldTypes.BOOLEAN));
            json.push(addressInfo);

            return;
        }
        
        compareField(currentContactInfo[ADDRESS_FIELD], updatedContactInfo[ADDRESS_FIELD], 'address1', 'Address 1', addressInfo);
        compareField(currentContactInfo[ADDRESS_FIELD], updatedContactInfo[ADDRESS_FIELD], 'address2', 'Address 2', addressInfo);
        compareField(currentContactInfo[ADDRESS_FIELD], updatedContactInfo[ADDRESS_FIELD], 'address3', 'Address 3', addressInfo);
        compareField(currentContactInfo[ADDRESS_FIELD], updatedContactInfo[ADDRESS_FIELD], 'countryId', 'Country', addressInfo, compareFieldTypes.COUNTRY);
        compareField(currentContactInfo[ADDRESS_FIELD], updatedContactInfo[ADDRESS_FIELD], 'city', 'City', addressInfo);
        if (hasPropertyChange('state')) addressInfo.fields.push(createStateFieldObj('State', `${ADDRESS_FIELD}.state`, `${ADDRESS_FIELD}.countryId`));
        compareField(currentContactInfo[ADDRESS_FIELD], updatedContactInfo[ADDRESS_FIELD], 'zip', 'Postal Code', addressInfo);
        
        
        if (addressInfo.fields && addressInfo.fields.length > 0) json.push(addressInfo);
    });
    
    // *Contact/Basic Info
    const basicInfo = {
        header: 'Contact Information',
        fields: []
    };

    compareField(currentContactInfo.basicContactInfo, updatedContactInfo.basicContactInfo, 'phoneCountryCode', 'Phone Country Code', basicInfo);
    compareField(currentContactInfo.basicContactInfo, updatedContactInfo.basicContactInfo, 'phone', 'Phone', basicInfo);
    compareField(currentContactInfo.basicContactInfo, updatedContactInfo.basicContactInfo, 'email', 'Email', basicInfo);
    compareField(currentContactInfo.basicContactInfo, updatedContactInfo.basicContactInfo, 'website', 'Website', basicInfo);

    compareField(currentContactInfo.socialMedia, updatedContactInfo.socialMedia, 'linkedInId', 'LinkedIn', basicInfo);
    compareField(currentContactInfo.socialMedia, updatedContactInfo.socialMedia, 'instagramId', 'Instagram', basicInfo);
    compareField(currentContactInfo.socialMedia, updatedContactInfo.socialMedia, 'twitterId', 'X/Twitter', basicInfo);
    compareField(currentContactInfo.socialMedia, updatedContactInfo.socialMedia, 'facebookId', 'Facebook', basicInfo);

    if (basicInfo.fields && basicInfo.fields.length > 0) json.push(basicInfo);

    // RETURN FINAL JSON COMPARISON
    return json;
};

import { errors, fieldTypes, reportingStandards } from '../index';
import { getCodes } from '../../utils/locale';
import { isTierOTCQB, isTierOTCQX } from '../../utils/helper';
import { isEmail, isWebsite } from '../../utils/validations';

const localeCodes = getCodes();
const countryList = localeCodes && localeCodes.countries ? localeCodes.countries : [];
const stateList = localeCodes && localeCodes.states ? localeCodes.states : [];

const SUBTITLE = 'Provide contact information below.';

export const providers = [
    {
        title: 'Transfer Agent',
        key: 'transferAgents',
        typeId: '6',
        addTitle: 'Add Transfer Agent',
        moreInfo: 'International companies may list their Registrar',
        isRequired: data => data.edgarFilingStatus && !data.edgarFilingStatus.includes(reportingStandards.INTERNATIONAL)
    },
    {
        title: 'Accountant/Auditor',
        typeId: '5',
        key: 'auditors',
        addTitle: 'Add Accountant/Auditor',
        isRequired: data => data.companyTier && (isTierOTCQB(data.companyTier) || isTierOTCQX(data.companyTier))
    },
    {
        title: 'Securities Counsel',
        typeId: '3',
        addTitle: 'Add Securities Counsel',
        key: 'securityCounsels',
        isRequired: data => data.companyTier && (isTierOTCQB(data.companyTier) || isTierOTCQX(data.companyTier)) && data.edgarFilingStatus && !data.edgarFilingStatus.includes(reportingStandards.INTERNATIONAL)
      },
      {
        title: 'Investor Relations Firm',
        typeId: '2',
        addTitle: 'Add Investor Relations Firm',
        key: 'investorRelations',
      },
      {
        title: 'Investment Bank',
        typeId: '1',
        addTitle: 'Add Investment Bank',
        key: 'investmentBanks',
      }
];

const firmFields = [
  {
    type: fieldTypes.TEXTBOX,
    label: 'Name of Firm',
    key: 'name',
    placeholder: 'Name of Firm',
    colSpan: 6,
    isRequired: true,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.PHONE,
    label: 'Firm Phone Number (optional)',
    key: 'phone',
    key2: 'phoneCountryCode',
    colSpan: 3,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.TEXTBOX,
    label: 'Firm Email (optional)',
    key: 'email',
    placeholder: 'email@mail.com',
    colSpan: 3,
    isValid: data => isEmail(data.email),
    invalidText: errors.INVALID_EMAIL,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.TEXTBOX,
    label: 'Firm Website (optional)',
    key: 'website',
    colSpan: 6,
    isValid: data => isWebsite(data.website),
    invalidText: errors.INVALID_WEBSITE,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.TEXTBOX,
    label: 'Firm Address',
    key: 'address1',
    placeholder: 'Address 1',
    colSpan: 6,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.TEXTBOX,
    key: 'address2',
    placeholder: 'Address 2',
    colSpan: 6,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.TEXTBOX,
    key: 'address3',
    placeholder: 'Address 3',
    colSpan: 6,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.SELECT,
    label: 'Country',
    key: 'countryId',
    valueExpr: 'id',
    displayExpr: 'name',
    options: countryList,
    colSpan: 3,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.TEXTBOX,
    label: 'City',
    key: 'city',
    colSpan: 6,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.SELECT,
    label: 'State/Province',
    key: 'state',
    valueExpr: 'id',
    displayExpr: 'name',
    options: data => {
      const country = countryList.find(c => c.id === data.countryId);
      if (!country) return [];
      return stateList.filter(option => option.countryId === country.id);
  },
    colSpan: 2,
    disabledBy: 'noOutsideCounsel'
  },
  {
    type: fieldTypes.TEXTBOX,
    label: 'Postal Code',
    key: 'zip',
    placeholder: 'Postal Code',
    colSpan: 2,
    disabledBy: 'noOutsideCounsel'
  }
];

export const firmSection = [
  {
    subTitle: SUBTITLE,
    fields: firmFields
  }
];

export const securityCounselSection = [
  {
    subTitle: SUBTITLE,
    fields: [
      ...firmFields
    ]
  }
];

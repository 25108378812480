import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DialogBox from '../DialogBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Entity.module.scss';

const Entity = ({ className, title, subTitle, subTitleHighlight, confirmRemoveText, icon, onClick, size, showIncompletes, handleRemove, handleCustomRemove, hideClose, isAdd, isEmpty, isInactive, isComplete }) => {
  const [confirmRemove, setConfirmRemove] = useState(false);
  const singularTitle = typeof title === 'string';
  const multipleTitles = typeof title === 'object';

  const onRemoveClick = e => {
    e.stopPropagation();
    setConfirmRemove(true);
  };

  const hideDialog = e => {
    e.stopPropagation();
    setConfirmRemove(false);
  };

  const onSubmit = e => {
    setConfirmRemove(false);
    handleRemove(e);
  };

  const renderTitle = titleText => {
    return <div className={styles.verticalAlign}>
      {icon === 'incomplete' && <span className={cn(styles.icon, styles.incompleteIcon)}>
        <FontAwesomeIcon
          icon={['fal', 'exclamation-triangle']} />
      </span>}
      {icon === 'user' && <span className={styles.icon}>
        <FontAwesomeIcon icon={['fal', 'user']} />
      </span>}
      {icon === 'userComplete' && <span className={styles.iconCheck}>
        <FontAwesomeIcon icon={['fal', 'user-check']} />
      </span>}
      {icon === 'fileCert' && <span className={cn(styles.icon, { [styles.complete]: isComplete })}>
        <FontAwesomeIcon icon={['fal', 'file-certificate']} />
      </span>}
      {icon === 'file' && <span className={styles.icon}>
        <FontAwesomeIcon icon={['fal', 'file']} />
      </span>}
      {icon === 'fileComplete' && <span className={cn(styles.icon, { [styles.complete]: isComplete })}>
        <FontAwesomeIcon icon={['fal', 'file-check']} />
      </span>}
      {isAdd && <span className={styles.addIcon}>
        <FontAwesomeIcon icon={['far', 'plus-circle']} />
      </span>}
      {icon === 'check' && <span className={cn(styles.icon, { [styles.complete]: isComplete })}>
        <FontAwesomeIcon icon={['far', 'check']} />
      </span>}
      {icon === 'location' && <span className={cn(styles.icon, { [styles.complete]: isComplete })}>
        <FontAwesomeIcon icon={['fal', 'location-dot']} />
      </span>}
      {icon === 'userLock' && <span className={cn(styles.icon, { [styles.complete]: isComplete })}>
        <FontAwesomeIcon icon={['fal', 'user-lock']} />
      </span>}
      <span className={styles.title}>
        {titleText}
      </span>
    </div>;
  };

  return <div className={cn(className, styles.container, styles[size], {
    [styles.add]: isAdd,
    [styles.complete]: isComplete,
    [styles.empty]: isEmpty,
    [styles.inactive]: isInactive,
    [styles.incomplete]: !isComplete
  })} onClick={(onClick && !isInactive) ? onClick : undefined}>
    {singularTitle && renderTitle(title)}
    {multipleTitles && <div>
      {title.map(t => renderTitle(t))}
    </div>}
    <div className={styles.centerAlign}>
      {subTitle && <span className={cn(styles.subTitle, {
        [styles.complete]: isComplete,
        [styles.highlight]: subTitleHighlight
      })}>{subTitle}</span>}
      {((handleRemove || handleCustomRemove) && !hideClose) && <span className={styles.remove} onClick={!isInactive ? handleCustomRemove ? handleCustomRemove : onRemoveClick : undefined} onMouseDown={e => e.stopPropagation()}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </span>}
    </div>
    {confirmRemove && <DialogBox
      className={styles.dialog}
      subTitle={confirmRemoveText || 'Are you sure you want to delete?'}
      cancelText='Cancel'
      submitText='Yes'
      isHorizontal
      size={size}
      onCancelClick={e => hideDialog(e)}
      onSubmitClick={e => onSubmit(e)} />}
  </div>;
};

Entity.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  subTitle: PropTypes.string,
  showIncompletes: PropTypes.bool,
  confirmRemoveText: PropTypes.string,
  icon: PropTypes.oneOf(['user', 'userComplete', 'userLock','fileCert', 'file', 'fileComplete', 'incomplete', 'check', 'location']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  handleRemove: PropTypes.func,
  handleCustomRemove: PropTypes.func,
  isAdd: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isInactive: PropTypes.bool,
  isComplete: PropTypes.bool,
  subTitleHighlight: PropTypes.bool,
  hideClose: PropTypes.bool,
  onClick: PropTypes.func
};

Entity.defaultProps = {
  size: 'large',
  isComplete: true
};

export default Entity;

import React, { useContext } from 'react';
import cn from 'classnames';
import { ProfileContext } from '../../context/Profile.context';
import { emails, routes } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileHeader from '../ProfileHeader';
import Title from '../Title';
import styles from './UpdateProfileSubmitted.module.scss';

const UpdateProfileSubmitted = () => {
    const [profileState] = useContext(ProfileContext);
    const profile = profileState.profile;
    const companyName = profile && profile.companyInfo.companyName;

    return <div className={styles.container}>
        {profile && <ProfileHeader inline company={companyName} isVerified={profileState.isVerified} verifiedDate={profileState.verifiedAsOf} />}
        <div>
            <Title className={cn('mtXL', styles.title)} title='Company Update Submitted!' />
            <p>
                Please allow OTC Markets 1-2 business days to review and process this form for {companyName}
            </p>
            <p>
                If you have any questions, please reach out to <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a> or call 212-896-4420.
            </p>
            <div className='mtXL'>
                <a className={styles.cta} href={routes.DASHBOARD}>
                    Go to Manage Company Profile <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
                </a>
            </div>
        </div>
    </div>;
};

export default UpdateProfileSubmitted;

import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProfileContextProvider } from './context/Profile.context';
import { NotificationsContextProvider } from './context/Notifications.context';
import { ThemeProvider } from './context/Theme.context';
import { routes } from './constants';
import DashboardPage from './containers/DashboardPage';
import FormPage from './containers/FormPage';
import CompanyLogoPage from './containers/CompanyLogoPage';
import Layout from './components/Layout';
import './styles/otc-theme.scss';

const App = () => {
  const defaultRoutes = [
    {
      path: routes.DASHBOARD,
      element: <DashboardPage />
    }, 
    {
      path: routes.COMPANY_LOGO,
      element: <CompanyLogoPage />
    }, 
    {
      path: routes.PROFILE_FORM,
      element: <FormPage />
    }
  ];

  return (
    <div className='otc-theme'>
      <ThemeProvider>
        <NotificationsContextProvider>
          <ProfileContextProvider>
            <Layout>
              <Routes>
                {
                  defaultRoutes.map( r => {
                    return (<Route key={r.path} path={r.path} element={r.element} />);
                  })
                }
              </Routes>
            </Layout>
          </ProfileContextProvider>
        </NotificationsContextProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;

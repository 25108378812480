import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';
export const ThemeContext = createContext();

const initialState = {
  lock: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOCK':
      return {
        ...state,
        lock: true
      };
    case 'SET_UNLOCK':
      return {
        ...state,
        lock: false
      };
    default:
      throw new Error();
  }
};

export function ThemeProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ThemeContext.Provider value={{ state: state, dispatch: dispatch }}>{props.children}</ThemeContext.Provider>;
}

ThemeProvider.propTypes = {
    children: PropTypes.node
};

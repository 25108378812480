import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ProfileContext } from '../../../context/Profile.context';
import { entities, errors, corporateEntityObj } from '../../../constants';
import { checkBeneficialOwners, isBeneficialOwnerComplete, isCorporateEntityComplete } from '../../../utils/completes';
import { isBoolean } from '../../../utils/validations';
import { createID, personEntityTitle } from '../../../utils/helper';
import Controls from '../../Controls';
import Entity from '../../Entity';
import EntityList from '../../EntityList';
import FieldError from '../../FieldError';
import Label from '../../Label';
import SlidePanel from '../../SlidePanel';
import TextBox from '../../TextBox';
import Title from '../../Title';
import QuestionCard from '../../QuestionCard';
import styles from './People.module.scss';

const OWNER = 'owner';
const CORP_ENTITY_VIEW = 'addCorpEntity';
const ADD_NEW_PARTICIPANTS_VIEW = 'addNewOwner';
const EDIT_NEW_PARTICIPANTS_VIEW = 'editNewOwner';

const CorporateEntities = ({ noControlPerson, onControlPersonAdd, onControlPersonUpdate, onControlPersonListUpdate, onControlPersonRemove }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [person, setPerson] = useState(corporateEntityObj);
  const [slidePanel, setSlidePanel] = useState(false);
  const [slideView, setSlideView] = useState(CORP_ENTITY_VIEW);
  const [corporateEntityView, setCorporateEntityView] = useState(corporateEntityObj);
  const [individualOwnerFocus, setIndividualOwnerFocus] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [controlPersons, setStaticControlPersons] = useState(JSON.parse(JSON.stringify(profileState.profile.controlPersons)));
  const staticControlPersonList = profileState.profile.controlPersons;
  const [controlPersonsList, setControlPersonsList] = useState(controlPersons || []);
  const corporateEntityList = staticControlPersonList && staticControlPersonList.filter((person, index) => (person.corpEntity && !person.hasBeneficialOwners && !person.isDeleted)
    || (person.corpEntity && person.hasBeneficialOwners && staticControlPersonList.findIndex((item) => item.corpEntity === person.corpEntity && !item.isDeleted) === index));
  const corporateEntityViewTitle = corporateEntityView && corporateEntityView.corpEntity || '';
  const owners = controlPersonsList.filter(obj => obj.corpEntity && !obj.isDeleted && obj.firstName && obj.lastName && obj.corpEntity === corporateEntityView.corpEntity);
  const hasOwners = owners && owners.length > 0;
  const addTitle = 'Add Corporate Entity (Control Person)';
  const isAdd = !corporateEntityView.id;
  const isReadOnly = profileState.readOnly;
  const showIncompletes = false;

  const IndividualOwner = ({ onClick }) => {
    const list = [...owners];
    const isMaxOwners = list && list.length >= 3;
    
    const onRemove = (e, id) => {
      e.stopPropagation();
      const updateControlPersonList = [...controlPersonsList];
      const personUpdate = updateControlPersonList.find(item => item.id && item.id === id);
      personUpdate.isDeleted = true;

      setControlPersonsList(updateControlPersonList);
    };

    const onEdit = (id) => {
      const person = controlPersonsList.find(person => person.id === id);
      setPerson(person);
      setSlideView(EDIT_NEW_PARTICIPANTS_VIEW);
    };

    return (
      <div className='mtMed'
        onMouseEnter={() => setIndividualOwnerFocus(true)}
        onMouseLeave={() => setIndividualOwnerFocus(false)}>
        <div>
          <Title className={styles.inline} title='Individual Owner' type='h3' />
          {(!list || list.length === 0) && <FieldError error={errors.INCOMPLETE_DATA_FIELD} isFocus={individualOwnerFocus} />}
        </div>
        {hasOwners && <div className={styles.entityList}>
          {owners.map(person => {
            const isComplete = isBeneficialOwnerComplete(person);
            const name = personEntityTitle(person, false);
            const id = person.id;
            if (person) {
              return <Entity
                key={`${id}-${name}`}
                title={name}
                size='small'
                showIncompletes={showIncompletes && !isComplete}
                isComplete={isComplete}
                subTitle={isReadOnly ? 'View' : isComplete ? 'Complete' : 'Enter Information'}
                subTitleHighlight={!isComplete}
                onClick={() => onEdit(id)}
                confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
                handleRemove={(!isReadOnly) && (e => onRemove(e, id))} />;
            };
          })}
        </div>}
        {(!isMaxOwners && !isReadOnly) && <Entity
          title='Add Beneficial Owner'
          isAdd
          size='small'
          onClick={onClick} />}
        {((!list || list.length === 0) && isReadOnly) && <Entity
          title='No Beneficial Owner'
          isEmpty
          size='small' />}
      </div>
    );
  };

  IndividualOwner.propTypes = {
    onClick: PropTypes.func
  };

  const question = {
    title: 'Control/Ownership',
    subTitle: 'Is there an individual who beneficially owns or controls this entity?',
    fieldName: 'hasBeneficialOwners',
    falseDetails: [
      {
        desc: 'Please explain why an individual cannot be identified under this corporate entity.',
        fieldName: 'reasonForNone'
      }
    ],
    trueDetails: {
      component: () => <IndividualOwner
        onClick={() => {
          setPerson(corporateEntityObj);
          setSlideView(ADD_NEW_PARTICIPANTS_VIEW);
        }} />
    }
  };

  useEffect(() => {
    setValidationError(false);
  }, [slideView]);
  
  useEffect(() => {
    if (!slidePanel) {
      setControlPersonsList(controlPersons);
      setSlideView(CORP_ENTITY_VIEW);
      setPerson(corporateEntityObj);
      setCorporateEntityView(corporateEntityObj);
    }
  }, [slidePanel]);
  
  useEffect(() => {
    const newList = JSON.parse(JSON.stringify(profileState.profile.controlPersons));
    setStaticControlPersons(newList);
    setControlPersonsList(newList);
  }, [profileState.profile.controlPersons]);

  const onIndividualValueChanged = (type, value, field) => {
    if (type === OWNER) {
      setPerson(prevState => {
        return {
          ...prevState,
          [field]: value
        };
      });
    }
  };

  const onValueChanged = (value, field) => {
    const updatedEntity = corporateEntityView;
    updatedEntity[field] = value;

    if (field === 'hasBeneficialOwners' && value === true) updatedEntity.reasonForNone = null;
    if (field === 'hasBeneficialOwners' && value === false) updatedEntity.owners = [];

    setCorporateEntityView({ ...updatedEntity });
  };

  const onCorpEntityNameChanged = e => {
    setCorporateEntityView({ ...corporateEntityView, corpEntity: !corporateEntityView.corpEntity ? e.value : corporateEntityView.corpEntity, newCorpEntity: e.value });
  };

  const handleOwnerAdd = _ => {
    let newPerson = person;

    setValidationError(false);

    if (!checkBeneficialOwners(newPerson)) {
      setValidationError(true);
      return;
    };

    if (!person.id) {
      const id = createID(controlPersonsList);
      newPerson.id = id;
    }

    newPerson = {
      ...corporateEntityView,
      id: person.id,
      isCorpEntity: true,
      firstName: person.firstName,
      middleName: person.middleName,
      lastName: person.lastName };

    setControlPersonsList([...controlPersonsList, newPerson]);
    setSlideView(CORP_ENTITY_VIEW);
  };

  const handleOwnerUpdate = () => {
    setValidationError(false);

    if (!checkBeneficialOwners(person)) {
      setValidationError(true);
      return;
    };

    let updatePeopleList = [...controlPersonsList];

    updatePeopleList = updatePeopleList.map(item => {
      if (item.id && (item.id === person.id)) return person;

      return item;
    });
    
    setControlPersonsList(updatePeopleList);
    setSlideView(CORP_ENTITY_VIEW);
  };

  const handleEdit = item => {
    setCorporateEntityView({ ...item, newCorpEntity: item.corpEntity });
    setSlidePanel(true);
  };

  const handleCancelView = _ => {
    setSlidePanel(false);
  };

  const handleCancelOwnerView = _ => {
    setPerson(corporateEntityObj);
    setSlideView(CORP_ENTITY_VIEW);
  };

  const handleSubmit = _ => {
    if (corporateEntityView.hasBeneficialOwners) handleSubmitHasOwners();
    if (!corporateEntityView.hasBeneficialOwners) handleSubmitNoOwners();
  };

  const handleSubmitNoOwners = _ => {
    setValidationError(false);
    
    const corporateEntity = { ...corporateEntityView };
    let listUpdate = controlPersonsList;

    if (!isCorporateEntityComplete(corporateEntity)) {
      setValidationError(true);
      return;
    }

    listUpdate = controlPersonsList.map(item => {
      if (item.id === corporateEntity.id || item.corpEntity === corporateEntity.corpEntity) item.isDeleted = true;
      return item;
    });

    if (!corporateEntity.id || controlPersonsList.find(item => item.id === corporateEntity.id)) {
      const id = createID(controlPersonsList);
      corporateEntity.id = id;
    }

    corporateEntity.corpEntity = corporateEntity.newCorpEntity;
    corporateEntity.isCorpEntity = true;
    corporateEntity.firstName = null;
    corporateEntity.middleName = null;
    corporateEntity.lastName = null;

    listUpdate = [ ...listUpdate, corporateEntity ];

    onControlPersonListUpdate(listUpdate);
    setSlidePanel(false);
  };

  const handleSubmitHasOwners = _ => {
    setValidationError(false);

    let listUpdate = controlPersonsList;

    // CHECK VALIDATION
    if (!owners || owners.length === 0) {
      setValidationError(true);
      return;
    } 

    listUpdate = controlPersonsList.filter(item => {
      if (item.hasBeneficialOwners && item.corpEntity === corporateEntityView.corpEntity) {
        item.hasBeneficialOwners = true;
        item.corpEntity = corporateEntityView.newCorpEntity;
        item.reasonForNone = null;
      
        return item;
      } else if (!item.hasBeneficialOwners && item.corpEntity === corporateEntityView.corpEntity) {
        item.isDeleted = true;
      }

      return item;
    });

    onControlPersonListUpdate(listUpdate);
    setSlidePanel(false);
  };

  const handleRemove = (e, entity) => {
    e.stopPropagation(); 
    onControlPersonRemove(entity);
  };

  const renderCorporateEntity = _ => <>
    <div>
      <p>
        Enter the name of the corporate entity that owns 10% or more of the issuer's shares.
      </p>
      <div className='mtXL'>
        <TextBox
          label='Name'
          placeholder='Name'
          name='newCorpEntity'
          value={corporateEntityView && corporateEntityView.newCorpEntity}
          readOnly={isReadOnly || person && person.isPreExisting}
          showClearButton
          isRequired
          onValueChanged={onCorpEntityNameChanged} />
      </div>
    </div>
    <div className='mtXL mbXL'>
      <QuestionCard
        item={question}
        section={corporateEntityView}
        readOnly={isReadOnly}
        isValid={isBoolean(corporateEntityView[question.fieldName]) && true}
        handleQuestionClick={(val, field) => onValueChanged(val, field)}
        onValueChanged={(val, field) => onValueChanged(val, field)}  />
    </div>
    {validationError && <Label className='mtLg' isError title={errors.INCOMPLETE_DATA} />}
    {!isReadOnly && <Controls
        className='mtXXL'
        cancelText='Cancel'
        submitText={isAdd ? 'Add' : 'Update'}
        onCancelClick={handleCancelView}
        onSubmitClick={handleSubmit}
      />}
  </>;

  const renderNewOwner = () => <>
    <Title title='Add Beneficial Owner' type='h2' />
    {renderOwnerForm()}
    {validationError && <Label className='mtLg' isError title={errors.INCOMPLETE_DATA} />}
    <div className={cn('mtXXL', styles.controls)}>
      <Controls
        cancelText='Cancel'
        submitText='Add'
        onCancelClick={handleCancelOwnerView}
        onSubmitClick={handleOwnerAdd} />
    </div>
  </>;

  const renderOwnerForm = () => <>
    <p className='mtLg mbLg'>
      Enter Full Legal Name of Beneficial Owner.
    </p>
    <div className={styles.innerGrid}>
      <div className={styles.col2}>
        <TextBox
          label='First Name'
          placeholder='First Name'
          name='firstName'
          value={person && person.firstName}
          readOnly={isReadOnly || person && person.isPreExisting}
          showClearButton
          isRequired
          onValueChanged={e => onIndividualValueChanged(OWNER, e.value, 'firstName')} />
      </div>
      <div className={styles.col2}>
        <TextBox
          label='Middle Name'
          placeholder='Middle Name'
          name='middleName'
          value={person && person.middleName}
          readOnly={isReadOnly || person && person.isPreExisting}
          showClearButton
          onValueChanged={e => onIndividualValueChanged(OWNER, e.value, 'middleName')} />
      </div>
      <div className={styles.col2}>
        <TextBox
          label='Last Name'
          placeholder='Last Name'
          name='lastName'
          value={person && person.lastName}
          readOnly={isReadOnly || person && person.isPreExisting}
          showClearButton
          isRequired
          onValueChanged={e => onIndividualValueChanged(OWNER, e.value, 'lastName')} />
      </div>
    </div>
  </>;

  const renderUpdateOwner = () => <>
    <Title title='Edit Beneficial Owner' type='h2' />
    {renderOwnerForm()}
    {validationError && <Label isError title={errors.INCOMPLETE_DATA} />}
    {!isReadOnly && <div className={cn('mtXXL', styles.controls)}>
      <Controls
        cancelText='Cancel'
        submitText='Update'
        onCancelClick={handleCancelOwnerView}
        onSubmitClick={handleOwnerUpdate} />
    </div>}
  </>;

  return (
    <div>
      {!noControlPerson && <EntityList
        className='mbXL'
        title='Corporate Entities'
        list={corporateEntityList}
        confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
        icon={'user'}
        iconComplete={'userComplete'}
        entityTitle={item => item.corpEntity}
        validateComplete={item => isCorporateEntityComplete(item)}
        addTitle='Add Corporate Entity (Control Person)'
        readOnly={isReadOnly}
        handleEntityClick={handleEdit}
        handleEntityRemove={!isReadOnly && handleRemove}
        handleAddClick={() => setSlidePanel(true)}
      />}
      {noControlPerson && <div className='mbLg'>
        <Entity
          title='Not Available'
          icon='userComplete'
          isComplete
          subTitle='Complete' />
      </div>}
      <SlidePanel 
        isOpen={slidePanel}
        onClose={() => setSlidePanel(false)}
        title={isAdd ? addTitle : corporateEntityViewTitle}>
          {slideView === CORP_ENTITY_VIEW && renderCorporateEntity()}
          {slideView === ADD_NEW_PARTICIPANTS_VIEW && renderNewOwner()}
          {slideView === EDIT_NEW_PARTICIPANTS_VIEW && renderUpdateOwner()}
      </SlidePanel>
    </div>
  );
};

CorporateEntities.propTypes = {
  noControlPerson: PropTypes.bool,
  onControlPersonAdd: PropTypes.func,
  onControlPersonUpdate: PropTypes.func,
  onControlPersonListUpdate: PropTypes.func,
  onControlPersonRemove: PropTypes.func
};

export default CorporateEntities;

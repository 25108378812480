import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NotificationsContext } from '../../context/Notifications.context';
import { ThemeContext } from '../../context/Theme.context';
import Loading from '../Loading';
import SystemNotifications from '../SystemNotifications';
import styles from './Layout.module.scss';

const Layout = ({ children }) => {
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const theme = useContext(ThemeContext);
  const hasNotifications = notificationState.notifications;
  const lock = theme.state.lock;

  return <>
    {!!lock && <div className={styles.loading}>
      <Loading />
    </div>}
    <div className={styles.container}>
      {lock && <div className={styles.lock} />}
      {children}
    </div>
    {hasNotifications && <SystemNotifications notifications={hasNotifications} />}
  </>;
};

Layout.propTypes = {
  children: PropTypes.node
};


export default Layout;

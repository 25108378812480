import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { fields } from '../../constants';
import { getLastAuthUser, personEntityTitle } from '../../utils/helper';
import { ProfileContext } from '../../context/Profile.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Controls from '../Controls';
import Label from '../Label';
import QuestionCard from '../QuestionCard';
import styles from './RemovePerson.module.scss';

const RemovePerson = ({ person, selectedRemove, onRemoveSubmit, onCancel }) => {
  const [removePerson, setRemovePerson] = useState({ ...person, [selectedRemove]: false });
  const [profileState] = useContext(ProfileContext);
  const peopleList = profileState.profile.peopleList;
  const companyName = profileState.profile.companyInfo.companyName;
  const isLastAuthUser = getLastAuthUser(person, peopleList) && !removePerson[fields.AUTH_USER];

  const contactTypes = [
    {
      title: 'Officer or Contact',
      subTitle: `Is ${personEntityTitle(person, false)} an Officer or Contact for ${companyName}`,
      fieldName: fields.OFFICER
    },
    {
      title: 'Director',
      subTitle: `Is ${personEntityTitle(person, false)} a Director for ${companyName}`,
      fieldName: fields.DIRECTOR
    },
    {
      title: 'Control person',
      subTitle: `Is ${personEntityTitle(person, false)} a Control Person for ${companyName}`,
      fieldName: fields.CONTROL_PERSON
    },
    {
      title: 'Beneficial Owner',
      subTitle: `Is ${personEntityTitle(person, false)} a Beneficial Owner for ${companyName}`,
      fieldName: fields.BENEFICIAL_OWNER
    },
    {
      title: 'Primary Contact',
      subTitle: `Is ${personEntityTitle(person, false)} a Primary Contact for ${companyName}`,
      fieldName: fields.PRIMARY_CONTACT
    },
    {
      title: 'Primary Billing Contact',
      subTitle: `Is ${personEntityTitle(person, false)} a Primary Billing Contact for ${companyName}`,
      fieldName: fields.PRIMARY_BILLING
    },
    {
      title: 'Additional Billing Contact',
      subTitle: `Is ${personEntityTitle(person, false)} a Additional Billing Contact for ${companyName}`,
      fieldName: fields.OTHER_BILLING
    },
    {
      title: 'Authorized User',
      subTitle: <>
        {`Is ${personEntityTitle(person, false)} an Authorized User for ${companyName}`}
        {isLastAuthUser && <div>
          <FontAwesomeIcon className={styles.warningIcon} icon={['fal', 'exclamation-triangle']} />
          <p className={styles.warningText}>
            Are you sure you want to remove this person from {companyName}? Please note that without any Authorized IQ users, {companyName} will not be able to make any updates via OTCIQ.
          </p>
        </div>}
      </>,
      fieldName: fields.AUTH_USER
    }
  ];

  const contactQuestions = contactTypes.filter(type => person[type.fieldName]);
  const isDeleteUser = contactQuestions.filter(item => !removePerson[item.fieldName]).length === contactQuestions.length;

  contactQuestions.sort(function(x,y){ return x.fieldName === selectedRemove ? -1 : y.fieldName === selectedRemove ? 1 : 0; });

  const handleQuestionUpdate = (value, field) => {
    setRemovePerson({ ...removePerson, [field]: value });
  };

  const handleSubmit = _ => {
    const isDeleted = isDeleteUser;
    onRemoveSubmit({ ...removePerson, isDeleted });
  };

  return <div className={styles.container}>
    <Label className='mbLg' isError>
      <b>
        You are attempting to remove {personEntityTitle(person, false)} as an Officer or Contact. {personEntityTitle(person, false)} is also assigned to the following contact types below.
      </b>
      <br /><br />
      <b>
        If you would like to completely remove {personEntityTitle(person, false)} from {companyName} select No for all contact types below.
      </b>
    </Label>
    {contactQuestions.map(question => <QuestionCard
      key={question.fieldName}
      item={question}
      section={removePerson}
      handleQuestionClick={handleQuestionUpdate}  />)}
      <div className={'mtXL'}>
        <Controls
          cancelText='Cancel'
          submitText={isDeleteUser ? 'Remove' : 'Update'}
          submitColor={isDeleteUser && 'red'}
          onCancelClick={onCancel}
          onSubmitClick={handleSubmit} />
      </div>
  </div>;
};

RemovePerson.propTypes = {
  person: PropTypes.object,
  selectedRemove: PropTypes.string,
  onRemoveSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default RemovePerson;

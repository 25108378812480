import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getServiceProviders } from '../../api/directory';
import AutoCompleteBox from '../../components/AutoCompleteBox';
import FieldLabel from '../../components/FieldLabel';

const PLACEHOLDER_NA = 'N/A';

const ServiceProviderSearch = ({ value, typeId, notAvailable, isRequired, readOnly, disabled, disableSearch, onValueChanged, onDetailSelect }) => {
    const [inputValue, setInputValue] = useState(value || null);
    const [searchResults, setSearchResults] = useState(null);

    useEffect(() => {
        if (disabled) {
            setInputValue(null);
        }
    }, [disabled]);

    const onOptionChanged = e => {
        if (e.name === 'text') {
            const value = e.value;
            setInputValue(value);
            onValueChanged(value);
            !disableSearch && value && value.length > 0 && getServiceProviders(typeId, value).then(data => setSearchResults(data));
        }
    };

    const handleSelect = e => {
        setInputValue(e.value);
        if (onDetailSelect) onDetailSelect(searchResults.find(r => r.name === e.value));
        onValueChanged(e.value);
    };

    return (
        <>
            <FieldLabel text='Name of Firm' />
            <AutoCompleteBox
                dataSource={searchResults}
                placeholder={notAvailable ? PLACEHOLDER_NA : 'Name of Firm'}
                name='name'
                isRequired={isRequired}
                readOnly={readOnly}
                valueExpr='name'
                value={inputValue}
                disabled={disabled}
                showClearButton
                onOptionChanged={onOptionChanged}
                onValueChanged={handleSelect} />
        </>
    );
};

ServiceProviderSearch.propTypes = {
  value: PropTypes.string,
  typeId: PropTypes.string,
  notAvailable: PropTypes.bool,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  disableSearch: PropTypes.bool,
  onValueChanged: PropTypes.func,
  onDetailSelect: PropTypes.func
};

export default ServiceProviderSearch;

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Title from '../Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';
import styles from './SlidePanel.module.scss';
import './slide-panel.scss';

const SlidePanel = ({ isOpen, onClose, title, subTitle, children, childChange }) => {
  const ref = useRef(null);

  useEffect(() => {
    !!childChange && ref.current.scrollTo(0, 0);
  }, [childChange]);

  return <div className={cn(styles.container, {[styles.background]: isOpen})}>
    <CSSTransition in={!!isOpen} timeout={300}
      classNames='panel'
      unmountOnExit>
      <div className='slide-out'>
        <div ref={ref} className={styles.slideContainer}>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              <div className={styles.titleRow}>
                {title && <Title className={styles.title} title={title} />}
                <span className={styles.closeBtn} onClick={onClose}>
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </span>
              </div>
              {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
            </div>
          </div>
          {children && <div className={styles.content}>
            {children}
          </div>}
        </div>
      </div>
    </CSSTransition>
  </div>;
};

SlidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  childChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default SlidePanel;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errors, urls } from '../../../constants';
import { isBoolean } from '../../../utils/validations';
import { isDirectorComplete } from '../../../utils/completes';
import { directorSection1, directorSection2 } from '../../../constants/forms/people';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Controls from '../../Controls';
import FormSection from '../../FormSection';
import Label from '../../Label';
import Title from '../../Title';
import QuestionCard from '../../QuestionCard';
import styles from './People.module.scss';

const question = {
    title: 'Independent Director',
    subTitle: () => <p>Is this individual an  <a href={urls.GLOSSARY_IND_DIR} target='_blank' rel='noopener noreferrer'>Independent Director</a>?</p>,
    fieldName: 'isIndependent'
};

const DirectorForm = ({ person, onPersonAdd, onPersonUpdate, isChairPerson, onCancel, onValueChanged, isAdd, isReadOnly }) => {
    const [validationError, setValidationError] = useState(false);

    const handleQuestionUpdate = (value, field) => {
        person[field] = value;
        onValueChanged(person);
    };

    const handleSubmitPerson = _ => {
        setValidationError(false);

        const onSubmit = (!person.id) ? onPersonAdd : onPersonUpdate;
        const director = person;
        if (!director.isDirector) director.isDirector = true;
        if (isChairPerson && !director.isChairPerson) director.isChairPerson = true;

        if (!isDirectorComplete(person)) {
            setValidationError(true);
            return;
        }

        onSubmit(director);
    };

    return (
        <div>
            <Label>
            <FontAwesomeIcon className={styles.icon} icon={['far', 'lock']} />
            <Title className={styles.labelTitle} type='h3' title='Private Information' />
            <div>
                This icon denotes that the information is private and is not publicly displayed.
            </div>
            </Label>
            <div className='mtMed'>
                {directorSection1.map((section, i) => <FormSection key={i} section={section} data={person} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
            </div>
            <div className='mtXL mbXL'>
                <QuestionCard
                item={question}
                section={person}
                readOnly={isReadOnly}
                isValid={isBoolean(person.isIndependent)}
                handleQuestionClick={handleQuestionUpdate}  />
            </div>
            <div className='mtMed'>
                {directorSection2.map((section, i) => <FormSection key={i} section={section} data={person} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
            </div>
            {validationError && <Label isError title={errors.INCOMPLETE_DATA} />}
            {!isReadOnly && <Controls
                className='mtXXL'
                cancelText='Cancel'
                submitText={isAdd ? 'Add' : 'Update'}
                onCancelClick={onCancel}
                onSubmitClick={handleSubmitPerson}
            />}
        </div>
    );
};

DirectorForm.propTypes = {
    person: PropTypes.object,
    onCancel: PropTypes.func,
    onPersonAdd: PropTypes.func,
    onPersonUpdate: PropTypes.func,
    onValueChanged: PropTypes.func,
    isChairPerson: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isAdd: PropTypes.bool
};

export default DirectorForm;

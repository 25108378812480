import moment from 'moment-timezone';
import { entities, fields, compareFieldTypes } from '../constants';
import { isDate } from '../utils/validations';
import { format, getCountryName } from './locale';

export const redirectIQ = _ => window.location.href = 'https://www.otciq.com/home';

export const getProperty = (from, ...selectors) =>
[...selectors].map(s =>
  s
    .replace(/\[([^\[\]]*)\]/g, '.$1.')
    .split('.')
    .filter(t => t !== '')
    .reduce((prev, cur) => prev && prev[cur], from)
);

export const setProperty = (object, path, value) => {
    const decomposedPath = path.split('.');
    const base = decomposedPath[0];

    if (base === undefined) {
        return object;
    }

    // assign an empty object in order to spread object
    if (!object.hasOwnProperty(base)) {
        object[base] = {};
    }

    // Determine if there is still layers to traverse
    value = decomposedPath.length <= 1 ? value : setProperty(object[base], decomposedPath.slice(1).join('.'), value);
    
    return {
        ...object,
        [base]: value,
    };
};

export const personEntityTitle = (person, showRole = true) => {
    const hasTitles = person && person.title1;
    const titleList = [];

    if (hasTitles) titleList.push(person.title1);
    if (person && person.title2) titleList.push(person.title2);
    if (person && person.title3) titleList.push(person.title3);
    if (person && person.title4) titleList.push(person.title4);

    let title = `${person && person.firstName ? person.firstName : ''} ${person && person.lastName ? person.lastName : ''}`;
    if (hasTitles && showRole) title = title + `, ${titleList.join(', ')}`;

    if (!title || title.trim().length === 0) return entities.NO_NAME;

    return title;
};

export const isTierOTCQX = tier => getTier(tier) === 'qx';
export const isTierOTCQB = tier => getTier(tier) === 'qb';
export const isTierPink = tier => getTier(tier) === 'ps';

export const getTier = data => {
    let tier = (data && (data.id || data.group || data.tierId || data.tierCode || data.tierGroup));
    if (!isNaN(parseInt(tier))) tier = parseInt(tier);

    let tierType = null;

    switch (tier) {
    case -1:
        case 'CE':
        tierType = 'caveat-emptor';
        break;

    case 1:
    case 2:
    case 5:
    case 6:
    case 'OTCQX_US':
    case 'OTCQX_BANKS':
    case 'OTCQX_INTL':
    case 'OTCQX':
    case 'QX':
    case 'QXUSP':
    case 'QXUS':
    case 'QXIP':
    case 'QXI':
    tierType = 'qx'; // QX
    break;

    case 10:
    case 'OTCQB':
    case 'QB':
    case 'DQ':
    tierType = 'qb'; // QB
    break;

    case 20:
    case 'PS':
    case 'PC':
    tierType = 'ps'; // Pink
    break;

    case 21:
    case 'PL':
    tierType = 'yield'; // Pink Limited Info
    break;

    case 22:
    case 'PN':
    tierType = 'stop'; // Pink No Info
    break;

    case 30:
    case 'OO':
    case 'GM':
    tierType = 'caution'; // Grey Market
    break;

    case 40:
    case 'EM':
    tierType = 'expert-tier'; // Expert Tier
    break;

    default:
    tierType = 'listed'; // LISTED/NON-OTC
    break;
    }

    return tierType;
};

export const createID = array => {
    const id = Math.floor(Math.random()*90000) + 10000;
    if (array && array.find(item => item.id === id)) {
        createID(array);
    } else {
        return id;
    }
};

export const isSameOrBefore = date => date ? moment(date).isSameOrBefore(new Date()) : true;

export const sortList = (list, sortBy, sortDir = 'asc') => {
    let orderBy1 = sortDir === 'desc' ? -1 : 1;
    let orderBy2 = sortDir === 'desc' ? 1 : -1;
    return list.sort((a, b) => (a[sortBy] > b[sortBy]) ? orderBy1 : orderBy2);
};

export const sortListAlphabetically = (list, sortBy, sortDir = 'asc') => {
    const sortedList = list.sort((a, b) => {
        const compareResult = a[sortBy].localeCompare(b[sortBy], 'en-US', { ignoreCase: true });
        return sortDir === 'desc' ? -compareResult : compareResult;
    });
    return sortedList;
};

export const sortTierDashboard = array => {
    return array.sort((a, b) => {
        if (a.tierId === 0 && b.tierId !== 0) {
          return 1; // Move a (zero) to the end
        }
        if (b.tierId === 0 && a.tierId !== 0) {
          return -1; // Move b (zero) to the end
        }

        const valueComparison = a.tierId - b.tierId;
        if (valueComparison !== 0) {
            return valueComparison;
        }
        
        return a.companyName.localeCompare(b.companyName); // When same tier sort by alphabetical company name
    });
};

export const isFutureDate = inputDate => {
    // Create Date objects for the input date and current date
    const currentDate = new Date();
    const inputDateObj = new Date(inputDate);

    // Compare the input date with the current date
    return inputDateObj > currentDate;
};

export const isDateWithinLast90Days = date => {
    return moment().diff(date, 'days') < 90;
};

export const isDateWithinLastOneYear = date => {
    return moment().diff(date, 'years') < 1;
};

export const isSharesOutstandingLessThanAuthorized = data => data.unlimitedAuthorizedShares ||  data.outstandingShares <= data.authorizedShares;

export const getLastAuthUser = (person, list) => person && person[fields.AUTH_USER] && list.filter(p => p[fields.AUTH_USER] && p.id !== person.id).length === 0 ? true : false;

export const compareField = (obj1, obj2, fieldName, fieldLabel, changeObj, fieldType) => {
    const hasCurrentField = obj1 && obj1.hasOwnProperty(fieldName) ? true : false;
    const hasUpdatedField = obj2 && obj2.hasOwnProperty(fieldName) ? true : false;

    if (!hasCurrentField && !hasUpdatedField
        || !hasCurrentField && (obj2[fieldName] === null || obj2[fieldName] === undefined || obj2[fieldName].length === 0)
        || !hasUpdatedField && (obj1[fieldName] === null || obj1[fieldName] === undefined || obj1[fieldName].length === 0)) return false;

    let oldValue = hasCurrentField ? obj1[fieldName] : null;
    let newValue = hasUpdatedField ? obj2[fieldName] : null;

    if (oldValue !== newValue) {
        switch (fieldType) {
            case compareFieldTypes.DATE:
                oldValue = oldValue && isDate(oldValue) ? moment(oldValue).format(compareFieldTypes.MMDDYYYYY) : null;
                newValue = newValue && isDate(newValue) ? moment(newValue).format(compareFieldTypes.MMDDYYYYY) : null;
                break;
            case compareFieldTypes.BOOLEAN:
                oldValue = oldValue ? 'Yes' : hasCurrentField ? 'No' : null;
                newValue = newValue ? 'Yes' : 'No';
                break;
            case compareFieldTypes.NUMBER:
                oldValue = format(oldValue, oldValue, 'int');
                newValue = format(newValue, newValue, 'int');
                break;
            case compareFieldTypes.COUNTRY:
                oldValue = getCountryName(oldValue);
                newValue = getCountryName(newValue);
                break;
            case compareFieldTypes.STATE:
                oldValue = getCountryName(oldValue);
                newValue = getCountryName(newValue);
                break;
            default:
            break;
        };
        
        const diffObject = {
            fieldName: fieldLabel,
            oldValue,
            newValue
        };

        changeObj.fields.push(diffObject);
    }

    return null; // Return null if there is no difference
};

import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal/es6';
import { withRouter } from '../../WithRouter';
import { contactRoles, fields, routes, verifiedSectionKeys } from '../../../constants';
import { isPrimaryContactsTabComplete, isAuthUsersTabComplete, isBillingContactsTabComplete } from '../../../utils/completes';
import { ProfileContext } from '../../../context/Profile.context';
import { createID } from '../../../utils/helper';
import FormInstructions from '../../FormInstructions';
import PrimaryContactsTab from './PrimaryContactsTab';
import AuthorizedUsersTab from './AuthorizedUsersTab';
import BillingContactsTab from './BillingContactsTab';
import TabMenu from '../../TabMenu';
import VerifyBox from '../../VerifyBox';
import Button from '../../Button';
import ApplicationDescription from '../../ApplicationDescription/ApplicationDescription';

const pageTabItems = [
  {
    title: 'Primary Contacts',
    path: routes.PRIMARY_CONTACTS,
    component: PrimaryContactsTab,
    validateComplete: isPrimaryContactsTabComplete
  },
  {
    title: 'Authorized Users',
    path: routes.AUTHORIZED_USERS,
    component: AuthorizedUsersTab,
    validateComplete: isAuthUsersTabComplete
  },
  {
    title: 'Billing Contacts',
    path: routes.BILLING_CONTACTS,
    component: BillingContactsTab,
    validateComplete: isBillingContactsTabComplete
  }
];

const getTabItems = (profile) => {

  const newTabs = pageTabItems.map((item, i) => {
    let completed = true;
    if (profile && item.validateComplete) {
      completed = item.validateComplete(profile);
    }
    item.completed = completed;
    return item;
  });
  return newTabs;


};

const DesignatedContacts = ({ navigate,  params: { symbol, formSection, formTab }, saveApplication }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const profile = profileState.profile;
  const backupProfile = profileState.backupProfile;
  const peopleList = profile.peopleList;
  const tabItems = getTabItems(profile);
  const currentTab = tabItems.find(item => item.path === formTab);
  const [activeTab, setActiveTab] = useState(currentTab || tabItems[0]);
  const isReadOnly = profileState.readOnly;

  useEffect(() => {
    const tab = tabItems[0].path;
    if (!formTab) navigate(`${routes.PROFILE_PREFIX}/${symbol}/${formSection}/${tab}`);
  }, [navigate, formTab, symbol, formSection]);

  useEffect(() => {
    setActiveTab(tabItems.find(tab => tab.path === formTab));
  }, [formTab]);

  useEffect(() => {
    const isPeopleChange = !equal({ ...backupProfile.peopleList }, { ...peopleList });

    if (isPeopleChange) {
      saveApplication();
    }
  }, [backupProfile.peopleList, peopleList, saveApplication]);


  const onUpdate = (data, list) => {
    dispatchProfile({
      type: 'UPDATE_PROFILE',
      field: list,
      payload: data
    });
  };

  const onPersonAdd = person => {
    const newPerson = person;
    if (!person.id) {
      const id = createID(peopleList);
      newPerson.id = id;
    }

    onUpdate([...peopleList, newPerson], fields.PEOPLE_LIST);
  };

  const onPersonUpdate = person => {
    let updatePeopleList = [...peopleList];
    updatePeopleList = updatePeopleList.map(item => {
      if (item.id && (item.id === person.id)) return person;

      return item;
    });

    onUpdate(updatePeopleList, fields.PEOPLE_LIST);
  };

  const onPersonRemove = (person, type) => {
    const updatePeopleList = peopleList;
    const personUpdate = updatePeopleList.find(item => item.id && item.id === person.id);
    
    personUpdate[type] = false;

    const hasRole = contactRoles.find(role => person[role]);
    if (!hasRole) personUpdate.isDeleted = true;

    onUpdate(updatePeopleList, fields.PEOPLE_LIST);
  };

  const renderTab = () => {
    const TabComponent = currentTab.component;

    return <TabComponent
      onPersonAdd={onPersonAdd}
      onPersonUpdate={onPersonUpdate}
      onPersonRemove={onPersonRemove}
    />;
  };

  const handleTabClick = tab => {
    navigate(`${routes.PROFILE_PREFIX}/${symbol}/${formSection}/${tab.path}`);
  };

  return <div>
    <ApplicationDescription title='Designated Contacts' />
    <FormInstructions />
    <TabMenu items={tabItems} activeTab={activeTab.title} handleTabClick={handleTabClick} />
    {renderTab()}
    {!isReadOnly && <VerifyBox section={verifiedSectionKeys.DESIGNATE_CONTACTS} />}
    <Link to={`${routes.PROFILE_PREFIX}/${symbol}/${routes.SECURITIES}`}>
      <Button title='Proceed to Securities' fullWidth />
    </Link>
  </div>;
};

DesignatedContacts.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string,
    formSection: PropTypes.string,
    formTab: PropTypes.string
  }),
  navigate: PropTypes.func,
  saveApplication: PropTypes.func
};

export default withRouter(DesignatedContacts);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { Autocomplete } from 'devextreme-react';
import { errors } from '../../constants';

const AutoCompleteBox = ({...props}) => {
  const [isFocus, setFocus] = useState(false);
  const isLabel = props.label || !props.isValid;
  const showRequired = props.isRequired && (!props.value || props.value.trim().length === 0);

  const handleFocusIn = e => {
    setFocus(true);
  };

  const handleFocusOut = e => {
    setFocus(false);
  };

  return <>
    {isLabel && <div>
      {props.label && <FieldLabel text={props.label} />}
      {!props.hideErrorIcon && (!props.isValid || showRequired) && <FieldError isFocus={isFocus} error={props.errorText || errors.INCOMPLETE_DATA_FIELD} />}
    </div>}
    <Autocomplete
      {...props}
      onFocusIn={props.onFocusIn || handleFocusIn}
      onFocusOut={props.onFocusOut || handleFocusOut}
      inputAttr={{
        autocomplete: 'no-thanks'
      }}
      isValid={showRequired ? !!(props.value && props.value.trim().length > 0) : props.isValid}
      showClearButton={props.showClearButton && isFocus}
    />
  </>;
};

AutoCompleteBox.defaultProps = {
  isValid: true
};

AutoCompleteBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
  isRequired: PropTypes.bool,
  isValid: PropTypes.bool,
  hideErrorIcon: PropTypes.bool,
  showClearButton: PropTypes.bool,
  onFocusIn: PropTypes.func,
  onFocusOut: PropTypes.func
};


export default AutoCompleteBox;
import api from './api';

export const getList = async () => {
    const response = await api.request({
        method: 'get',
        url: '/cuf/list',
        isCUF: true
    });
    return response.data;
};

export const getApplication = async params => {
  const response = await api.request({
      method: 'get',
      url: `/cuf/application`,
      params,
      isCUF: true
  });

  return response.data;
};

export const getCurrentProfile = async params => {
     const response = await api.request({
         method: 'get',
         url: `/cuf/company/current`,
         params,
         isCUF: true
     });
     return response.data;
   };

export const createCuf = async params => {
   const response = await api.request({
      method: 'post',
      url: `/cuf/create`,
      params,
      isCUF: true
  });
  return response.data;
};

export const cancelCuf = async params => {
   const response = await api.request({
      method: 'post',
      url: `/cuf/cancel`,
      params,
      isCUF: true
  });
  return response.data;
};

export const reopenCuf = async params => {
    const response = await api.request({
        method: 'post',
        url: `/cuf/reopen`,
        params,
        isCUF: true
    });
    return response.data;
};

export const saveCuf = async data => {
    const response = await api.request({
        method: 'post',
        url: `/cuf/save`,
        data,
        isCUF: true
    });
    return response.data;
};

export const submitCuf = async data => {
    const response = await api.request({
        method: 'post',
        url: `/cuf/submit`,
        data,
        isCUF: true
    });
    return response.data;
};

export const helpCuf = async data => {
    const response = await api.request({
        method: 'post',
        url: `/cuf/help`,
        data,
        isCUF: true
    });
    return response.data;
};

import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appStatuses, routes } from '../../constants';
import { sortTierDashboard, sortListAlphabetically } from '../../utils/helper';
import { createCuf, cancelCuf, reopenCuf, getList } from '../../api/cuf';
import { withRouter } from '../../components/WithRouter';
import DashboardCard from '../../components/DashboardCard';
import HeaderDescription from '../../components/HeaderDescription';
import Loading from '../../components/Loading';
import styles from './DashboardPage.module.scss';

const DESC = <>Select a company below to review and verify company profile data.<br /><br />
A <span className={styles.descIcon}><FontAwesomeIcon icon={['fa', 'circle-check']} /></span> <b>Verified Profile</b> badge will display for 6 months from the date of verification. Note that all changes will be reviewed by OTC Markets before they are publicly displayed.
</>;

const DashboardPage = ({ navigate }) => {
  const [list, setList] = useState(null);
  const [isListLoaded, setListLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [listError, setListError] = useState(null);
  const [inactiveCards, setInactiveCards] = useState([]);

  useEffect(() => {
    getProfiles();
  }, []);

  const getProfiles = _ => getList()
    .then(data => {
      if (data.length === 0) {
        setListError('There are no profiles assigned to this user.');
        return;
      }
      const inProgressItems = sortTierDashboard(data.filter(item => {
        item.tierId = item.tier.id;
        return item.status.id === appStatuses.IN_PROGRESS;
      }));
      const notInProgressItems = sortListAlphabetically(data.filter(item => item.status.id !== appStatuses.IN_PROGRESS), 'companyName');
      const sortedList = [...inProgressItems, ...notInProgressItems];
      setList(sortedList);
      setListLoaded(true);
    })
    .catch(err => {
      console.error(err);
      setListError('Data currently not available.');
    });

  const renderList = () => list.map((item, i) => <DashboardCard
    key={`item-${i}`}
    item={item}
    error={error && error.id === item.symbol ? error.message : false}
    onCreateClick={handleCreate}
    inactive={inactiveCards.includes(item.symbol)}
    onCancelClick={handleCancel}
    onReopenClick={handleReopen} />);
  
  const handleCreate = (item) => {
    const symbol = item.symbol;
    const params = {
      symbol
    };

    setError(null);
    setInactiveCards([...inactiveCards, symbol]);

    createCuf(params)
      .then(_ => {
        const origin = window.location.origin;
        window.location.href = `${origin}${routes.PROFILE_PREFIX}/${symbol}/${routes.COMPANY_INFO}`;
      })
      .catch(err => {
        const isStatus504 = err?.response?.data?.code === 504;
        const errorMessage = `Unable to create profile.${isStatus504 ? ' Please wait a few minutes and try again.' : ''}`;

        setInactiveCards(inactiveCards.filter(card => card.symbol !== symbol));
        setError({
          id: item.symbol,
          message: errorMessage
        });
      });
  };
  
  const handleCancel = item => {
    const symbol = item.symbol;
    const params = {
      symbol
    };

    setError(null);
    setInactiveCards([...inactiveCards, symbol]);

    cancelCuf(params)
      .then(data => {
        setInactiveCards(inactiveCards.filter(card => card.symbol !== symbol));
        getProfiles();
      })
      .catch(err => {
        console.error(err);
        setInactiveCards(inactiveCards.filter(card => card.symbol !== symbol));
        setError({
          id: item.symbol,
          message: 'Unable to cancel profile'
        });
      });
  };

  const handleReopen = item => {
    const symbol = item.symbol;
    const params = {
      symbol
    };

    setError(null);
    setInactiveCards([...inactiveCards, symbol]);

    reopenCuf(params)
      .then(data => {
        setInactiveCards(inactiveCards.filter(card => card.symbol !== symbol));
        getProfiles();
      })
      .catch(err => {
        console.error(err);
        setInactiveCards(inactiveCards.filter(card => card.symbol !== symbol));
        setError({
          id: item.symbol,
          message: 'Unable to reopen profile'
        });
      });
  };

  return (
    <section>
      <HeaderDescription title='Manage Company Profiles' desc={DESC} />
      <Loading loaded={isListLoaded}
            error={listError}>
        {!!list && renderList()}
      </Loading>
    </section>
  );
};

DashboardPage.propTypes = {
  navigate: PropTypes.func
};

export default withRouter(DashboardPage);

import { storage } from '../constants';
import { getCountryCodes } from '../api/common';

const numberWithCommas = (x) => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};

export const getCodes = () => {
    const currentDate = new Date();
    const storedCodes = window.localStorage.getItem(storage.COUNTRY_CODES);
    const storedTime = window.localStorage.getItem(storage.COUNTRY_CODES_TIME);
    if (storedCodes && storedTime && storedTime > currentDate.getTime()) {
        return JSON.parse(storedCodes);
    } else {
        getCountryCodes().then(data => {
            if (!data) return;
            const countryList = data.countries;
            const USA = countryList && countryList.find(country => country.id === 'US');
            if (USA) countryList.unshift(USA);
            const expireDate =  currentDate.setDate(currentDate.getDate() + 100);
            const codes = { ...data, countries: countryList };
            window.localStorage.setItem(storage.COUNTRY_CODES, JSON.stringify(codes));
            window.localStorage.setItem(storage.COUNTRY_CODES_TIME, expireDate);
            return JSON.parse(storedCodes);
        }).catch(e => console.log('error', e));
    }
};

const localeCodes = getCodes();
const countryList = localeCodes && localeCodes.countries ? localeCodes.countries : [];
const stateList = localeCodes && localeCodes.states ? localeCodes.states : [];

export const getCountryName = id => {
    const country = countryList.find(c => c.id === id);
    return country ? country.name : id;
};

export const getStateName = (stateId, countryId) => {
    const state = stateList.find(s => s.id === stateId && s.countryId === countryId);
    return state ? state.name : stateId;
};

export function format (value, placeholder, type) {
    if (value !== null && value !== undefined) {
      switch (type) {
        case 'int':
          if (typeof value === 'string') value = parseFloat(value);
          value = numberWithCommas(value);
          break;
  
        case 'seconds':
          if (typeof value === 'string') value = parseFloat(value);
          if (value < 1) value = '< 1s';
          if (value >= 1) value = `${numberWithCommas(value.toFixed(0))}s`;
          break;
  
        case 'float':
          if (typeof value === 'string') value = parseFloat(value);
          if (isNaN(value)) return placeholder;
          value = numberWithCommas(value.toFixed(2));
          break;
  
        case 'price':
          value = parseFloat(value);
          if (isNaN(value)) return placeholder;
          value = numberWithCommas(value.toFixed(6).replace(/0{0,4}$/, ''));
          break;
  
        case 'millions':
            value = parseFloat(value);
          if (isNaN(value)) return placeholder;
          value = value / 1000000.0;
          value = numberWithCommas(value.toFixed(2));
          break;
  
        case 'percentage':
          value = parseFloat(value);
          if (isNaN(value)) return placeholder;
          value = numberWithCommas(value.toFixed(2));
          if (value === '-0.00') value = '0.00';
          if (value.length > 0) value += '%';
          break;
  
        case 'tenths':
          value = parseFloat(value);
          if (isNaN(value)) return placeholder;
          value = numberWithCommas(value.toFixed(1));
          if (value === '-0.00') value = '0.00';
          if (value.length > 0) value += '%';
          break;
  
        case 'devExpressPercent':
          value = (value * 100).toFixed(2).replace(/\.00$/,'') + '%';
          break;
  
        case 'sign':
          const sign = parseFloat(value) > 0 ? '+' : '';
          value = numberWithCommas(value.toFixed(2));
          if (value.length > 0) value = sign + value;
          break;
  
        case 'bool':
          value = value ? 'Yes' : 'No';
          break;
  
        case 'text':
          break;
  
        case 'number':
          if (typeof value === 'string') value = parseFloat(value);
          if (isNaN(value)) return placeholder;
          value = numberWithCommas(value);
          break;
  
        case 'currency':
          if (typeof value === 'string') value = parseFloat(value);
          if (isNaN(value)) return placeholder;
          value = numberWithCommas(value);
          if (value.length > 0) value = '$' + value;
          break;
      }
    } else {
      value = placeholder;
    }
  
    return value;
  }
const getConfig = _ => {
  // Use env vars as default
  if (window.location.host.match(/localhost/)) {
    return {
      REACT_ENV: 'local',
      REACT_APP_API_BASE_URL: 'http://localhost:4001',
      REACT_APP_OTC_BASE_URL: 'https://otcm-web.test.otcmkt.com'
    };
  } else if (window.location.host.match(/dev/)) {
    return {
      REACT_ENV: 'dev',
      REACT_APP_API_BASE_URL: 'https://cuf.dev.otcmkt.com',
      REACT_APP_OTC_BASE_URL: 'https://otcm-web.dev.otcmkt.com'
    };
  } else if (window.location.host.match(/test/)) {
    return {
      REACT_ENV: 'test',
      REACT_APP_API_BASE_URL: 'https://cuf.test.otcmkt.com',
      REACT_APP_OTC_BASE_URL: 'https://otcm-web.test.otcmkt.com'
    };
  } else if (window.location.host.match(/qa/)) {
    return {
      REACT_ENV: 'qa',
      REACT_APP_API_BASE_URL: 'https://cuf.test.otcmkt.com',
      REACT_APP_OTC_BASE_URL: 'https://otcm-web.test.otcmkt.com'
    };
  } else if (window.location.host.match(/stage/)) {
    return {
      REACT_ENV: 'stage',
      REACT_APP_API_BASE_URL: 'https://cuf.stage.otcmkt.com',
      REACT_APP_OTC_BASE_URL: 'https://otcm-web.stage.otcmkt.com'
    };
  } else if (window.location.host.match(/.otcmarkets\.com/)) {
    return {
      REACT_ENV: 'prod',
      REACT_APP_API_BASE_URL: "https://company.otcmarkets.com",
      REACT_APP_OTC_BASE_URL: 'https://www.otcmarkets.com'
    };
  }
};

export default getConfig;

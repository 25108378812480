import { fieldTypes, filingCycles, reportingStandards, sectionTitles } from '../index';
import { getCodes } from '../../utils/locale';
import { yearsList } from '../years';
import { monthsList } from '../months';
import CompanyNameHistory from '../../components/CompanyNameHistory';
import IncorporationHistory from '../../components/IncorporationHistory';
import { isRequiredCountry, isIncCorpHistValid, getIncCorpHistInvalidText } from '../../utils/completes';
const localeCodes = getCodes();
const countryList = localeCodes && localeCodes.countries ? localeCodes.countries : [];
const stateList = localeCodes && localeCodes.states ? localeCodes.states : [];

const getDaysList = () => {
    let daysList = [];
    for (var i = 1; i < 31 + 1; i++) {
        daysList.push(i.toString());
    }

    return daysList;
};


export const incorporationHistory = [
    {
        fields: [
            {
                type: fieldTypes.SELECT,
                label: 'Country of Incorporation',
                key: 'countryOfIncorporation',
                valueExpr: 'id',
                displayExpr: 'name',
                options: countryList,
                isRequired: true,
                moreInfo: data => {
                    const preExistingList = data.incorporationList && data.incorporationList.filter(item => item.isPreExisting);
                    if (data && data.edgarFilingStatus && data.edgarFilingStatus.includes(reportingStandards.SEC) && preExistingList && preExistingList.length > 0) return `To make a modification in the Company's Country of Incorporation - please make a note on the Review and Submit page.`;
                    return false;
                },
                readOnly: data => {
                    const preExistingList = data.incorporationList && data.incorporationList.filter(item => item.isPreExisting);
                    if (data && data.edgarFilingStatus && data.edgarFilingStatus.includes(reportingStandards.SEC) && preExistingList && preExistingList.length > 0) return true;
                    return false;
                },
                colSpan: 3
            },
            {
                type: fieldTypes.SELECT,
                label: 'State/Province of Incorporation',
                key: 'stateOfIncorporation',
                valueExpr: 'id',
                displayExpr: 'name',
                options: data => {
                    const country = countryList.find(c => c.id === data.countryOfIncorporation || c.id === data.countryId);
                    if (!country) return [];
                    return stateList.filter(option => option.countryId === country.id);
                },
                isRequired: data => isRequiredCountry(data.countryOfIncorporation),
                colSpan: 3
            },
            {
                type: fieldTypes.SELECT,
                label: 'Year of Incorporation',
                key: 'yearOfIncorporation',
                options: yearsList,
                isRequired: true,
                colSpan: 3
            }
        ]
    }
];

export const formSections = [
    {
        title: 'General Information',
        fields: [
            {
                type: fieldTypes.TEXTAREA,
                label: 'Business Description',
                key: 'companyInfo.businessDesc',
                isRequired: true,
                colSpan: 6
            },
            {
                type: fieldTypes.TEXTAREA,
                label: 'Facilities Description',
                key: 'companyInfo.facilitiesDesc',
                colSpan: 6,
                isRequired: true,
                moreInfo: 'Describe the assets, properties or facilities owned, used or leased by the issuer. Give the location of the principal plants and other property of the issuer and describe the condition of the properties. It the issuer does not nave complete ownership or control of the property (for example, it others also own the property or if there is a mortgage on the property), describe the limitations on the ownership.'
            },
            {
                type: fieldTypes.TEXTAREA,
                label: 'Product & Services Description',
                key: 'companyInfo.productServiceDesc',
                isRequired: true,
                colSpan: 6
            },
            {
                type: fieldTypes.NUMBERBOX,
                label: '# of Employees',
                placeholder: 'XX',
                key: 'companyInfo.numberOfEmployees',
                isRequired: true,
                max: 99999999,
                colSpan: 2
            },
            {
                type: fieldTypes.DATEBOX,
                label: '# of Employees (As of)',
                key: 'companyInfo.numberOfEmployeesAsOf',
                isRequired: true,
                colSpan: 2
            }
        ]
    },
    {
        title: sectionTitles.INCORP_HISTORY,
        isValid: data => isIncCorpHistValid(data),
        invalidText: data=> getIncCorpHistInvalidText(data),
        subTitle: `Enter the incorporation or registration information for the issuer or its' predecessors (if any) during the past five years.`,
        component: IncorporationHistory,
        reviewList: {
            fields: [ ...incorporationHistory ]
        }
    },
    {
        title: sectionTitles.COMPANY_NAME_HISTORY,
        subTitle: 'Enter any previous company names used in the past 5 years.',
        component: CompanyNameHistory,
        reviewList: {
            fields: [
                {
                    label: 'Description',
                    field: 'description',
                },
                {
                    label: 'Effective Date',
                    field: 'effectiveDate'
                }
            ]
        }
    },
    {
        title: 'Filing Information',
        fields: [
            {
                type: fieldTypes.SELECT,
                label: 'Fiscal Year End Month',
                key: 'companyInfo.filingInfo.fiscalYearEndMonth',
                valueExpr: 'twoDigitId',
                displayExpr: 'month',
                options: monthsList,
                isRequired: true,
                colSpan: 2
            },
            {
                type: fieldTypes.SELECT,
                label: 'Fiscal Year End Day',
                key: 'companyInfo.filingInfo.fiscalYearEndDay',
                options: getDaysList(),
                isRequired: true,
                isRequired: true,
                colSpan: 2
            },
            {
                type: fieldTypes.SELECT,
                label: 'Filing Cycle',
                key: 'companyInfo.filingInfo.filingCycle',
                valueExpr: 'id',
                displayExpr: 'text',
                options: filingCycles,
                colSpan: 2,
                moreInfo: `To make a modification in the Company's Filing Cycle - please make a note on the Review and Submit page.`,
                readOnly: true
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'Primary SIC Code',
                key: 'companyInfo.filingInfo.primarySicCode',
                maxLength: 4,
                colSpan: 2
            },
            {
                type: fieldTypes.TEXTBOX,
                label: 'SEC CIK Code',
                key: 'companyInfo.filingInfo.cik',
                maxLength: 10,
                colSpan: 2
            }
        ]
    },
    {
        title: 'Organizational Indicators',
        fields: [
            {
                type: fieldTypes.CHECKBOX,
                text: 'Blank Check',
                key: 'companyInfo.organizationIndicators.blankCheck',
                colSpan: 2
            },
            {
                type: fieldTypes.CHECKBOX,
                text: 'Shell Company',
                key: 'companyInfo.organizationIndicators.shell',
                colSpan: 2
            },
            {
                type: fieldTypes.CHECKBOX,
                text: 'SPAC',
                key: 'companyInfo.organizationIndicators.spac',
                colSpan: 2
            },
            {
                type: fieldTypes.CHECKBOX,
                text: 'Blind Pool',
                key: 'companyInfo.organizationIndicators.blindPool',
                colSpan: 2
            }
        ]
    }
];

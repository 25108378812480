import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import { contactRoles, entities, errors, fields, personObj, personTitles, emails, urls } from '../../../constants';
import { personEntityTitle } from '../../../utils/helper';
import { isOfficerComplete } from '../../../utils/completes';
import { officerSection1, officerSection2 } from '../../../constants/forms/people';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProfileContext } from '../../../context/Profile.context';
import DialogBox from '../../DialogBox';
import Button from '../../Button';
import EntityList from '../../EntityList';
import FieldError from '../../FieldError';
import FormSection from '../../FormSection';
import Controls from '../../Controls';
import Entity from '../../Entity';
import Label from '../../Label';
import PreviousAdded from '../../PreviousAdded';
import RemovePerson from '../../RemovePerson';
import SlidePanel from '../../SlidePanel';
import SortableDrag from '../../SortableDrag';
import Title from '../../Title';
import TitleSelect from '../../TitleSelect';
import styles from './People.module.scss';

const PREV_ADDED_VIEW = 'previousAdded';
const FORM_VIEW = 'formView';
const REMOVE_PERSON_VIEW = 'removePerson';
const isOfficer = fields.OFFICER;
const isNotPublish = fields.DO_NOT_PUBLISH;
const DO_NOT_PUBLISH = <p>The following contacts are not publicly displayed on your Company Profile on <a href={urls.HOME} target='_blank' rel='noopener noreferrer'>OTCMarkets.com</a>. If you would like to change this designation, please contact Issuer Services at <a href={emails.ISSUER_SERVICES}>issuers@otcmarkets.com</a> or call 212-896-4420. Note that it is OTC Markets Group policy to display all Company Officers, Directors and Control Persons.</p>;

const OfficersTab = ({ onPersonAdd, onPersonUpdate, onPersonRemove}) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [slidePanel, setSlidePanel] = useState(false);
  const [officerView, setOfficerView] = useState(personObj);
  const [addTitle, toggleAddTitle] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [titles, setTitles] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [viewHistory, setViewHistory] = useState(null);
  const [titlesFocus, setTitlesFocus] = useState(false);
  const companyName = profileState.profile.companyInfo.companyName;
  const peopleList = profileState.profile.peopleList;
  const directorList = peopleList && peopleList.filter(person => person.isDirector && !person[isOfficer]);
  const previousAddView = directorList && directorList.length > 0;
  const [slideView, setSlideView] = useState(previousAddView ? PREV_ADDED_VIEW : FORM_VIEW);
  const isMaxTitles = officerView && officerView.title4;
  const dnpOfficerList = (peopleList && peopleList.filter(person => person[isOfficer] && person[isNotPublish])) || [];
  const hasDNPOfficerList = dnpOfficerList.length > 0;
  const officerList = peopleList && peopleList.filter(person => person[isOfficer] && !person[isNotPublish]).sort((a, b) => {
    const nameA = a.title1 || '';
    const nameB = b.title1 || '';
    
    const indexA = personTitles.indexOf(nameA);
    const indexB = personTitles.indexOf(nameB);
    
    if (indexA === -1 && indexB === -1) {
      // If both names are not in the personTitles, sort them alphabetically.
      return nameA.localeCompare(nameB);
    } else if (indexA === -1) {
      // If only nameA is not in the personTitles, it comes after nameB.
      return 1;
    } else if (indexB === -1) {
      // If only nameB is not in the personTitles, it comes before nameA.
      return -1;
    }
    
    // Compare the indexes in personTitles to determine the sorting order.
    return indexA - indexB;
  });
  const hasTitles = officerView && officerView.title1;
  const isAdd = !officerView.id || officerView && !officerView[isOfficer];
  const isReadOnly = profileState.readOnly;
  const isRemoveView = slideView === REMOVE_PERSON_VIEW;
  const slidePanelTitle = isRemoveView ? `Remove - ${personEntityTitle(officerView, false)}` : isAdd ? 'Add Officer or Contact' : personEntityTitle(officerView, false);  

  useEffect(() => {
    if (!slidePanel) {
      setViewHistory(null);
      setOfficerView(personObj);
      setSlideView(previousAddView ? PREV_ADDED_VIEW : FORM_VIEW);
      setValidationError(false);
      setSelectedTitle(null);
      toggleAddTitle(false);
    }
  }, [slidePanel, previousAddView]);

  useEffect(() => {
    let titleArray = [];
    if (officerView && officerView.title1) titleArray.push(officerView.title1);
    if (officerView && officerView.title2) titleArray.push(officerView.title2);
    if (officerView && officerView.title3) titleArray.push(officerView.title3);
    if (officerView && officerView.title4) titleArray.push(officerView.title4);
    setTitles(titleArray);
  }, [officerView]);

  const onValueChanged = person => {
    setOfficerView(person);
  };

  const onRemoveSubmit = person => {
    onPersonUpdate(person);
    setSlidePanel(false);
  };

  const handleEditView = person => {
    let titleArray = [];
    if (person.title1) titleArray.push(person.title1);
    if (person.title2) titleArray.push(person.title2);
    if (person.title3) titleArray.push(person.title3);
    if (person.title4) titleArray.push(person.title4);
    setSlideView(FORM_VIEW);
    setOfficerView({ ...person, titles: titleArray });
    setSlidePanel(true);
  };

  const handleCancelView = _ => {
    viewHistory === PREV_ADDED_VIEW && directorList && directorList.length > 0 ? setSlideView(PREV_ADDED_VIEW) : setSlidePanel(false);
    viewHistory === PREV_ADDED_VIEW && setOfficerView(personObj);
  };

  const handleSubmitPerson = _ => {
    setValidationError(false);

    const officer = officerView;
    const onSubmit = !officer.id ? onPersonAdd : onPersonUpdate;

    officer[isOfficer] = true;

    officer.title1 = titles[0];
    officer.title2 = titles[1];
    officer.title3 = titles[2];
    officer.title4 = titles[3];

    if (!isOfficerComplete(officer)) {
      setValidationError(true);
      return;
    }

    delete officer.titles;

    onSubmit(officer);
    setSlidePanel(false);
  };

  const handleRemove = (e, person) => {
    e.stopPropagation(); 
    onPersonRemove(person, isOfficer);
  };

  const handleRemoveCustom = (e, person) => {
    e.stopPropagation();
    setOfficerView(person);
    setSlideView(REMOVE_PERSON_VIEW);
    setSlidePanel(true);
  };

  const isCustomRemove = person => {
    const totalRoles = contactRoles.filter(role => person[role]);

    return totalRoles.length > 1;
  };

  const handleCancelRemove = _ => {
    setSlidePanel(false);
  };

  const validateComplete = person => {
    return isOfficerComplete(person);
  };

  const onTitleRemove = title => {
    const updateTitles = officerView.titles;
    const index = updateTitles.indexOf(title);
    updateTitles.splice(index, 1);

    setOfficerView(prevState => {
      return {
        ...prevState,
        titles: updateTitles,
        title1: updateTitles[0],
        title2: updateTitles[1],
        title3: updateTitles[2],
        title4: updateTitles[3]
      };
    });
  };

  const handleSelection = value => {
    setSelectedTitle(value);
  };

  const handleTitleSave = _ => {
    setOfficerView(prevState => {
      const titleList = [...titles || [], selectedTitle];
      setTitles(titleList);
      return {
        ...prevState,
        titles: titleList,
        title1: titleList[0],
        title2: titleList[1],
        title3: titleList[2],
        title4: titleList[3]
      };
    });
    
    setSelectedTitle(null);
    toggleAddTitle(false);
  };

  const closeToggleTitle = _ => {
    setSelectedTitle(null);
    toggleAddTitle(false);
  };

  const renderTitles = _ => (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.entityList}>{titles && titles.map((card, i) => {
        const onRemove = _ => onTitleRemove(card);

        return (
          (
            <SortableDrag key={`${card}${i}`} index={i} id={card} items={titles} setItems={setTitles} disable={isReadOnly}>
              <Entity
                title={card}
                size='small'
                handleRemove={!isReadOnly && onRemove} />
            </SortableDrag>
          )
        );
      })}</div>
    </DndProvider>
  );

  const renderAddNew = _ => {
    return <div>
      <Label>
        <FontAwesomeIcon className={styles.icon} icon={['far', 'lock']} />
        <Title className={styles.labelTitle} type='h3' title='Private Information' />
        <div>
          This icon denotes that the information is private and is not publicly displayed.
        </div>
      </Label>
      <div className='mtMed'>
        {officerSection1.map((section, i) => <FormSection key={i} section={section} data={officerView} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
      </div>
      <div>
        <div className={cn({ [styles.hideTitles]: addTitle })}>
          <div
            onMouseEnter={() => setTitlesFocus(true)}
            onMouseLeave={() => setTitlesFocus(false)}>
            <Title className={styles.inline} type='h3' title='Titles' />
            {!officerView.title1 && <FieldError error={errors.INCOMPLETE_DATA_FIELD} isFocus={titlesFocus} />}
          </div>
          <p>
            Please enter all titles associated with this person.
          </p>
          {hasTitles && renderTitles()}
          {(!isMaxTitles && !isReadOnly) && <Entity
          title='Add Title'
          isAdd
          size='small'
          onClick={() => toggleAddTitle(true)} />}
          {((officerView && !officerView.title1) && isReadOnly) && <Entity
            title='No Titles'
            isEmpty
            size='small' />}
        </div>
        {addTitle && <DialogBox
            title='Add New Title'
            subTitle='Select title from preset selections.'
            onCancelClick={closeToggleTitle}
            onSubmitClick={handleTitleSave}
            isSubmitDisabled={!selectedTitle || selectedTitle.trim().length === 0}>
              <TitleSelect onSelection={handleSelection} selectedTitles={titles} />
        </DialogBox>}
      </div>
      <div className='mtXL'>
        {officerSection2.map((section, i) => <FormSection key={i} section={section} data={officerView} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
      </div>
      {validationError && <Label isError title={errors.INCOMPLETE_DATA} />}
      {!isReadOnly && <Controls
        className='mtXXL'
        cancelText='Cancel'
        submitText={isAdd ? 'Add' : 'Update'}
        onCancelClick={handleCancelView}
        onSubmitClick={handleSubmitPerson}
      />}
    </div>;
  };
  
  const handleSelectDirector = person => {
    const newOfficer = { ...person };
    if (!newOfficer.titles) newOfficer.titles = [];
    setViewHistory(PREV_ADDED_VIEW);
    setOfficerView(newOfficer);
    setSlideView(FORM_VIEW);
  };

  const handleAddNew = _ => {
    setViewHistory(PREV_ADDED_VIEW);
    setSlideView(FORM_VIEW);
  };

  const renderPreviouslyAdded = _ => (
    <div>
      <p>
        Select individual previously entered as a Director or add a new individual below
      </p>
      <PreviousAdded
        listTitle='All Directors Previously Added'
        items={directorList}
        handleSelect={handleSelectDirector} />
      <div className='mtMed'>
        <Button
          title='Add New Officer or Contact' 
          buttonType='secondary' 
          fullWidth
          onClick={handleAddNew} />
      </div>
      <div className={cn('mtXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          showSubmit={false}
          onCancelClick={() => setSlidePanel(false)} />
      </div>
    </div>
  );

  return (
    <div>
      <p className='mbLg'>
        Enter all Executive Officers and Contacts to be included on the Company Profile for {companyName} and provide contact information.
      </p>
      <EntityList
        className='mbXL'
        title='Executive Officers and Contacts'
        list={officerList}
        confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
        showIncompleteList={(officerList && officerList.length < 1) && (dnpOfficerList && dnpOfficerList.length < 1) }
        incompleteMessage='At least one Officer is required'
        icon={'user'}
        iconComplete={'userComplete'}
        entityTitle={personEntityTitle}
        validateComplete={validateComplete}
        addTitle='Add Officer or Contact'
        readOnly={isReadOnly}
        handleEntityClick={handleEditView}
        hasCustomRemove={isCustomRemove}
        handleEntityRemove={!isReadOnly && handleRemove}
        handleCustomRemove={!isReadOnly && handleRemoveCustom}
        handleAddClick={() => setSlidePanel(true)}
      />
      {hasDNPOfficerList && <>
        {DO_NOT_PUBLISH}
          <EntityList
            className='mbXL'
            title=''
            list={dnpOfficerList}
            confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
            icon={'userLock'}
            iconComplete={'userLock'}
            entityTitle={personEntityTitle}
            validateComplete={validateComplete}
            readOnly={isReadOnly}
            handleEntityClick={handleEditView}
            hasCustomRemove={isCustomRemove}
            handleEntityRemove={!isReadOnly && handleRemove}
            handleCustomRemove={!isReadOnly && handleRemoveCustom}
          />
        </>
      }
      
      <SlidePanel 
        isOpen={slidePanel}
        onClose={() => setSlidePanel(false)}
        title={slidePanelTitle}>
          {slideView === PREV_ADDED_VIEW && renderPreviouslyAdded()}
          {slideView === FORM_VIEW && renderAddNew()}
          {isRemoveView && <RemovePerson
            person={{ ...officerView }}
            selectedRemove={isOfficer}
            onCancel={handleCancelRemove}
            onRemoveSubmit={onRemoveSubmit} />}
      </SlidePanel>
    </div>
  );
};

OfficersTab.propTypes = {
  onPersonAdd: PropTypes.func,
  onPersonUpdate: PropTypes.func,
  onPersonRemove: PropTypes.func
};


export default OfficersTab;

import React, { useEffect, useContext, useState } from 'react';
import equal from 'fast-deep-equal/es6';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { reviewColumns, sectionTitles } from '../../constants';
import { formSections } from '../../constants/forms/companyInformation';
import { Outline, Table } from '@otcmarketsgroup/otcm-ui';
import { ProfileContext } from '../../context/Profile.context';
import NoChangesMade from '../NoChangesMade';

const ReviewCompanyInformation = () => {
    const [profileState] = useContext(ProfileContext);
    const [hasChanges, setHasChanges] = useState(false);
    const oldData = profileState.currentProfile;
    const newData = profileState.profile;

    const getDiffData = fields => {
      const list = [];

      fields && fields.forEach(field => {
        const newValue = _.get(newData, field.key);
        const oldValue = _.get(oldData, field.key);
        if (oldValue !== newValue) {
          !hasChanges && setHasChanges(true);
          list.push({
            fieldName: field.label || field.text,
            oldValue: typeof oldValue === 'boolean' ? oldValue ? 'Yes' : 'No' : oldValue,
            newValue: typeof newValue === 'boolean' ? newValue ? 'Yes' : 'No' : newValue
          });
        }
      });

      return list;
    };


    return <div>
      {!hasChanges && <NoChangesMade />}
      {formSections.map(section => {
        const isIncorpHistory = section.title === sectionTitles.INCORP_HISTORY;
        let list = [];
        if (section.fields) list = section.fields;
        if (isIncorpHistory) list = section.reviewList.fields;
        const fieldData = getDiffData(list);

        return <>
          {(fieldData && fieldData.length > 0) && <>
            <Outline mode='heading3'>
              {section.title}
            </Outline>
            <Table columns={reviewColumns} data={fieldData} size='small' />
          </>}
        </>;
      })}
    </div>;
};

ReviewCompanyInformation.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string
  })
};

export default ReviewCompanyInformation;
